import React, { useState, useEffect } from "react";
import Slider from "../../../components/Slider/Slider";
import Manifiesto from "../../../components/Manifiesto/Manifiesto";
import SlideProductos from "../../../components/SlideProductos/SlideProductos";
import Galeria from "../../../components/Galeria/Galeria";
import { traerUnaGaleria } from "../../../controllers/Galeria";
import { todosProductos } from "../../../controllers/Productos";
import { traerWhatsAppProducto } from "../../../controllers/WhatsApp";
import { ModalContacto } from "../../../components/Modal";
import Instagram from "../../../components/Instagram/Instagram";
import "./Inicio.css";

const Inicio = () => {
  const [productos, setProductos] = useState([]);
  //const [galerias, setGalerias] = useState([]);
  const [whatsAppProducto, setWhatsAppProducto] = useState({});
  const [estadoContacto, setEstadoContacto] = useState(false);

  useEffect(() => {
    (async () => {
      const productosDB = await todosProductos();
      setProductos(productosDB);
      /*const galeriaDoc = await traerUnaGaleria();
      setGalerias(galeriaDoc.GaleriaPrincipal);*/
      const whatsAppProductoDoc = await traerWhatsAppProducto();
      setWhatsAppProducto(whatsAppProductoDoc);
    })();
  }, []);

  return (
    <>
      {estadoContacto && Object.keys(whatsAppProducto).length !== 0 && (
        <ModalContacto
          setEstadoContacto={setEstadoContacto}
          whatsAppProducto={whatsAppProducto}
        />
      )}
      <Slider />
      {productos && Object.keys(whatsAppProducto).length !== 0 && (
        <SlideProductos
          productos={productos}
          whatsAppProducto={whatsAppProducto}
        />
      )}
      <br />
      <br />
      <br />
      <br />
      {/*galerias && <Galeria galerias={galerias} />*/}
      <br />
      {/*galerias && <Galeria galerias={galerias.slice(0, 2)} />*/}
      {/*galerias && <Galeria galerias={galerias.slice(-2)} />*/}

      {/*<Manifiesto />*/}
      <br />
      <div className="contenedor-letras-palma">
        <p className="ocultar-computadora">PALMA STORE EST {new Date().getFullYear()}</p>
        <p className="ocultar-computadora">PALMA STORE EST {new Date().getFullYear()}</p>
        <p className="ocultar-tablet">PALMA STORE EST {new Date().getFullYear()}</p>
        <p className="ocultar-tablet">PALMA STORE EST {new Date().getFullYear()}</p>
        <p>PALMA STORE EST {new Date().getFullYear()}</p>
        <p>PALMA STORE EST {new Date().getFullYear()}</p>
      </div>
      <Instagram />
    </>
  );
};

export default Inicio;
