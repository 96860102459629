import React from "react";
import "./CargandoPagina.css";

const CargandoPagina = () => {
  return (
    <div className="contendor-cargando-pagina">
      <div className="cargando"></div>{" "}
    </div>
  );
};

export default CargandoPagina;
