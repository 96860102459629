import React, { useState } from "react";
import { Link } from "react-router-dom";
import { mensajeCrear } from "../../controllers/Mensajes";

import "./EnviarMensaje.css";

const EnviarMensaje = () => {
  const [celular, setCelular] = useState("");

  const enviarMensaje = (e) => {
    e.preventDefault();
    if (celular) {
      mensajeCrear(celular);
      setCelular("");
    }
  };
  return (
    <div className="pie-registro">
      <h3>REGISTRATE Y ENTÉRATE DE LO NUEVO</h3>
      <p>¡Conóce lo último de Palma, sobre novedades y ofertas! </p>
      {/*<p>Ingresa tu email</p>*/}
      <form className="pie-registro-form" onSubmit={enviarMensaje}>
        <input
          type="email"
          required
          name="celular"
          /*value={celular}*/
          placeholder="Escribe tu email"
          onChange={(event) => setCelular(event.target.value)}
        />

        <input type="submit" value="Enviar" />
        <div>
          <Link
            to={{ pathname: `https://${"www.google.com"}` }}
            target="_blank"
          >
            <img src="/icons/IconoFacebook.svg" alt="" />
          </Link>
          <Link
            to={{ pathname: `https://${"www.google.com"}` }}
            target="_blank"
          >
            <img src="/icons/IconoInstagram.svg" alt="" />
          </Link>
          <Link
            to={{ pathname: `https://${"www.google.com"}` }}
            target="_blank"
          >
            <img src="/icons/IconoPinterest.svg" alt="" />
          </Link>
        </div>
      </form>
    </div>
  );
};

export default EnviarMensaje;
