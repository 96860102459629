import React, { useState } from "react";
import { registrarClienteAuth } from "../../../controllers/Sesion";
import { Link } from "react-router-dom";
import { ModalRegistrar } from "../../../components/Modal";
import Notificacion from "../../../components/Notificacion/Notificacion";
import Titulo2 from "../../../components/Titulo/Titulo2";
import "./Ingresar.css";

const initFormRegistrar = {
  correo: "",
  contrasena: "",
};
const mensajeModal = "Tiene que aceptar nuestros terminos";

const Registrar = (props) => {
  const [formRegistrar, setFormRegistrar] = useState(initFormRegistrar);
  const cambiarDatosFormRegistrar = (e) => {
    const { name, value } = e.target;
    setFormRegistrar({
      ...formRegistrar,
      [name]: value,
    });
  };
  const [formCheck, setFormCheck] = useState(false);
  const cambiarEstadoCheck = (event) => {
    setFormCheck(event.target.checked);
  };
  const [estadoModal, setEstadoModal] = useState(false);
  const [estadoNotificacion, setEstadoNotificacion] = useState(false);
  const [mensajeNotificacion, setMensajeEstadoNotificacion] = useState(null);

  const crearCliente = (e) => {
    e.preventDefault();
    if (formCheck === false) {
      setEstadoModal(true);
    } else {
      registrarClienteAuth(formRegistrar).then((res) => {
        if (res === "Correcto") {
          setEstadoNotificacion(true);
          setMensajeEstadoNotificacion(
            "Registrado Correctamente, verifique su correo para validar."
          );
          setTimeout(() => {
            props.history.push("/ingresar");
          }, 4000);
        } else if (res === "Repetido") {
          setEstadoNotificacion(true);
          setMensajeEstadoNotificacion("Este correo ya fue registrado.");
        } else if (res === "Contrasena") {
          setEstadoNotificacion(true);
          setMensajeEstadoNotificacion(
            "Contraseña debe ser mayor de 6 dígitos"
          );
        }
        setFormRegistrar(initFormRegistrar);
        setFormCheck(false);
      });
    }
  };

  return (
    <div className="contenedor-ingresar-fondo">
      <Titulo2 titulo={"REGISTRA A TU CUENTA DE PALMA"} />

      {estadoModal && (
        <ModalRegistrar
          mensajeModal={mensajeModal}
          setEstadoModal={setEstadoModal}
        />
      )}
      {estadoNotificacion && (
        <Notificacion
          mensajeNotificacion={mensajeNotificacion}
          setEstadoNotificacion={setEstadoNotificacion}
          setMensajeEstadoNotificacion={setMensajeEstadoNotificacion}
        />
      )}
      <div className="contenedor-ingresar">
        <h3>
          <span>REGISTRAR</span>
        </h3>
        <form onSubmit={crearCliente}>
          <input
            type="email"
            required
            name="correo"
            placeholder="Email"
            value={formRegistrar.correo}
            onChange={cambiarDatosFormRegistrar}
          />
          <input
            type="password"
            required
            name="contrasena"
            placeholder="Password"
            value={formRegistrar.contrasena}
            onChange={cambiarDatosFormRegistrar}
          />
          <label className="contenedor-pie-check">
            <span>
              I’d like to be notified about new products, behined the scenes
              news and early access to sales. I can unsubscribe at any time.
            </span>
            <input type="checkbox" onChange={cambiarEstadoCheck} />
            <span className="checkmark-pie">
              {formCheck ? (
                <img src="/icons/IconoCheck.svg" alt="logo" />
              ) : (
                <></>
              )}
            </span>
          </label>{" "}
          <input type="submit" value="REGISTRAR" />
          <p>
            <Link to="/ingresar">Ingresar</Link>
          </p>
          <div className="contenedor-aceptar-terminos">
            By providing your email address, you agree to ou{" "}
            <Link to="/politicas-de-privacidad" className="enlace-politicas">
              Privacy Policy and Terms & Conditions
            </Link>
          </div>
        </form>
      </div>
    </div>
  );
};

export default Registrar;
