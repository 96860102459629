import React from "react";
import { Link } from "react-router-dom";

const MenuNavegacion = () => {
  return (
    <div>
      <h1 style={{ fontSize: "16px" }}>
        <Link to="/administracion/menu" style={{ textDecoration: "underline" }}>
          Menu Principal
        </Link>{" "}
        <span> / </span>{" "}
        <Link
          to="/administracion/menu-social"
          style={{ textDecoration: "underline" }}
        >
          Menu Empresa
        </Link>
        {/*<span> / </span>{" "}
        <Link
          to="/administracion/menu-info"
          style={{ textDecoration: "underline" }}
        >
          Menu Info
        </Link>*/}
      </h1>
    </div>
  );
};
export default MenuNavegacion;
