import React from "react";
import Titulo4 from "../../../components/Titulo/Titulo4";
import "./Politicas.css";

const About = () => {
  return (
    <>
      <Titulo4 titulo={"INTENCIÓN"} />
      <div className="contenedor-politicas">
        <p style={{ textAlign: "justify", marginBottom: "15px" }}>
          <strong>PALMA</strong> trabaja para crear una comunidad que utilice
          los recursos sanadores de la tierra de manera responsable.
        </p>
        <p style={{ textAlign: "justify", marginBottom: "15px" }}>
          Cada uno de los productos que ofrecemos están pensados en tener el
          menor impacto posible, siendo transparentes con la información de
          origén, historia, componentes y manera de producción.
        </p>
        <p style={{ textAlign: "justify", marginBottom: "15px" }}>
          Nuestros artículos son artesanales y hechos a mano, la mayoría por
          artistas artesanos del Perú.
        </p>
        <p style={{ textAlign: "justify", marginBottom: "15px" }}>
          Eliminamos el plástico de nuestro packaging y contamos con Certificado
          Forestal y de Fauna Silvestre.
        </p>
        <p style={{ textAlign: "justify", marginBottom: "15px" }}>
          Aquí esperamos seguir creciendo para seguir apostando por estas
          comunidades que nos hacen llegar con su amor toda su trabajo. El foco
          de la intención es aportarles valor.
        </p>
        <p style={{ textAlign: "justify", marginBottom: "15px" }}>
          Los animales son para nosotras intocables por lo que no vas encontrar
          productos de origén animal, somos vegan & Crueltyfree.
        </p>
        <p style={{ textAlign: "justify", marginBottom: "15px" }}>
          Con cariño,
        </p>
        <p style={{ textAlign: "justify", marginBottom: "15px" }}>
          Alicia Sintjago.
        </p>
      </div>
    </>
  );
};

export default About;
