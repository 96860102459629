import React, { useState, useEffect } from "react";
import { db } from "../../../firebase/firebase";
import { doc, onSnapshot } from "firebase/firestore";
import { Link } from "react-router-dom";
import { actualizarWhatsAppCarrito } from "../../../controllers/WhatsApp";

const initFormWhatsApp = {
  celular: "",
  saludo: "",
  ayuda: "",
};

const WhatsAppCarrito = () => {
  const [formWhatsApp, setFormWhatsApp] = useState(initFormWhatsApp);

  useEffect(() => {
    const whatsAppRef = doc(db, "WhatsApp", "0cP8iHSSGpgOG2kMlQMl");
    onSnapshot(whatsAppRef, (doc) => {
      setFormWhatsApp({
        whatsAppId: doc.id,
        celular: doc.data().celular,
        saludo: doc.data().saludo,
        ayuda: doc.data().ayuda,
      });
    });
  }, []);

  const cambiarDatos = (e) => {
    const { name, value } = e.target;
    setFormWhatsApp({
      ...formWhatsApp,
      [name]: value,
    });
  };

  const guardarWhatsApp = (e) => {
    e.preventDefault();
    actualizarWhatsAppCarrito(formWhatsApp);
    setFormWhatsApp(initFormWhatsApp);
  };

  return (
    <>
      <div className="contenedor-perfil-cliente">
        <h4>
          <Link
            to="/administracion/whatsapp-carrito"
            style={{ textDecoration: "underline" }}
          >
            Whatsapp Carrito
          </Link>{" "}
          <span> / </span>{" "}
          <Link
            to="/administracion/whatsapp-producto"
            style={{ textDecoration: "underline" }}
          >
            Whatsapp Producto
          </Link>
        </h4>
        <form onSubmit={guardarWhatsApp}>
          <p>Numero de WhatsApp con código:</p>
          <input
            type="text"
            required
            name="celular"
            placeholder="Numero de WhatsApp con código"
            value={formWhatsApp.celular}
            onChange={cambiarDatos}
          />
          <p>Mensaje saludo en Carrito:</p>
          <input
            type="text"
            required
            name="saludo"
            placeholder="Mensaje saludo en Carrito"
            value={formWhatsApp.saludo}
            onChange={cambiarDatos}
          />
          <p>Mensaje de ayuda en Carrito:</p>
          <input
            type="text"
            required
            name="ayuda"
            placeholder="Mensaje de ayuda en Carrito"
            value={formWhatsApp.ayuda}
            onChange={cambiarDatos}
          />
          <input type="submit" value="Editar WhatsApp" />
        </form>
      </div>
    </>
  );
};

export default WhatsAppCarrito;
