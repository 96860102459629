import {
  collection,
  doc,
  deleteDoc,
  addDoc,
  updateDoc,
  getDocs,
  setDoc,
} from "firebase/firestore";
import { db } from "../firebase/firebase";
const coleccion = "Usuarios";
const subColeccion = "Favoritos";

export const favoritosTodos = async (idCliente) => {
  const favoritosRef = collection(db, coleccion, idCliente, subColeccion);
  const favoritosDB = await getDocs(favoritosRef);
  return favoritosDB.docs.map((doc) => {
    const id = doc.id;
    return {
      idProducto: id,
      ...doc.data(),
    };
  });
};

export const favoritoAgregar = async (idCliente, producto) => {
  console.log(idCliente, producto);
  const rrutaFavorito = `${coleccion}/${idCliente}/${subColeccion}`;
  const favoritosRef = doc(db, rrutaFavorito, producto.idProducto);
  try {
    await setDoc(favoritosRef, {
      nombre: producto.nombre,
      marca: producto.marca,
      url: producto.url,
      //descripcion: producto.descripcion,
      caracteristicas: producto.caracteristicas,
      precio: producto.precio,
      stock: producto.stock,
      tiempoEntrega: producto.tiempoEntrega,
      categoria: producto.categoria,
      etiqueta: producto.etiqueta,
      imagenes: producto.imagenes,
      estado: true,
    });
  } catch (e) {
    console.error("No se pudo agregar favorito ", e);
  }
};

export const favoritoCrear = async (idCliente, producto) => {
  console.log(producto);
  const favoritosRef = collection(db, coleccion, idCliente, subColeccion);
  try {
    await addDoc(favoritosRef, {
      nombre: producto.nombre,
      marca: producto.marca,
      url: producto.url,
      descripcion: producto.descripcion,
      precio: producto.precio,
      stock: producto.stock,
      tiempoEntrega: producto.tiempoEntrega,
      categoria: producto.categoria,
      etiqueta: producto.etiqueta,
      imagenes: producto.imagenes,
      estado: true,
    });
  } catch (e) {
    console.error("No se pudo agregar favorito ", e);
  }
};

export const favoritoEditar = async (idCliente, favorito) => {
  const rrutaFavorito = `${coleccion}/${idCliente}/${subColeccion}`;
  const favoritosRef = doc(db, rrutaFavorito, favorito.idFavorito);
  await updateDoc(favoritosRef, {
    nombre: favorito.nombre,
  });
};

export const favoritoEliminar = async (idCliente, idFavorito) => {
  const rrutaFavorito = `${coleccion}/${idCliente}/${subColeccion}`;
  const favoritosRef = doc(db, rrutaFavorito, idFavorito);
  await deleteDoc(favoritosRef);
};
