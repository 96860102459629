import { doc, setDoc, getDoc, updateDoc } from "firebase/firestore";
import { db } from "../firebase/firebase";
import {
  getAuth,
  createUserWithEmailAndPassword,
  sendEmailVerification,
  checkActionCode,
  applyActionCode,
  signInWithEmailAndPassword,
  sendPasswordResetEmail,
  verifyPasswordResetCode,
  confirmPasswordReset,
} from "firebase/auth";
const coleccion = "Usuarios";
const auth = getAuth();

export const registrarCliente = async (formRegistrar, clienteID) => {
  try {
    await setDoc(doc(db, coleccion, clienteID), {
      correo: formRegistrar.correo,
      confirmacion: false,
      rol: "cliente",
    });
  } catch (e) {
    console.error("Error al registrar el cliente ", e);
  }
};

export const registrarClienteAuth = (formRegistrar) => {
  return createUserWithEmailAndPassword(
    auth,
    formRegistrar.correo,
    formRegistrar.contrasena
  )
    .then((userCredential) => {
      //console.log("Credenciales: ", userCredential);
      const clienteID = userCredential.user.uid;
      return sendEmailVerification(userCredential.user).then(() => {
        registrarCliente(formRegistrar, clienteID);
        return "Correcto";
      });
    })
    .catch((error) => {
      if (error.code === "auth/email-already-in-use") {
        return "Repetido";
      } else if (error.code === "auth/weak-password") {
        return "Contrasena";
      }
    });
};

export const verificarCuentaCorreo = (actionCode) => {
  var correoCliente = null;
  return checkActionCode(auth, actionCode)
    .then((info) => {
      correoCliente = info["data"]["email"];
      applyActionCode(auth, actionCode);
      return correoCliente;
    })
    .catch((error) => {
      if (error.code === "auth/invalid-action-code") {
        return "expirado";
      } else {
        return "error";
      }
    });
};

export const ingresarClienteAuth = (formIngresar) => {
  return signInWithEmailAndPassword(
    auth,
    formIngresar.correo,
    formIngresar.contrasena
  )
    .then((userCredential) => {
      const correoVerificado = userCredential.user.emailVerified;
      const usuario = {
        idUsuario: userCredential.user.uid,
        token: userCredential.user.accessToken,
      };
      if (correoVerificado) {
        return usuario;
      } else {
        return "noVerificado";
      }
    })
    .catch((error) => {
      if (error.code === "auth/wrong-password") {
        return "contrasenaIncorrecta";
      } else {
        return "error";
      }
    });
};

export const clienteEditarToken = async (idUsuario, token) => {
  const clienteRef = doc(db, coleccion, idUsuario);
  await updateDoc(clienteRef, { idToken: token });
};

export const traerUnCliente = async (usuarioVerificado) => {
  const idUsuario = usuarioVerificado.idUsuario;
  const token = usuarioVerificado.token;
  const idTokenLS = localStorage.getItem("idToken");

  const clienteRef = doc(db, coleccion, idUsuario);
  const docCliente = await getDoc(clienteRef);

  if (docCliente.exists()) {
    if (idTokenLS && idTokenLS === docCliente.data().idToken) {
      return {
        idCliente: idUsuario,
        ...docCliente.data(),
      };
    } else {
      clienteEditarToken(idUsuario, token);
      return {
        idCliente: idUsuario,
        idToken: token,
        correo: docCliente.data().correo,
        nombres: docCliente.data().nombres,
        apellidos: docCliente.data().apellidos,
        celular: docCliente.data().celular,
        rol: docCliente.data().rol,
        confirmacion: docCliente.data().confirmacion,
      };
    }
  } else {
    console.log("No existe el documento");
  }
};

export const recuperarContrasena = (formCorreo) => {
  return sendPasswordResetEmail(auth, formCorreo)
    .then(() => {
      return "correcto";
    })
    .catch(() => {
      return "error";
    });
};

export const actualizarCuentaContrasena = (actionCode, formContrasena) => {
  return verifyPasswordResetCode(auth, actionCode)
    .then(() => {
      return confirmPasswordReset(auth, actionCode, formContrasena)
        .then(() => {
          return "cambiado";
        })
        .catch(() => {
          return "no";
        });
    })
    .catch(() => {
      return "error";
    });
};
