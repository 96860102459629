import React, { useState, useEffect } from "react";
import { db } from "../../../firebase/firebase";
import { doc, onSnapshot } from "firebase/firestore";
import {
  editarArrayMenuSocial,
  mostrarArrayMenuSocial,
} from "../../../controllers/MenuSocial";
import { Link } from "react-router-dom";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import {
  MenuModalSocialCrear,
  MenuModalSocialEditar,
} from "../../../components/Modal";
import MenuNavegacion from "./MenuNavegacion";
import "./Menu.css";

const reodernarLista = (nuevaLista, posicionInicio, posicionFinal) => {
  const nuevaListaMenu = [...nuevaLista];
  const [removido] = nuevaListaMenu.splice(posicionInicio, 1);
  nuevaListaMenu.splice(posicionFinal, 0, removido);
  return nuevaListaMenu;
};

const MenuSocial = () => {
  const [menuSocial, setMenuSocial] = useState([]);
  const [estadoMenuModal, setEstadoMenuModal] = useState(false);
  const [estadoMenuModalEditar, setEstadoMenuModalEditar] = useState(false);
  const [menuSeleccionado, setMenuSeleccionado] = useState({});
  const [posicionMenu, setPosicionMenu] = useState(0);
  const [mostrar, setMostrar] = useState(false);

  useEffect(() => {
    const idMenuSocial = "WsIW0MIqPVrL16QIGEcq";
    const menuSocialRef = doc(db, "Menu", idMenuSocial);
    onSnapshot(menuSocialRef, (doc) => {
      setMenuSocial(doc.data().MenuSocial);
      setMostrar(doc.data().mostrar);
    });
  }, []);

  const eliminarMenu = (id) => {
    setMenuSocial(
      menuSocial.filter((elemento) => elemento.idMenuSocial !== id)
    );
  };

  const editarMenu = (categoria, index) => {
    setPosicionMenu(index);
    setMenuSeleccionado(categoria);
    setEstadoMenuModalEditar(!estadoMenuModalEditar);
  };

  const guardarNuevoMenu = (e) => {
    e.preventDefault();
    editarArrayMenuSocial(menuSocial);
  };

  return (
    <>
      {estadoMenuModal && (
        <MenuModalSocialCrear setEstadoMenuModal={setEstadoMenuModal} />
      )}
      <MenuNavegacion />
      <p>Example: www.google.com</p>

      {menuSocial?.length === 0 ? (
        <></>
      ) : (
        <>
          {estadoMenuModalEditar && (
            <MenuModalSocialEditar
              setEstadoMenuModal={setEstadoMenuModalEditar}
              menuSeleccionado={menuSeleccionado}
              menuPrincipal={menuSocial}
              posicionMenu={posicionMenu}
            />
          )}
          <DragDropContext
            onDragEnd={(result) => {
              const { source, destination } = result;
              if (!destination) {
                return;
              }
              if (
                source.index === destination.index &&
                source.droppableId === destination.droppableId
              ) {
                return;
              }

              setMenuSocial((listaAntigua) =>
                reodernarLista(listaAntigua, source.index, destination.index)
              );
            }}
          >
            <Droppable droppableId="tasks">
              {(droppableProvided) => (
                <ul
                  {...droppableProvided.droppableProps}
                  ref={droppableProvided.innerRef}
                  className="contenedor-direcciones-drop"
                >
                  {menuSocial.map((itemSocial, index) => (
                    <Draggable
                      key={itemSocial.idMenuSocial}
                      draggableId={itemSocial.idMenuSocial}
                      index={index}
                    >
                      {(draggableProvided) => (
                        <li
                          {...draggableProvided.draggableProps}
                          ref={draggableProvided.innerRef}
                          {...draggableProvided.dragHandleProps}
                        >
                          <p>{itemSocial.nombre}</p>
                          <p>{itemSocial.url}</p>
                          <div className="contenedor-botones-drop">
                            <div className="drop-botones-drop-menu">
                              <span
                                style={{ backgroundColor: "#f2c9cd" }}
                                onClick={() => editarMenu(itemSocial, index)}
                              >
                                Editar Menu
                              </span>
                              <span
                                style={{
                                  backgroundColor: "#dadad8",
                                }}
                              >
                                Eliminar Menu
                              </span>
                            </div>
                          </div>
                        </li>
                      )}
                    </Draggable>
                  ))}
                  {droppableProvided.placeholder}
                </ul>
              )}
            </Droppable>
          </DragDropContext>

          <div className="switch-button-modal">
            <input
              type="checkbox"
              name="switch-button"
              id="switch-label"
              checked={mostrar}
              className={
                mostrar
                ? "switch-button-checkbox switch-button-checkbox-activo"
                : "switch-button-checkbox"
              }
              onChange={(event) =>
                mostrarArrayMenuSocial(event.currentTarget.checked)
              }
            />
         <label
              htmlFor="switch-label"
              className="switch-button-label"
            ></label>
          </div>

          <span
            className="drop-botones-guardar"
            style={{
              backgroundColor: "#555555",
              color: "white",
            }}
            onClick={guardarNuevoMenu}
          >
            Guardar Menu
          </span>
        </>
      )}
      <span
        className="drop-botones-agregar"
        style={{
          backgroundColor: "black",
          color: "white",
        }}
        onClick={() => setEstadoMenuModal(!estadoMenuModal)}
      >
        Agregar Nuevo Menu
      </span>
    </>
  );
};

export default MenuSocial;
