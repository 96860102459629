import React from "react";
import "./Titulo2.css";

const Titulo2 = ({ titulo }) => {
  return (
    <div className="titulo-2-componente">
      <img loading="lazy" src="/images/imagen-sol-rosado.png" alt="" />
      <h2>{titulo}</h2>
      <img loading="lazy" src="/images/imagen-sol-rosado.png" alt="" />
    </div>
  );
};
export default Titulo2;
