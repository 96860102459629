import React from "react";
import { Link } from "react-router-dom";

const PiePoliticas = ({ menuInfo, mostrarMenuInfo }) => {
  return (
    <div className="pie-politicas">
      {mostrarMenuInfo && (
        <>
          <h3>LEGALES</h3>
          {menuInfo.map(
            (itemMenuInfo, indexMenuInfo) =>
              itemMenuInfo.mostrar && (
                <Link to={`/${itemMenuInfo.url}`} key={indexMenuInfo}>
                  {itemMenuInfo.nombre}
                </Link>
              )
          )}
        </>
      )}
    </div>
  );
};

export default PiePoliticas;
