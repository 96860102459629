import React, { useState, useRef, useEffect } from "react";
import { sliderAgregarCF } from "../../../controllers/Slider";
import "./../Modal.css";

const SlidersModalAgregar = ({ setEstadoMenuModal }) => {
  const [foto, setFoto] = useState(undefined);
  const [fotoVista, setFotoVista] = useState(undefined);
  const [fotoFirebase, setFotoFirebase] = useState("");
  const imagenRef = useRef();

  useEffect(() => {
    if (!foto) {
      return;
    }
    const fotoCargada = new FileReader();
    fotoCargada.onload = () => {
      setFotoVista(fotoCargada.result);
    };
    fotoCargada.readAsDataURL(foto);
  }, [foto]);

  function cambiarImagen(e) {
    let fotoSeleccionado;
    if (e.target.files && e.target.files.length === 1) {
      fotoSeleccionado = e.target.files[0];
      setFoto(fotoSeleccionado);
    }
    imagenRef.current.value = "";
  }

  function cambiarImagenSubir() {
    imagenRef.current.click();
  }

  function eliminarImagen(e) {
    setFoto(undefined);
    if (fotoFirebase) {
      setFotoVista(fotoFirebase);
    } else {
      setFotoVista(undefined);
    }
    imagenRef.current.value = "";
  }

  const guardarSlider = (e) => {
    e.preventDefault();
    if (foto !== undefined) {
      sliderAgregarCF(foto);
      setEstadoMenuModal(false);
    }
  };
  return (
    <div className="contenedor-padre-modal">
      <div className="contenedor-modal">
        <div className="modal-cerrar">
          {" "}
          <button onClick={() => setEstadoMenuModal(false)}>
            <img src="/icons/Cerrar.svg" alt="" />
          </button>
        </div>
        <form onSubmit={guardarSlider}>
          <div className="grid-perfil-cliente-imagen">
            {fotoVista === undefined && (
              <div>
                <img
                  style={{ width: "300px" }}
                  src="/images/perfil/sinPerfil.jpg"
                  alt=""
                />
                <br />
                <button
                  style={{
                    backgroundColor: "black",
                    color: "white",
                    cursor: "pointer",
                    border: "none",
                    marginLeft: "2px",
                    padding: "5px",
                  }}
                  type="button"
                  onClick={cambiarImagenSubir}
                >
                  Cambiar slider
                </button>
              </div>
            )}
            {fotoVista && (
              <>
                <img
                  style={{ width: "300px" }}
                  src={fotoVista}
                  alt="vista previa"
                />
                <br />
                <button
                  type="button"
                  style={{
                    backgroundColor: "black",
                    color: "white",
                    cursor: "pointer",
                    border: "none",
                    marginLeft: "2px",
                    padding: "5px",
                  }}
                  onClick={cambiarImagenSubir}
                >
                  Cambiar slider
                </button>
                {foto !== undefined && (
                  <>
                    <button
                      type="button"
                      style={{
                        backgroundColor: "#dadad8",
                        cursor: "pointer",
                        border: "none",
                        marginLeft: "2px",
                        padding: "5px",
                      }}
                      onClick={eliminarImagen}
                    >
                      Cancelar cambio
                    </button>
                  </>
                )}
              </>
            )}
            <input
              ref={imagenRef}
              type="file"
              accept=".jpg, .png, .jpeg"
              onChange={cambiarImagen}
              style={{
                display: "none",
              }}
            />
          </div>
          <br />
          <input type="submit" value="Guardar Slider" />
        </form>
        <br />
      </div>
    </div>
  );
};
export default SlidersModalAgregar;
