import { doc, getDoc, updateDoc, arrayUnion } from "firebase/firestore";
import { db } from "../firebase/firebase";
const coleccion = "Promocion";
const idPromocion = "sRwNA0tAurZIq2uOdVAj";

export const traerUnaPromocion = async () => {
  const promocionRef = doc(db, coleccion, idPromocion);
  const docPromocion = await getDoc(promocionRef);

  if (docPromocion.exists()) {
    return docPromocion.data();
  } else {
    console.log("No existe promocion");
  }
};

export const agregarArrayPromocion = async (nuevaPromocion) => {
  const promocionRef = doc(db, coleccion, idPromocion);  

  await updateDoc(promocionRef, {
    Promocion: arrayUnion(nuevaPromocion),
  });
};

export const editarArrayPromocion = async (promocionActualizado) => {
  const promocionRef = doc(db, coleccion, idPromocion);
  await updateDoc(promocionRef, {
    Promocion: promocionActualizado,
  });
};
