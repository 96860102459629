import React, { useState, useEffect } from "react";
import Axios from "axios";
import Feed from "./Feed";
import "./Instagram.css";

const Instagram = () => {
  const [feedsInstagram, setFeedInstagram] = useState([]);

  const tokenInstagram =
    "IGQVJXTjFIdTR5ZAVp2MGNONkd0eGxadTBfTWlZAWGRWMHg4clQ2djlUYzdPLVpEdGxhZAFB4a25aOExWcmpHd2puRWlpdlcyT0dFd0xrc3pqSkZAuQkJfcmQ5UWJmT29fQ0ZA4NWxQRkJxYU9IRW5oSzZARaQZDZD";

  const limiteInstagram = 12;
  useEffect(() => {
    const abortController = new AbortController();
    async function fetchInstagramPost() {
      try {
        Axios.get(
          `https://graph.instagram.com/me/media?fields=id,media_type,media_url,permalink,caption&limit=${limiteInstagram}&access_token=${tokenInstagram}`
        ).then((resp) => {
          setFeedInstagram(resp.data.data);
        });
      } catch (err) {
        console.log("error", err);
      }
    }
    fetchInstagramPost();
    return () => {
      abortController.abort();
    };
  }, [tokenInstagram]);
  return (
    <div className="contenedor-instagram">
      {feedsInstagram &&
        feedsInstagram.map((feed) => <Feed key={feed.id} feed={feed} />)}
    </div>
  );
};
export default Instagram;
