import React, { useState } from "react";
import { editarArrayMenuSocial } from "../../../controllers/MenuSocial";
import "./../Modal.css";

const initFormMenuSocial = {
  nombre: "",
  url: "",
};

const MenuModalSocialEditar = ({
  setEstadoMenuModal,
  menuSeleccionado,
  menuPrincipal,
  posicionMenu,
}) => {
  const [formMenuSocial, setFormMenuSocial] = useState(menuSeleccionado);

  const cambiarDatosDelMenuSocial = (e) => {
    const { name, value } = e.target;
    setFormMenuSocial({
      ...formMenuSocial,
      [name]: value,
    });
  };

  const cambiarDatosDelMenuSocialCheked = (e) => {
    const mostrar = "mostrar";
    setFormMenuSocial({
      ...formMenuSocial,
      [mostrar]: e.currentTarget.checked,
    });
  };

  const editarNuevoMenuSocial = (e) => {
    e.preventDefault();
    const copiaMenuPrincipal = [...menuPrincipal];
    copiaMenuPrincipal[posicionMenu] = formMenuSocial;
    editarArrayMenuSocial(copiaMenuPrincipal);
    setFormMenuSocial(initFormMenuSocial);
    setEstadoMenuModal(false);
  };

  return (
    <div className="contenedor-padre-modal">
      <div className="contenedor-modal">
        <div className="modal-cerrar">
          {" "}
          <button onClick={() => setEstadoMenuModal(false)}>
            <img src="/icons/Cerrar.svg" alt="" />
          </button>
        </div>

        <form onSubmit={editarNuevoMenuSocial}>
          <p>Nombre Menu:</p>
          <input
            type="text"
            required
            name="nombre"
            placeholder="Nombre Menu"
            value={formMenuSocial.nombre}
            onChange={cambiarDatosDelMenuSocial}
          />
          <p>URL Menu:</p>
          <input
            type="text"
            required
            name="url"
            placeholder="URL Menu"
            value={formMenuSocial.url}
            onChange={cambiarDatosDelMenuSocial}
          />
          <p>Estado:</p>

          <div className="switch-button-modal">
            <input
              type="checkbox"
              name="mostrar"
              id="switch-label"
              checked={formMenuSocial.mostrar}
              className={
                formMenuSocial.mostrar
                  ? "switch-button-checkbox switch-button-checkbox-activo"
                  : "switch-button-checkbox"
              }
              onChange={cambiarDatosDelMenuSocialCheked}
            />
            <label
              htmlFor="switch-label"
              className="switch-button-label"
            ></label>
          </div>

          <input type="submit" value="Editar Menu" />
        </form>

        <br />
      </div>
    </div>
  );
};
export default MenuModalSocialEditar;
