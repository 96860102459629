import React, { useState, useEffect } from "react";
import { editarArrayDireccion } from "../../../controllers/Direcciones";
import venezuela from "./venezuela";
import "./../Modal.css";

const initFormDireccion = {
  direccion: "",
  recomendacion: "",
};

const MenuModalSocialEditar = ({
  idCliente,
  setEstadoMenuModal,
  menuSeleccionado,
  menuPrincipal,
  posicionMenu,
}) => {
  const [estadoSelect, setEstadoSelect] = useState(menuSeleccionado.estado);
  const [municipioSelect, setMunicipioSelect] = useState(
    menuSeleccionado.municipio
  );
  const [parroquiaSelect, setParroquiaSelect] = useState(
    menuSeleccionado.parroquia
  );
  const [estadoData, setEstadoData] = useState([]);
  const [municipioData, setMunicipioData] = useState([]);
  const [parroquiaData, setParroquiaData] = useState([]);
  const [formDireccion, setFormDireccion] = useState(menuSeleccionado);

  useEffect(() => {
    
    setEstadoData(
      venezuela.map((doc) => ({
        idEstado: doc.id_estado,
        estado: doc.estado,
      }))
    );

    const municipiosFiltrados = venezuela.filter(
      (provinciaFiltrada) =>
        provinciaFiltrada.estado === menuSeleccionado.estado
    );
    setMunicipioData(municipiosFiltrados[0].municipios);

    const parroquiaFiltrados = municipiosFiltrados[0].municipios.filter(
      (parroquiaFiltrada) =>
        parroquiaFiltrada.municipio === menuSeleccionado.municipio
    );
    setParroquiaData(parroquiaFiltrados[0].parroquias);
  }, [menuSeleccionado.estado, menuSeleccionado.municipio]);

  const cambiarSelectEstado = (event) => {
    setEstadoSelect(event.target.value);
    const municipiosFiltrados = venezuela.filter(
      (provinciaFiltrada) => provinciaFiltrada.estado === event.target.value
    );
    setMunicipioData(municipiosFiltrados[0].municipios);
  };

  const cambiarSelectMunicipio = (event) => {
    setMunicipioSelect(event.target.value);
    const parroquiaFiltrados = municipioData.filter(
      (parroquiaFiltrada) => parroquiaFiltrada.municipio === event.target.value
    );
    setParroquiaData(parroquiaFiltrados[0].parroquias);
  };

  const cambiarSelectParroquia = (event) => {
    setParroquiaSelect(event.target.value);
  };

  const cambiarDatosFormDireccion = (e) => {
    const { name, value } = e.target;
    setFormDireccion({
      ...formDireccion,
      [name]: value,
    });
  };

  const editarNuevoMenuSocial = (e) => {
    e.preventDefault();
    const copiaMenuPrincipal = [...menuPrincipal];
    const nuevaDireccion = {
      estado: estadoSelect,
      municipio: municipioSelect,
      parroquia: parroquiaSelect,
      idDireccion: formDireccion.idDireccion,
      direccion: formDireccion.direccion,
      recomendacion: formDireccion.recomendacion,
    };
    copiaMenuPrincipal[posicionMenu] = nuevaDireccion;
    editarArrayDireccion(copiaMenuPrincipal, idCliente);
    setFormDireccion(initFormDireccion);
    setEstadoMenuModal(false);
  };

  return (
    <div className="contenedor-padre-modal">
      <div className="contenedor-modal">
        <div className="modal-cerrar">
          {" "}
          <button onClick={() => setEstadoMenuModal(false)}>
            <img src="/icons/Cerrar.svg" alt="" />
          </button>
        </div>

        <form onSubmit={editarNuevoMenuSocial}>
          <label>
            Escoja su Estado:
            <select onChange={cambiarSelectEstado} value={estadoSelect}>
              <option disabled value={"Seleccione su Estado"}>
                Seleccione su Estado
              </option>
              {estadoData.map((itemEstado) => (
                <option key={itemEstado.idEstado} value={itemEstado.estado}>
                  {itemEstado.estado}
                </option>
              ))}{" "}
            </select>
          </label>
          <label>
            Escoja su Municipio:
            <select onChange={cambiarSelectMunicipio} value={municipioSelect}>
              <option disabled value={"Seleccione su Municipio"}>
                Seleccione su Municipio
              </option>
              {municipioData.map((municipioItem) => (
                <option
                  key={municipioItem.municipio}
                  value={municipioItem.municipio}
                >
                  {municipioItem.municipio}
                </option>
              ))}{" "}
            </select>
          </label>
          <label>
            Escoja su Parroquia:
            <select onChange={cambiarSelectParroquia} value={parroquiaSelect}>
              <option disabled value={"Seleccione su Parroquia"}>
                Seleccione su Parroquia
              </option>
              {parroquiaData.map((parroquia) => (
                <option key={parroquia} value={parroquia}>
                  {parroquia}
                </option>
              ))}{" "}
            </select>
          </label>
          <input
            type="text"
            required
            name="direccion"
            placeholder="Direccion"
            value={formDireccion.direccion}
            onChange={cambiarDatosFormDireccion}
          />
          <textarea
            name="recomendacion"
            required
            cols="30"
            rows="2"
            placeholder="Recomendaciones de envio"
            value={formDireccion.recomendacion}
            onChange={cambiarDatosFormDireccion}
          ></textarea>
          <input type="submit" value="Submit" />
        </form>

        <br />
      </div>
    </div>
  );
};
export default MenuModalSocialEditar;
