import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { traerUnManifiesto } from "../../controllers/Manifiesto";
import "./Manifiesto.css";

const Manifiesto = () => {
  const [manifiesto, setManifiesto] = useState({});
  useEffect(() => {
    (async () => {
      const manifiestoDoc = await traerUnManifiesto();
      setManifiesto(manifiestoDoc);
    })();
  }, []);
  return (
    Object.keys(manifiesto).length !== 0 && (
      <div className="manifiesto">
        <div className="manifiesto-imagen">
          <img loading="lazy" src={manifiesto.imagen} alt="" />
        </div>
        <div className="manifiesto-contenido">
          <h3>{manifiesto.titulo} </h3>
          <h4>PALMA STORE EST 2019 </h4>
          <p>{manifiesto.descripcion}</p>
          <p>{manifiesto.nombres}</p>
          <Link to={{ pathname: `${manifiesto.url}` }} target="_blank">
            OUR INSTAGRAM
          </Link>
          <span>@thepalmastore</span>
        </div>
      </div>
    )
  );
};
export default Manifiesto;
