import React, { useState, useContext } from "react";
import { EstadoContexto } from "../../context/EstadoGeneral";
import {
  productoAumentarUnidadIDB,
  productoDisminuirUnidadIDB,
  productoEliminarIDB,
} from "../../controllers/IndexDB";
import { favoritoAgregar } from "../../controllers/Favoritos";

import "./ProductoCheckout.css";

const ProductoLinea = ({ producto, anchoPantalla }) => {
  const { usuario } = useContext(EstadoContexto);
  const exiteUsuario = Object.keys(usuario).length;

  const [formCantidad, setFormCantidad] = useState(producto.cantidad);

  const aumentar = () => {
    setFormCantidad(formCantidad + 1);
    productoAumentarUnidadIDB(producto.id, formCantidad);
  };

  const disminuir = () => {
    if (formCantidad > 1) {
      setFormCantidad(formCantidad - 1);
      productoDisminuirUnidadIDB(producto.id, formCantidad);
    }
  };

  const guardarProducto = (producto) => {
    if (exiteUsuario) {
      favoritoAgregar(usuario.idCliente, producto);
      productoEliminarIDB(producto.id)
    } else {
      console.log("Debe registrarse para guardar su producto");
    }
  };

  return anchoPantalla.ancho >= 800 ? (
    <tr>
      <td>
        <div className="datos-producto-linea">
          <img className="imagen-tabla" src={producto.imagenes[0]} alt="" />
          <div className="controles-producto-linea">
            <p>{producto.nombre}</p>
            <p>USD {producto.precio}</p>
            <div>
              {formCantidad}
              <button
                style={{ width: "25px", height: "25px", background: "black" }}
                onClick={() => aumentar()}
              >
                +
              </button>
              <button
                style={{ width: "25px", height: "25px", background: "black" }}
                onClick={() => disminuir()}
              >
                -
              </button>
            </div>
          </div>
        </div>
      </td>

      <td>
        <h3>USD {formCantidad * parseFloat(producto.precio)} </h3>
      </td>

      <td>
        <button
          style={{ background: "black" }}
          onClick={() => productoEliminarIDB(producto.id)}
        >
          Eliminar
        </button>
        <br />
        <br />
        <button
          style={{ background: "black" }}
          onClick={() => guardarProducto(producto)}
        >
          Guardar
        </button>
      </td>
    </tr>
  ) : (
    <tr>
      <td className="contenedor-celular-productos">
        <div className="datos-producto-linea">
          <img className="imagen-tabla" src={producto.imagenes[0]} alt="" />
          <div className="controles-producto-linea">
            <p>{producto.nombre}</p>
            <p>USD {producto.precio}</p>
            <div>
              {formCantidad}
              <button
                style={{ width: "25px", height: "25px", background: "black" }}
                onClick={() => aumentar()}
              >
                +
              </button>
              <button
                style={{ width: "25px", height: "25px", background: "black" }}
                onClick={() => disminuir()}
              >
                -
              </button>
            </div>
          </div>
        </div>
        <div>
          <h3>USD {formCantidad * parseFloat(producto.precio)} </h3>
          <div>
            <button
              style={{ background: "black" }}
              onClick={() => productoEliminarIDB(producto.idProducto)}
            >
              Eliminar
            </button>
            <br />
            <br />
            <button
              style={{ background: "black" }}
              onClick={() => guardarProducto(producto)}
            >
              Guardar
            </button>
          </div>
        </div>
      </td>
    </tr>
  );
};

export default ProductoLinea;
