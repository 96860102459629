import React, { useState, useEffect } from "react";
import { db } from "../../../firebase/firebase";
import { collection, onSnapshot, query, orderBy } from "firebase/firestore";
import { TablaMensajes } from "../../../components/Tablas";

const Mensajes = () => {
  const [clientes, setClientes] = useState([]);

  useEffect(() => {
    const productosRef = collection(db, "Mensajes");
    const queryProductos = query(
      productosRef,
      orderBy("fecha", "desc")
    );
    onSnapshot(queryProductos, (snapshot) => {
      setClientes(
        snapshot.docs.map((doc) => ({
          idCliente: doc.id,
          ...doc.data(),
        }))
      );
    });
  }, []);

  return (
    <>
      <h4 className="titulo-panel-administracion">Clientes</h4>
      {clientes?.length === 0 ? (
        ""
      ) : (
        <>
          <TablaMensajes clientes={clientes} />
        </>
      )}
    </>
  );
};

export default Mensajes;
