import React, { useState, useRef } from "react";
import {
  editarArrayMenuPrincipal,
  editarMenuPrincipalCF,
} from "../../../controllers/MenuPrincipal";
import MostrarImagenes from "../../MostrarImagenes/MostrarImagenes";
import BuscarTipoImagen from "../../../utils/BuscarTipoImagen";
import "./../Modal.css";

const initFormMenuPrincipal = {
  nombreCategoria: "",
  urlCategoria: "",
};

const MenuModalPrincipalEditar = ({
  setEstadoMenuModal,
  menuSeleccionado,
  menuPrincipal,
  posicionMenu,
}) => {
  const [formMenuPrincipal, setFormMenuPrincipal] = useState(menuSeleccionado);
  const [fotos, setFotos] = useState(menuSeleccionado.imagenesCategoria);
  const [fotosVista, setFotosVista] = useState(
    menuSeleccionado.imagenesCategoria
  );
  const imagenRef = useRef();

  const cambiarDatosDelMenuPrincipal = (e) => {
    const { name, value } = e.target;
    setFormMenuPrincipal({
      ...formMenuPrincipal,
      [name]: value,
    });
  };

  const cambiarDatosDelMenuPrincipalCheked = (e) => {
    const mostrar = "mostrar";
    setFormMenuPrincipal({
      ...formMenuPrincipal,
      [mostrar]: e.currentTarget.checked,
    });
  };

  const cambiarFotos = (e) => {
    if (e.target.files) {
      const fotosArray = Array.from(e.target.files).map((file) =>
        URL.createObjectURL(file)
      );
      const fotosArray2 = Array.from(e.target.files);

      setFotos((prevImages) => prevImages.concat(fotosArray));
      Array.from(e.target.files).map((file) => URL.revokeObjectURL(file));

      setFotosVista((prevImages) => prevImages.concat(fotosArray2));
    }
  };

  const editarNuevoMenuPrincipal = (e) => {
    e.preventDefault();
    if (fotosVista.length > 2) {
      const fotosAntiguas = BuscarTipoImagen("firebasestorage", fotos);
      const buscarImgBlob = BuscarTipoImagen("blob", fotos);
      if (buscarImgBlob.length === 0) {
        console.log(1);
        const copiaMenuPrincipal = [...menuPrincipal];
        formMenuPrincipal.imagenesCategoria = fotos;
        copiaMenuPrincipal[posicionMenu] = formMenuPrincipal;
        editarArrayMenuPrincipal(copiaMenuPrincipal);
      } else if (buscarImgBlob.length !== 0) {
        const fotosNuevas = fotosVista.splice(
          fotosAntiguas.length,
          buscarImgBlob.length
        );
        editarMenuPrincipalCF(
          menuPrincipal,
          formMenuPrincipal,
          posicionMenu,
          fotosAntiguas,
          fotosNuevas
        );
      }
      setFormMenuPrincipal(initFormMenuPrincipal);
      setFotos([]);
      setFotosVista([]);
      imagenRef.current.value = "";
      setEstadoMenuModal(false);
    } else {
      console.log("Añade 3 imagenes, porfa");
    }
  };

  return (
    <div className="contenedor-padre-modal">
      <div className="contenedor-modal">
        <div className="modal-cerrar">
          {" "}
          <button onClick={() => setEstadoMenuModal(false)}>
            <img src="/icons/Cerrar.svg" alt="" />
          </button>
        </div>

        <form onSubmit={editarNuevoMenuPrincipal}>
          <p>Nombre Menu:</p>
          <input
            type="text"
            required
            name="nombreCategoria"
            placeholder="Nombre Menu"
            value={formMenuPrincipal.nombreCategoria}
            onChange={cambiarDatosDelMenuPrincipal}
          />
          <p>URL Menu:</p>
          <input
            type="text"
            required
            name="urlCategoria"
            placeholder="URL Menu"
            value={formMenuPrincipal.urlCategoria}
            onChange={cambiarDatosDelMenuPrincipal}
          />
          <p>Estado:</p>
          <div className="switch-button-modal">
            <input
              type="checkbox"
              name="mostrar"
              id="switch-label"
              checked={formMenuPrincipal.mostrar}
              className={
                formMenuPrincipal.mostrar
                  ? "switch-button-checkbox switch-button-checkbox-activo"
                  : "switch-button-checkbox"
              }
              onChange={cambiarDatosDelMenuPrincipalCheked}
            />
            <label
              htmlFor="switch-label"
              className="switch-button-label"
            ></label>
          </div>

          <p>Imagenes:</p>
          <span
            className="agregar-caracteristica"
            onClick={() => imagenRef.current.click()}
          >
            Subir Imagen
          </span>
          <input
            ref={imagenRef}
            type="file"
            id="file"
            multiple
            onChange={cambiarFotos}
            style={{
              display: "none",
            }}
          />
          <MostrarImagenes
            fotos={fotos}
            fotosVista={fotosVista}
            setFotos={setFotos}
            setFotosVista={setFotosVista}
          />
          <input type="submit" value="Editar Menu" />
        </form>
      </div>
    </div>
  );
};
export default MenuModalPrincipalEditar;
