import React, { useState } from "react";
import { etiquetaCrear } from "../../../controllers/Etiquetas";
import GenerarUrl from "../../../utils/GenerarUrl";
import "./../Modal.css";

const initFormEtiqueta = {
  nombre: "",
  descripcion: "",
  url: "",
};

const EtiquetasCrearModal = ({ setEstadoModal }) => {
  const [formEtiqueta, setFormEtiqueta] = useState(initFormEtiqueta);

  const cambiarDatosCrearEtiqueta = (e) => {
    const { name, value } = e.target;
    setFormEtiqueta({
      ...formEtiqueta,
      [name]: value,
    });
  };

  function onBlur() {
    setFormEtiqueta({
      ...formEtiqueta,
      url: GenerarUrl(formEtiqueta.nombre),
    });
  }

  const crearEtiqueta = (e) => {
    e.preventDefault();
    etiquetaCrear(formEtiqueta);
    setFormEtiqueta(initFormEtiqueta);
    setEstadoModal(false);
  };

  return (
    <div className="contenedor-padre-modal">
      <div className="contenedor-modal">
        <div className="modal-cerrar">
          {" "}
          <button onClick={() => setEstadoModal(false)}>
            <img src="/icons/Cerrar.svg" alt="" />
          </button>
        </div>

        <form onSubmit={crearEtiqueta}>
          <p>Nombre:</p>
          <input
            type="text"
            required
            name="nombre"
            placeholder="Nombre de Etiqueta"
            value={formEtiqueta.nombre}
            onChange={cambiarDatosCrearEtiqueta}
            onBlur={onBlur}
          />

          <p>URL:</p>
          <input
            type="text"
            disabled
            required
            defaultValue={GenerarUrl(formEtiqueta.nombre)}
            placeholder="URL Etiqueta generado."
          />

          <p>Descripción:</p>
          <textarea
            name="descripcion"
            required
            cols="30"
            rows="2"
            placeholder="Descripción de la Etiqueta"
            value={formEtiqueta.descripcion}
            onChange={cambiarDatosCrearEtiqueta}
          ></textarea>
          <input type="submit" value="Guardar Etiqueta" />
        </form>
      </div>
    </div>
  );
};
export default EtiquetasCrearModal;
