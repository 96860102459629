import React, { useState, useEffect } from "react";
import { db } from "../../../firebase/firebase";
import { doc, onSnapshot } from "firebase/firestore";
import {
  editarArrayMenuPrincipal,
  mostrarArrayMenuPrincipal,
} from "../../../controllers/MenuPrincipal";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import {
  SubMenuModalCrear,
  MenuModalPrincipalCrear,
  SubMenuModalEditar,
  MenuModalPrincipalEditar,
} from "../../../components/Modal";
import CaracteristicasMenu from "../../../components/CaracteristicasMenu/CaracteristicasMenu";
import MenuNavegacion from "./MenuNavegacion";
import "./Menu.css";

const reodernarLista = (nuevaLista, posicionInicio, posicionFinal) => {
  const nuevaListaMenu = [...nuevaLista];
  const [removido] = nuevaListaMenu.splice(posicionInicio, 1);
  nuevaListaMenu.splice(posicionFinal, 0, removido);
  return nuevaListaMenu;
};

const MenuPrincipal = () => {
  const [menuPrincipal, setMenuPrincipal] = useState([]);
  const [estadoModal, setEstadoModal] = useState(false);
  const [estadoModalEditar, setEstadoModalEditar] = useState(false);
  const [estadoMenuModal, setEstadoMenuModal] = useState(false);
  const [estadoMenuModalEditar, setEstadoMenuModalEditar] = useState(false);
  const [idSeleccionado, setIdSeleccionado] = useState("");
  const [subMenuSeleccionado, setSubMenuSeleccionado] = useState({});
  const [menuSeleccionado, setMenuSeleccionado] = useState({});
  const [posicionMenu, setPosicionMenu] = useState(0);
  const [mostrar, setMostrar] = useState(false);

  useEffect(() => {
    const idMenuPrincipal = "UiD9tShW9IWTuu5qst0P";
    const menuPrincipalRef = doc(db, "Menu", idMenuPrincipal);
    onSnapshot(menuPrincipalRef, (doc) => {
      setMenuPrincipal(doc.data().MenuPrincipal);
      setMostrar(doc.data().mostrar);
    });
  }, []);

  const eliminarMenu = (id) => {
    setMenuPrincipal(
      menuPrincipal.filter((elemento) => elemento.idCategoria !== id)
    );
  };

  const editarMenu = (categoria, index) => {
    setPosicionMenu(index);
    setMenuSeleccionado(categoria);
    setEstadoMenuModalEditar(!estadoMenuModalEditar);
  };

  const guardarNuevoMenu = (e) => {
    e.preventDefault();
    editarArrayMenuPrincipal(menuPrincipal);
  };

  const abrirModalSubMenu = (id) => {
    setEstadoModal(!estadoModal);
    setIdSeleccionado(id);
  };

  const abrirModalSubMenuEditar = (id, subCategoria) => {
    setEstadoModalEditar(!estadoModalEditar);
    setIdSeleccionado(id);
    setSubMenuSeleccionado(subCategoria);
  };

  return menuPrincipal?.length === 0 ? (
    <></>
  ) : (
    <>
      {estadoModal && (
        <SubMenuModalCrear
          setEstadoModal={setEstadoModal}
          idSeleccionado={idSeleccionado}
          menuPrincipal={menuPrincipal}
          setMenuPrincipal={setMenuPrincipal}
        />
      )}
      {estadoModalEditar && (
        <SubMenuModalEditar
          setEstadoModal={setEstadoModalEditar}
          idSeleccionado={idSeleccionado}
          subMenuSeleccionado={subMenuSeleccionado}
          menuPrincipal={menuPrincipal}
          setMenuPrincipal={setMenuPrincipal}
        />
      )}
      {estadoMenuModal && (
        <MenuModalPrincipalCrear setEstadoMenuModal={setEstadoMenuModal} />
      )}
      {estadoMenuModalEditar && (
        <MenuModalPrincipalEditar
          setEstadoMenuModal={setEstadoMenuModalEditar}
          menuSeleccionado={menuSeleccionado}
          menuPrincipal={menuPrincipal}
          posicionMenu={posicionMenu}
        />
      )}
      <MenuNavegacion />
      <DragDropContext
        onDragEnd={(result) => {
          const { source, destination } = result;
          if (!destination) {
            return;
          }
          if (
            source.index === destination.index &&
            source.droppableId === destination.droppableId
          ) {
            return;
          }

          setMenuPrincipal((listaAntigua) =>
            reodernarLista(listaAntigua, source.index, destination.index)
          );
        }}
      >
        <Droppable droppableId="tasks">
          {(droppableProvided) => (
            <ul
              {...droppableProvided.droppableProps}
              ref={droppableProvided.innerRef}
              className="contenedor-direcciones-drop"
            >
              {menuPrincipal.map((categoria, index) => (
                <Draggable
                  key={categoria.idCategoria}
                  draggableId={categoria.idCategoria}
                  index={index}
                >
                  {(draggableProvided) => (
                    <li
                      {...draggableProvided.draggableProps}
                      ref={draggableProvided.innerRef}
                      {...draggableProvided.dragHandleProps}
                    >
                      <p>{categoria.nombreCategoria}</p>

                      <div>
                        <CaracteristicasMenu
                          categoria={categoria}
                          menuPrincipal={menuPrincipal}
                          setMenuPrincipal={setMenuPrincipal}
                          index={index}
                          abrirModalSubMenuEditar={abrirModalSubMenuEditar}
                        />
                        <div className="contenedor-botones-drop">
                          <span
                            style={{
                              backgroundColor: "#f2c9cd",
                              marginTop: "5px",
                            }}
                            onClick={() =>
                              abrirModalSubMenu(categoria.idCategoria)
                            }
                          >
                            AGREGAR SUB MENU
                          </span>
                          <div className="drop-botones-drop-menu">
                            <span
                              style={{ backgroundColor: "#dadad8" }}
                              onClick={() => editarMenu(categoria, index)}
                            >
                              EDITAR MENU
                            </span>
                            <span
                              style={{
                                backgroundColor: "#dadad8",
                              }}
                              onClick={() =>
                                eliminarMenu(categoria.idCategoria)
                              }
                            >
                              ELIMINAR MENU
                            </span>
                          </div>
                        </div>
                      </div>
                    </li>
                  )}
                </Draggable>
              ))}
              {droppableProvided.placeholder}
            </ul>
          )}
        </Droppable>
      </DragDropContext>

      <div className="switch-button-modal">
        <input
          type="checkbox"
          name="switch-button"
          id="switch-label"
          checked={mostrar}
          className={
            mostrar
              ? "switch-button-checkbox switch-button-checkbox-activo"
              : "switch-button-checkbox"
          }
          onChange={(event) =>
            mostrarArrayMenuPrincipal(event.currentTarget.checked)
          }
        />
        <label htmlFor="switch-label" className="switch-button-label"></label>
      </div>
      <span
        className="drop-botones-guardar"
        style={{
          backgroundColor: "#555555",
          color: "white",
        }}
        onClick={guardarNuevoMenu}
      >
        GUARDAR MENU
      </span>

      <span
        className="drop-botones-agregar"
        style={{
          backgroundColor: "black",
          color: "white",
        }}
        onClick={() => setEstadoMenuModal(!estadoMenuModal)}
      >
        AGREGAR NUEVO MENU{" "}
      </span>
    </>
  );
};

export default MenuPrincipal;
