import React from "react";

import "./Titulo3.css";

const Titulo3 = ({ titulo }) => {
  return (
    <div className="titulo-componente-2">
      <img loading="lazy" src="/images/imagen-luna.png" alt="" />
      <h2>{titulo}</h2>
    </div>
  );
};
export default Titulo3;
