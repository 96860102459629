import React, { useState, useEffect } from "react";
import {
  agregarArrayDireccion,
  crearDireccion,
} from "../../../controllers/Direcciones";
import venezuela from "./venezuela";
import "./../Modal.css";

const initFormDireccion = {
  direccion: "",
  recomendacion: "",
};

const MenuModalSocialCrear = ({ setEstadoMenuModal, idCliente, tamanio }) => {
  const [estadoSelect, setEstadoSelect] = useState("Seleccione su Estado");
  const [municipioSelect, setMunicipioSelect] = useState(
    "Seleccione su Municipio"
  );
  const [parroquiaSelect, setParroquiaSelect] = useState(
    "Seleccione su Parroquia"
  );

  const [estadoData, setEstadoData] = useState([]);
  const [municipioData, setMunicipioData] = useState([]);
  const [parroquiaData, setParroquiaData] = useState([]);

  const [formDireccion, setFormDireccion] = useState(initFormDireccion);

  useEffect(() => {
    setEstadoData(
      venezuela.map((doc) => ({
        idEstado: doc.id_estado,
        estado: doc.estado,
      }))
    );
  }, []);

  const cambiarSelectEstado = (event) => {
    setEstadoSelect(event.target.value);
    const municipiosFiltrados = venezuela.filter(
      (provinciaFiltrada) => provinciaFiltrada.estado === event.target.value
    );
    setMunicipioData(municipiosFiltrados[0].municipios);
  };

  const cambiarSelectMunicipio = (event) => {
    setMunicipioSelect(event.target.value);
    const parroquiaFiltrados = municipioData.filter(
      (parroquiaFiltrada) => parroquiaFiltrada.municipio === event.target.value
    );
    setParroquiaData(parroquiaFiltrados[0].parroquias);
  };

  const cambiarSelectParroquia = (event) => {
    setParroquiaSelect(event.target.value);
  };

  const cambiarDatosFormDireccion = (e) => {
    const { name, value } = e.target;
    setFormDireccion({
      ...formDireccion,
      [name]: value,
    });
  };

  const guardarDireccion = (e) => {
    e.preventDefault();
    const direcionEnvio = {
      estado: estadoSelect,
      municipio: municipioSelect,
      parroquia: parroquiaSelect,
    };
    if (tamanio !== 0) {
      agregarArrayDireccion(idCliente, formDireccion, direcionEnvio);
    } else {
      crearDireccion(idCliente, formDireccion, direcionEnvio);
    }
    setFormDireccion(initFormDireccion);
    setEstadoMenuModal(false);
  };

  return (
    <div className="contenedor-padre-modal">
      <div className="contenedor-modal">
        <div className="modal-cerrar">
          {" "}
          <button onClick={() => setEstadoMenuModal(false)}>
            <img src="/icons/Cerrar.svg" alt="" />
          </button>
        </div>
        <form onSubmit={guardarDireccion}>
          <label>
            Escoja su Estado:
            <select onChange={cambiarSelectEstado} value={estadoSelect}>
              <option disabled value={"Seleccione su Estado"}>
                Seleccione su Estado
              </option>
              {estadoData.map((estadoItem) => (
                <option key={estadoItem.idEstado} value={estadoItem.estado}>
                  {estadoItem.estado}
                </option>
              ))}{" "}
            </select>
          </label>
          <label>
            Escoja su Municipio:
            <select onChange={cambiarSelectMunicipio} value={municipioSelect}>
              <option disabled value={"Seleccione su Municipio"}>
                Seleccione su Municipio
              </option>
              {municipioData.map((municipioItem) => (
                <option
                  key={municipioItem.municipio}
                  value={municipioItem.municipio}
                >
                  {municipioItem.municipio}
                </option>
              ))}{" "}
            </select>
          </label>
          <label>
            Escoja su Parroquia:
            <select onChange={cambiarSelectParroquia} value={parroquiaSelect}>
              <option disabled value={"Seleccione su Parroquia"}>
                Seleccione su Parroquia
              </option>
              {parroquiaData.map((parroquia) => (
                <option key={parroquia} value={parroquia}>
                  {parroquia}
                </option>
              ))}{" "}
            </select>
          </label>
          <input
            type="text"
            required
            name="direccion"
            placeholder="Direccion"
            value={formDireccion.direccion}
            onChange={cambiarDatosFormDireccion}
          />
          <textarea
            name="recomendacion"
            required
            cols="30"
            rows="2"
            placeholder="Recomendaciones de envio"
            value={formDireccion.recomendacion}
            onChange={cambiarDatosFormDireccion}
          ></textarea>
          <input type="submit" value="Submit" />
        </form>

        <br />
      </div>
    </div>
  );
};
export default MenuModalSocialCrear;
