import React, { useEffect, useState, useContext } from "react";
import Axios from "axios";
import { EstadoContexto } from "../../../context/EstadoGeneral";
import { useLocation, useHistory } from "react-router-dom";
import {
  productosTraerIDB,
  elminarCarritoIDB,
} from "../../../controllers/IndexDB";

//let functionCrearPago =   "http://localhost:5001/ecommerce-palma-5acc0/us-central1/crearPago";
let functionCrearPago = "https://us-central1-ecommerce-palma-5acc0.cloudfunctions.net/crearPago";
const ConfirmacionPago = () => {
  const { usuario } = useContext(EstadoContexto);
  const history = useHistory();
  const [compraExitosa, setCompraExitosa] = useState("esperando");
  const useQuery = () => {
    return new URLSearchParams(useLocation().search);
  };
  let query = useQuery();
  let payerIDPayPal = query.get("PayerID");
  let tokenPayPal = query.get("token");

  

  useEffect(() => {
    (async () => {
      if (payerIDPayPal !== null && tokenPayPal !== null) {
        const direccion = {
          direccion: localStorage.getItem("direccion"),
          estado: localStorage.getItem("estado"),
          recomendacion: localStorage.getItem("recomendacion"),
          municipio: localStorage.getItem("municipio"),
          parroquia: localStorage.getItem("parroquia"),
        };
        const pedido = {
          payerIDPayPal: payerIDPayPal,
          tokenPayPal: tokenPayPal,
          usuario: {
            idCliente: usuario.idCliente,
            nombres: usuario.nombres,
            apellidos: usuario.apellidos,
            correo: usuario.correo,
            celular: usuario.celular,
          },
          productos: await productosTraerIDB(),
          direccion,
        };

        const request = await Axios({
          method: "post",
          baseURL: functionCrearPago,
          data: JSON.stringify(pedido),
          headers: {
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "https://thepalmastore.com",
          },
          withCredentials: true,
        });
        if (request.data) {
          elminarCarritoIDB();
          setCompraExitosa("comprado");
          setTimeout(() => {
            history.push(`/cliente/mis-compras`);
          }, 1000);
        } else {
          console.log("No se puedo comprar");
          setCompraExitosa("error");
        }
      }
    })();
  }, [payerIDPayPal, tokenPayPal, usuario, history]);

  return (
    <>
      <div className="titulo-paginas">
        <h1>CONFIRMACIÓN DEL PEDIDO</h1>
      </div>
      <p>Esperando confirmación de la compra:</p>
      {compraExitosa === "esperando" && <h3>Procesando....</h3>}
      {compraExitosa === "comprado" && <h3>Gracias por comprar</h3>}
      {compraExitosa === "error" && <h3>Error en la compra</h3>}
    </>
  );
};

export default ConfirmacionPago;
