import React, { useState, useEffect } from "react";
import { db } from "../../../firebase/firebase";
import { collection, onSnapshot } from "firebase/firestore";
import { categoriaEliminar } from "../../../controllers/Categorias";
import { TablaCategoria } from "../../../components/Tablas";
import { CategoriasCrearModal, CategoriasEditarModal } from "../../../components/Modal";

const Categorias = () => {
  const [estadoModal, setEstadoModal] = useState(false);
  const [estadoModalEditar, setEstadoModalEditar] = useState(false);
  const [categorias, setCategorias] = useState([]);
  const [categoriaSeleccionada, setCategoriaSeleccionada] = useState({});

  useEffect(() => {
    onSnapshot(collection(db, "Categorias"), (snapshot) => {
      setCategorias(
        snapshot.docs.map((doc) => ({
          idCategoria: doc.id,
          ...doc.data(),
        }))
      );
    });
  }, []);

  const habilitarEditar = (categoriaItem) => {
    setCategoriaSeleccionada(categoriaItem);
    setEstadoModalEditar(!estadoModalEditar);
  };

  return (
    <>
      {estadoModal && <CategoriasCrearModal setEstadoModal={setEstadoModal} />}
      {estadoModalEditar && (
        <CategoriasEditarModal
          setEstadoModalEditar={setEstadoModalEditar}
          categoriaSeleccionada={categoriaSeleccionada}
        />
      )}
      <h4 className="titulo-panel-administracion">Categorias</h4>
      <button
        onClick={() => setEstadoModal(!estadoModal)}
        className="boton-administracion"
      >
        Agregar Categoria
      </button>
      {categorias?.length === 0 ? (
        <h5>No hay categorias</h5>
      ) : (
        <>
          <TablaCategoria
            categorias={categorias}
            habilitarEditar={habilitarEditar}
            categoriaEliminar={categoriaEliminar}
          />
        </>
      )}
    </>
  );
};

export default Categorias;
