import { doc, getDoc, updateDoc } from "firebase/firestore";
import { db } from "../firebase/firebase";
const coleccion = "WhatsApp";
const idWhatsAppProducto = "TAdNCM1ectJZj9HqaHEj";
const idWhatsAppCarrito = "0cP8iHSSGpgOG2kMlQMl";

export const traerWhatsAppProducto = async () => {
  const whatsAppRef = doc(db, coleccion, idWhatsAppProducto);
  const docWhatsApp = await getDoc(whatsAppRef);

  if (docWhatsApp.exists()) {
    return docWhatsApp.data();
  } else {
    console.log("No existe whatsApp producto");
  }
};

export const actualizarWhatsAppProducto = async (formWhatsApp) => {
  try {
    await updateDoc(doc(db, coleccion, idWhatsAppProducto), {
      celular: formWhatsApp.celular,
      mensaje: formWhatsApp.mensaje,
    });
  } catch (e) {
    console.error("Error al actualizar WhatsApp ", e);
  }
};
export const traerWhatsAppCarrito = async () => {
  const whatsAppRef = doc(db, coleccion, idWhatsAppCarrito);
  const docWhatsApp = await getDoc(whatsAppRef);

  if (docWhatsApp.exists()) {
    return docWhatsApp.data();
  } else {
    console.log("No existe whatsApp producto");
  }
};


export const actualizarWhatsAppCarrito = async (formWhatsApp) => {
  try {
    await updateDoc(doc(db, coleccion, idWhatsAppCarrito), {
      celular: formWhatsApp.celular,
      saludo: formWhatsApp.saludo,
      ayuda: formWhatsApp.ayuda,
    });
  } catch (e) {
    console.error("Error al actualizar WhatsApp ", e);
  }
};
