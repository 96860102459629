import React, { useState, useEffect } from "react";
import {
  productosTotal,
  clientesTotal,
  pedidosTotal,
  ventasTotal,
} from "../../../controllers/Reportes";

import "./Reportes.css";

const Reportes = () => {
  const [productos, setProductos] = useState(0);
  const [clientes, setClientes] = useState(0);
  const [pedidos, setPedidos] = useState(0);
  const [total, setTotal] = useState(0);

  useEffect(() => {
    (async () => {
      const totalProductos = await productosTotal();
      setProductos(totalProductos);
      const totalClientes = await clientesTotal();
      setClientes(totalClientes);
      const totalPedidos = await pedidosTotal();
      setPedidos(totalPedidos);
      const totalVentas = await ventasTotal();
      setTotal(totalVentas);
    })();
  }, []);

  return (
    <>
      <div className="contenedor-reportes-card">
        <div className="card-reporte" style={{ backgroundColor: "#f2c9cd" }}>
          <h6>VENTAS</h6>
          <h3>USD.{total}</h3>
        </div>
        <div className="card-reporte" style={{ backgroundColor: "#dadad8" }}>
          <h6>PEDIDOS</h6>
          <h3>{pedidos}</h3>
        </div>

        <div className="card-reporte" style={{ backgroundColor: "#f2c9cd" }}>
          <h6>PRODUCTOS</h6>
          <h3>{productos}</h3>
        </div>
        <div className="card-reporte" style={{ backgroundColor: "#dadad8" }}>
          <h6>CLIENTES</h6>
          <h3>{clientes}</h3>
        </div>
      </div>{" "}
    </>
  );
};

export default Reportes;
