import React from "react";
import Titulo3 from "../../../components/Titulo/Titulo3";

import "./Politicas.css";

const PoliticaPrivacidad = () => {
  return (
    <>
      <Titulo3 titulo={"POLÍTICAS DE PRIVACIDAD"} />
      <div className="contenedor-politicas">
        <h2>SECCIÓN 1 – ¿QUÉ HACEMOS CON TU INFORMACIÓN?</h2>
        <p>
          Cuando compras algo de nuestra tienda, como parte del proceso de
          compra venta, nosotros recolectamos la información personal que nos
          das tales como nombre, dirección y correo electrónico.
        </p>

        <p>
          Cuando realices una compra, tu información será compartida con el
          courrier encargado de realizar el envío de tu producto. La información
          brindada, será: nombre, teléfono de contacto, correo electrónico y
          dirección de entrega.
        </p>
        <p>
          Cuando navegas en nuestra tienda, también recibimos de manera
          automática la dirección de protocolo de internet de tu computadora
          (IP) con el fin de proporcionarnos información que nos ayuda a conocer
          acerca de su navegador y sistema operativo.
        </p>
        <p>
          Email marketing (if applicable): Con tu permiso, podremos enviarte
          correos electrónicos acerca de nuestra tienda, nuevos productos y
          otras actualizaciones
        </p>

        <h2>SECTION 2 – CONSENTIMIENTO</h2>

        <p style={{ fontWeight: "bold" }}>¿Cómo obtienen mi consentimiento?</p>
        <p>
          Cuando nos provees tu información personal para completar una
          transacción, crear una orden, concertar un envío o hacer una
          devolución, implicamos que aceptas la recolección y uso por esa razón
          específica solamente.
        </p>
        <p>
          Si te pedimos tu información personal por una razón secundaria, como
          marketing, te pediremos directamente tu expreso consentimiento, o te
          daremos la oportunidad de negarte.
        </p>
        <p style={{ fontWeight: "bold" }}>
          ¿Cómo puedo anular mi consentimiento?
        </p>
        <p>
          Si luego de haber aceptado cambias de opinión, puedes anular tu
          consentimiento para que te contactemos, por la recolección, uso o
          divulgación de tu información, en cualquier momento, conectándonos a
          través de nuestra página de contacto.
        </p>
        <h2>SECCIÓN 3 – DIVULGACIÓN</h2>

        <p>
          Podemos divulgar tu información personal si se nos requiere por ley o
          si violas nuestros Términos de Servicio.
        </p>
        <h2>SECCIÓN 4 – SERVICES DE TERCERAS PARTES</h2>
        <p>
          En general, los proveedores de terceras partes utilizados por nosotros
          solo recopilarán, usarán y divulgarán tu información en la medida que
          sea necesaria para que les permita desempeñar los servicios que nos
          proveen.
        </p>
        <p>
          Sin embargo, algunos proveedores de servicios de terceros, tales como
          pasarelas de pago y otros procesadores de transacciones de pago,
          tienen sus propias políticas de privacidad con respecto a la
          información que estamos obligados a proporcionarles para las
          transacciones relacionadas con las compras.
        </p>
        <p>
          Para estos proveedores, te recomendamos que leas las políticas de
          privacidad para que puedas entender la manera en que tu información
          personal será manejada.{" "}
        </p>
        <p>
          En particular, recuerda que algunos proveedores pueden estar ubicados
          o contar con instalaciones que se encuentran en una jurisdicción
          diferente a ti o nosotros. Así que, si deseas proceder con una
          transacción que involucra los servicios de un proveedor a terceros, tu
          información puede estar sujeta a las leyes de la jurisdicción
          (jurisdicciones) en que se encuentra el proveedor de servicios o sus
          instalaciones.{" "}
        </p>
        <p>
          A modo de ejemplo, si te encuentras en Canadá y tu transacción es
          procesada por una pasarela de pago con sede en Estados Unidos,
          entonces tu información personal utilizada para completar la
          transacción puede ser sujeto de divulgación en virtud de la
          legislación de Estados Unidos, incluyendo la Ley Patriota.{" "}
        </p>
        <p>
          Una vez que abandonas el sitio web de nuestra tienda o te diriges a un
          sitio o aplicación de terceros, ya no estás siendo regulados por la
          presente Política de Privacidad o los Términos de Servicio de nuestra
          página web.{" "}
        </p>
        <p style={{ fontWeight: "bold" }}>Enlaces</p>
        <p>
          Cuando haces clic en enlaces de nuestra tienda, puede que seas
          redirigido fuera de nuestro sitio. No somos responsables por las
          prácticas de privacidad de otros sitios y te recomendamos leer sus
          normas de privacidad.{" "}
        </p>
        <h2>SECCIÓN 5 – EDAD DE CONSENTIMIENTO</h2>

        <p>
          Al utilizar este sitio, declaras que tienes al menos la mayoría de
          edad en tu estado o provincia de residencia y que nos has dado tu
          consentimiento para permitir que cualquiera de tus dependientes
          menores use este sitio.
        </p>
        <h2>SECCIÓN 6 – CAMBIOS A ESTA POLÍTICA DE PRIVACIDAD</h2>

        <p>
          Nos reservamos el derecho de modificar esta política de privacidad en
          cualquier momento, así que por favor revísala frecuentemente. Cambios
          y aclaraciones entrarán en vigencia inmediatamente después de su
          publicación en el sitio web. Si hacemos cambios materiales a esta
          política, notificaremos aquí que ha sido actualizada, por lo que cual
          estás enterado de qué información recopilamos, cómo y bajo qué
          circunstancias, si las hubiere, la utilizamos y/o divulgamos.
        </p>

        <p>
          Si nuestra tienda es adquirida o fusionada con otra empresa, tu
          información puede ser transferida a los nuevos propietarios, para que
          podamos seguir vendiéndote productos.{" "}
        </p>

        <h2>PREGUNTAS E INFORMACIÓN DE CONTACTO</h2>

        <p>
          Si quieres: acceder, corregir, enmendar o borrar cualquier información
          personal que poseamos sobre ti, registrar una queja, o simplemente
          quieres más información contacta a nuestro Oficial de Cumplimiento de
          Privacidad a través de nuestra página de contacto.
        </p>
      </div>
    </>
  );
};

export default PoliticaPrivacidad;
