import jsPDF from "jspdf";
import autoTable from "jspdf-autotable";

const Recibo = (pedido) => {
  var productosTabla = pedido.productos.map((item) => {
    return {
      Nombre: item.nombre,
      Cantidad: item.cantidad,
      Descripcion: item.descripcion,
      Precio: item.precio,
      Total: item.cantidad * parseFloat(item.precio),
    };
  });

  const result = productosTabla.map(
    ({ Nombre, Cantidad, Descripcion, Precio, Total }) => [
      Nombre,
      Cantidad,
      Descripcion,
      Precio,
      Total,
    ]
  );
  const doc = new jsPDF();
  autoTable(doc, {
    body: [
      [
        {
          content: "Invoice",
          styles: {
            halign: "right",
            fontSize: 20,
            textColor: "#000",
          },
        },
      ],
    ],
    theme: "plain",
    styles: {
      fillColor: "#fff",
    },
    didDrawPage: function (data) {
      doc.addImage("/logo.jpg", "JPEG", data.settings.margin.left, 13, 20, 15);
    },
  });

  autoTable(doc, {
    body: [
      [
        {
          content:
            `Reference: N° ${pedido.numeroPedido}` +
            `\nDate: ${pedido.fecha.toDate().toLocaleDateString()}`,

          styles: {
            halign: "right",
          },
        },
      ],
    ],
    theme: "plain",
  });

  autoTable(doc, {
    body: [
      [
        {
          content:
            "Cliente:" +
            `\n${pedido.usuario.nombres} ${pedido.usuario.apellidos}` +
            `\n${pedido.usuario.correo}` +
            `\n${pedido.usuario.celular}` +
            `\n${pedido.direccion.estado}` +
            `\n${pedido.direccion.municipio}` +
            `\n${pedido.direccion.parroquia}` +
            `\n${pedido.direccion.direccion}` +
            "\nRecomendación:" +
            `\n${pedido.direccion.recomendacion}`,
          styles: {
            halign: "left",
          },
        },
      ],
    ],
    theme: "plain",
  });

  autoTable(doc, {
    body: [
      [
        {
          content: "Amount due:",
          styles: {
            halign: "right",
            fontSize: 14,
          },
        },
      ],
      [
        {
          content: `USD ${pedido.productos?.reduce(
            (antes, actual) =>
              parseFloat(actual.precio) * actual.cantidad + antes,
            0
          )}`,
          styles: {
            halign: "right",
            fontSize: 20,
            textColor: "#3366ff",
          },
        },
      ],
      [
        {
          content: `${pedido.fecha.toDate().toLocaleDateString()}`,
          styles: {
            halign: "right",
          },
        },
      ],
    ],
    theme: "plain",
  });

  autoTable(doc, {
    body: [
      [
        {
          content: "Products & Services",
          styles: {
            halign: "left",
            fontSize: 14,
          },
        },
      ],
    ],
    theme: "plain",
  });

  autoTable(doc, {
    head: [["PRODUCTO", "CANTIDAD", "DESCRIPCION", "PRECIO", "TOTAL"]],
    body: result,
    theme: "striped",
    headStyles: {
      fillColor: "#343a40",
    },
  });

  autoTable(doc, {
    body: [
      [
        {
          content: "Total amount:",
          styles: {
            halign: "right",
          },
        },
        {
          content: `${pedido.productos?.reduce(
            (antes, actual) =>
              parseFloat(actual.precio) * actual.cantidad + antes,
            0
          )}`,
          styles: {
            halign: "right",
          },
        },
      ],
    ],
    theme: "plain",
  });

  autoTable(doc, {
    body: [
      [
        {
          content: "Terms & notes",
          styles: {
            halign: "left",
            fontSize: 14,
          },
        },
      ],
      [
        {
          content:
            "orem ipsum dolor sit amet consectetur adipisicing elit. Maxime mollitia" +
            "molestiae quas vel sint commodi repudiandae consequuntur voluptatum laborum" +
            "numquam blanditiis harum quisquam eius sed odit fugiat iusto fuga praesentium",
          styles: {
            halign: "left",
          },
        },
      ],
    ],
    theme: "plain",
  });

  autoTable(doc, {
    body: [
      [
        {
          content: "This is a centered footer",
          styles: {
            halign: "center",
          },
        },
      ],
    ],
    theme: "plain",
  });
  return doc.save(
    `${pedido.numeroPedido}-${pedido.usuario.nombres} ${pedido.usuario.apellidos}`
  );
};

export default Recibo;
