import { initializeApp, } from "firebase/app";
import { getFirestore } from "firebase/firestore";
import { getAuth } from "firebase/auth";
import { getStorage } from "firebase/storage";
import { getAnalytics } from "firebase/analytics";

const firebaseConfig = {
  apiKey: "AIzaSyAEPFAsCGTgF_4A_On0mK9-1lwPPXSVjxY",
  authDomain: "ecommerce-palma-5acc0.firebaseapp.com",
  projectId: "ecommerce-palma-5acc0",
  storageBucket: "ecommerce-palma-5acc0.appspot.com",
  messagingSenderId: "306110703661",
  appId: "1:306110703661:web:2e6833574a82188ac070a4",
  measurementId: "G-RK0B5LXPNW",
};

const app = initializeApp(firebaseConfig);
const db = getFirestore();
const storage = getStorage();
const analytics = getAnalytics(app);
const auth = getAuth();
db._settings.experimentalForceLongPolling = true;
export { auth, db, storage, analytics };
