import {
  collection,
  addDoc,
  doc,
  deleteDoc,
  getDoc,
  updateDoc,
  getDocs,
} from "firebase/firestore";
import { db } from "../firebase/firebase";
const coleccion = "Categorias";

/* CREAR UNA CATEGORIA SIN IMAGEN */
export const categoriaCrear = async (formCategoria) => {
  try {
    await addDoc(collection(db, coleccion), {
      nombre: formCategoria.nombre,
      url: formCategoria.url,
      descripcion: formCategoria.descripcion,
    });
  } catch (e) {
    console.error("Error al agregar categoria ", e);
  }
};

/* ELIMINAR UNA ETIQUETA */
export const categoriaEliminar = async (idCategoria) => {
  await deleteDoc(doc(db, coleccion, idCategoria));
};

/* EDITAR UNA CATEGORIA SIN IMAGEN */
export const categoriaEditar = async (formCategoria) => {
  const categoriaRef = doc(db, coleccion, formCategoria.idCategoria);
  await updateDoc(categoriaRef, {
    nombre: formCategoria.nombre,
    url: formCategoria.url,
    descripcion: formCategoria.descripcion,
  });
};


/* TRAER UN DOCUMENTO*/
export const categoriaUna = async (idCategoria) => {
  const categoriaRef = doc(db, coleccion, idCategoria);
  const docCategoria = await getDoc(categoriaRef);
  if (docCategoria.exists()) {
    return docCategoria.data();
  } else {
    console.log("No existe el documento");
  }
};

/* TRAER UN DOCUMENTO*/
export const categoriasTodas = async () => {
  const categoriaRef = collection(db, coleccion);
  const categoriasDB = await getDocs(categoriaRef);
  return categoriasDB.docs.map((doc) => {
    const id = doc.id;
    return {
      idCategoria: id,
      nombre: doc.data().nombre,
      url: doc.data().url
    };
  });
};
