import { doc, getDoc, updateDoc } from "firebase/firestore";
import { getStorage, ref, uploadBytes, getDownloadURL } from "firebase/storage";
import { db } from "../firebase/firebase";
const coleccion = "Manifiesto";
const idManifiesto = "P4ketM7RVmUPvp5TLINd";
const rutaFoto = "manifiesto-imagenes";

export const traerUnManifiesto = async () => {
  const manifiestoRef = doc(db, coleccion, idManifiesto);
  const docManifiesto = await getDoc(manifiestoRef);

  if (docManifiesto.exists()) {
    return docManifiesto.data();
  } else {
    console.log("No existe manifiesto");
  }
};

export const editarManifiestoSinFoto = async (url) => {
  const manifiestoRef = doc(db, coleccion, idManifiesto);
  await updateDoc(manifiestoRef, {
    imagen: url === undefined ? null : url,
  });
};

export const editarManifiestoConFoto = (foto) => {
  const fechaAhora = Date.now();
  const rutaCompleta = foto.name + fechaAhora + foto.lastModified + foto.size;
  const storage = getStorage();
  const imageRef = ref(storage, `${rutaFoto}/${rutaCompleta}`);
  uploadBytes(imageRef, foto)
    .then((snapshot) => {
      getDownloadURL(snapshot.ref).then((url) => {
        editarManifiestoSinFoto(url, idManifiesto);
      });
    })
    .catch((error) => {
      console.error("Error al subir imagen", error);
    });
};

export const editarManifiestoTitulares = async (
  titulo,
  subTitulo,
  descripcion,
  slogan,
  ingles,
  nombres,
  boton,
  url
) => {
  const sliderRef = doc(db, coleccion, idManifiesto);
  await updateDoc(sliderRef, {
    titulo: titulo,
    subTitulo: subTitulo,
    descripcion: descripcion,
    slogan: slogan,
    ingles: ingles,
    nombres: nombres,
    boton: boton,
    url: url,
  });
};
