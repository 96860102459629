import React from "react";
import "./../Modal.css";

const ModalRegistrar = ({ mensajeModal, setEstadoModal }) => {
  return (
    <div className="contenedor-padre-modal">
      <div className="contenedor-modal">
        <h3>Alert</h3>
        <p>{mensajeModal}</p>
        <br />
        <button onClick={() => setEstadoModal(false)}>
          Entendido
        </button>
      </div>
    </div>
  );
};
export default ModalRegistrar;
