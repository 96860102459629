import React, { useContext, useState } from "react";
import { EstadoContexto } from "../../context/EstadoGeneral";
import { Link } from "react-router-dom";
import "./LayoutAdministracion.css";

const LayoutAdministracion = (props) => {
  const { cerrarSesion } = useContext(EstadoContexto);
  const { children } = props;
  const [botonLayout, setBotonLayout] = useState(false);

  return (
    <>
      <section style={{ marginTop: "80px" }}>
        <div className="contenedor-layout-administracion">
          <div className="cabecera-perfil">
            <h3>HOLA, PALMA</h3>
            <p>Este es tu panel de Admimistración</p>
            <button
              onClick={() => setBotonLayout(!botonLayout)}
              className="boton-menu-layout"
            >
              {botonLayout ? "Mostrar Menu" : "Ocultar Menu"}
            </button>
          </div>
          <div className="grid-perfil">
            <div
              className="grid-perfil-menu"
              style={{ display: botonLayout ? "none" : "block" }}
            >
              <ul>
                <li>
                  <Link to="/administracion/reportes">Reportes</Link>
                </li>
                <li>
                  <Link to="/administracion/categorias">Categorias</Link>
                </li>
                <li>
                  <Link to="/administracion/etiquetas">Etiquetas</Link>
                </li>
                <li>
                  <Link to="/administracion/productos">Productos</Link>
                </li>
                <li>
                  <Link to="/administracion/clientes">Clientes</Link>
                </li>
                <li>
                  <Link to="/administracion/mensajes">Mensajes</Link>
                </li>
                <li>
                  <Link to="/administracion/pedidos">Pedidos</Link>
                </li>
                <li>
                  <Link to="/administracion/whatsapp-carrito">WhatsApp</Link>
                </li>
                <li>
                  <Link to="/administracion/slider">Slider</Link>
                </li>
                <li>
                  <Link to="/administracion/menu">Menu</Link>
                </li>
                <li>
                  <Link to="/administracion/galeria">Galeria</Link>
                </li>
                <li>
                  <Link to="/administracion/manifiesto">Manifiesto</Link>
                </li>
                <li>
                  <Link to="/administracion/promocion">Promocion</Link>
                </li>
              </ul>
              <Link
                className="boton-cerrar-sesion"
                to="#"
                onClick={cerrarSesion}
              >
                Cerrar Sesión
              </Link>
            </div>

            <div className="grid-perfil-detalles">{children}</div>
          </div>
        </div>
      </section>
    </>
  );
};

export default LayoutAdministracion;
