import React, { useState, useEffect, useContext } from "react";
import { EstadoContexto } from "../../../context/EstadoGeneral";
import { productoCrearIDB  } from "../../../controllers/IndexDB";
import { favoritoEliminar } from "../../../controllers/Favoritos";
import { db } from "../../../firebase/firebase";
import { collection, onSnapshot } from "firebase/firestore";
import { Link } from "react-router-dom";
import "./Guardados.css";

const Guardados = () => {
  const { usuario } = useContext(EstadoContexto);
  const [guardados, setGuardados] = useState([]);

  useEffect(() => {
    onSnapshot(collection(db, "Usuarios", usuario.idCliente, "Favoritos" ), (snapshot) => {
      setGuardados(
        snapshot.docs.map((doc) => ({
          idProducto: doc.id,
          ...doc.data(),
        }))
      );
    });
  }, [usuario]);

  const agregarCarritoIDB = (producto) => {
    let productoIDB = {
      idProducto: producto.idProducto,
      nombre: producto.nombre,
      marca: producto.marca,
      url: producto.url,
      caracteristicas: producto.caracteristicas,
      precio: producto.precio,
      stock: producto.stock,
      tiempoEntrega: producto.tiempoEntrega,
      categoria: producto.categoria,
      etiqueta: producto.etiqueta,
      imagenes: producto.imagenes,
      estado: true,
      cantidad: 1,
    };
    //console.log(productoIDB);
    productoCrearIDB(productoIDB);
  };

  return (
    <>
      <div className="contenedor-guardados-cliente">
        <h4>My Saved Items</h4>

        {guardados?.length === 0 ? (
          <p>No hay productos guardados</p>
        ) : (
          guardados.map((producto) => (
            <div
              key={producto.idProducto}
              className="contenedor-guardados-productos"
            >
              <div className="guardados-imagen">
                <Link
                  to={`/producto/${producto.url}/${producto.idProducto}`}
                >
                  <img src={producto.imagenes[0]} alt="" />
                </Link>{" "}
              </div>
              <div className="guardados-descripcion">
                <div>
                  <Link
                    to={`/producto/${producto.url}/${producto.idProducto}`}
                  >
                    <h5>{producto.nombre}</h5>
                  </Link>{" "}
                  <span style={{ color: "#8b0910" }}>
                    USD {producto.precio}
                  </span>
                  <br />
                  {/*<span>In Stock</span> */}
                  <div className="guardados-descripcion-botones">
                    {/*
  <select name="" id="">
                      <option>Select Size</option>
                      <option value="XS">XS</option>
                      <option value="S">S</option>
                    </select>

*/}

                    <button onClick={() => agregarCarritoIDB(producto)}>Add to cart</button>
                  </div>
                </div>
                <div className="guardados-descripcion-borrar">
                  <img
                    className="icono-eliminar"
                    onClick={() =>
                      favoritoEliminar(usuario.idCliente, producto.idProducto)
                    }
                    src="/icons/Eliminar.svg"
                    alt="logo"
                  />{" "}
                </div>
              </div>
            </div>
          ))
        )}
      </div>
    </>
  );
};

export default Guardados;
