import React, { useState, useEffect } from "react";
import { todosProductos } from "../../../controllers/Productos";
import ProductosPaginacion from "../../../components/ProductosSimilares/ProductosPaginacion";
import Titulo from "../../../components/Titulo/Titulo";
import Instagram from "../../../components/Instagram/Instagram";
import "./NoEncontrado.css";

const NoEncontrado = () => {
  const [productos, setProductos] = useState([]);

  useEffect(() => {
    (async () => {
      const productosDB = await todosProductos();
      setProductos(productosDB);
    })();
  }, []);

  return (
    <>
      {productos?.length === 0 ? (
        <></>
      ) : (
        <div className="contenedor-tienda">
          <Titulo titulo={"PÁGINA NO ENCONTRADA"} />
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <img
              style={{ marginTop: "8px", width:"70px" }}
              src="/images/no-encontrado.png"
              alt=""
            />
            <h2 style={{ fontSize: "38px" }}>¡Ups!</h2>
            <br />
            <h4>Parece que algo salió mal...</h4>
          </div>
          <ProductosPaginacion productos={productos} />
        </div>
      )}
      <Instagram />
    </>
  );
};

export default NoEncontrado;
