import React from "react";
import ProductoLinea from "./ProductoLinea";
import CalcularPantalla from "../../utils/CalcularPantalla";

import "./ProductoCheckout.css";

const ProductoCheckout = ({ productos }) => {
  const anchoPantalla = CalcularPantalla();

  return anchoPantalla.ancho >= 800 ? (
    <table className="tabla-producto-checkout">
      <thead>
        <tr>
          <th style={{textAlign:"start"}}>PRODUCTO</th>
          <th>TOTAL</th>
          <th>ACCIÓN</th>
        </tr>
      </thead>
      <tbody>
        {productos.map((producto, index) => (
          <ProductoLinea key={index} producto={producto} anchoPantalla={anchoPantalla}  />
        ))}
      </tbody>
    </table>
  ) : (
    <table className="tabla-producto-checkout">
      <thead>
        <tr>
          <th>PRODUCTO</th>
        </tr>
      </thead>
      <tbody>
        {productos.map((producto, index) => (
          <ProductoLinea key={index} producto={producto} anchoPantalla={anchoPantalla} />
        ))}
      </tbody>
    </table>
  );
};

export default ProductoCheckout;
