import React, { useState, useEffect } from "react";
import Menu from "../../components/Menu/Menu";
import Promocion from "../../components/Promocion/Promocion";
import Pie from "../../components/Pie/Pie";
import { todosMenu } from "../../controllers/Menu";
import "./whatsapp.css";

const LayoutGeneral = (props) => {
  const { children } = props;
  const [menus, setMenus] = useState([]);

  useEffect(() => {
    (async () => {
      const menusDB = await todosMenu();
      setMenus(menusDB);
    })();
  }, []);

  const enviarLink = () => {
    const wspLink = `https://wa.link/4lgzxf`;
    window.open(wspLink, "_blank");
  };

  return (
    <>
      {menus?.length === 0 ? (
        <></>
      ) : (
        <>
          <Menu menuPrincipal={menus[1].MenuPrincipal} />
          <section style={{ marginTop: "100px" }}>
            <Promocion />
            <div>{children}</div>
          </section>{" "}
          <Pie
            menuInfo={menus[0].MenuInfo}
            mostrarMenuInfo={menus[0].mostrar}
            menuSocial={menus[2].MenuSocial}
            mostrarMenuSocial={menus[2].mostrar}
            menuPromocion={menus[3].MenuPromocion}
            mostrarMenuPromocion={menus[3].mostrar}
          />
          <div className="whatsapp">
            <img
              src="/icons/WhatsAppContornoBlanco.svg"
              alt="logo"
              onClick={() => enviarLink()}
            />{" "}
          </div>
        </>
      )}
    </>
  );
};

export default LayoutGeneral;
