import React from "react";
import { Switch, Redirect } from "react-router-dom";
import { RutaPublica,RutaAdministrador,RutaCliente } from "./routers";
import { LayoutGeneral, LayoutAdministracion,LayoutCliente } from "./layouts/conLayout";
import SinLayout from "./layouts/sinLayout/SinLayout";
import {
  Pagina404,
  GeneralInicio,
  GeneralIngresar,
  GeneralRegistrar,
  GeneralVerificar,
  GeneralRecuperar,
  GeneralProductoSolo,
  GeneralCheckout,
  GeneralTienda,
  GeneralCambiosDevoluciones,
  GeneralPoliticaPrivacidad,
  GeneralTiemposEntrega,
  GeneralAbout,
  GeneralManifiesto,
  GeneralNoEncontrado,
  AdministracionReportes,
  AdministracionCategorias,
  AdministracionEtiquetas,
  AdministracionProductos,
  AdministracionWhatsAppCarrito,
  AdministracionWhatsAppProducto,
  AdministracionMenuPrincipal,
  AdministracionMenuSocial,
  AdministracionMenuInfo,
  AdministracionMenuPromocion,
  AdministracionGaleria,
  AdministracionSlider,
  AdministracionManifiesto,
  AdministracionPromocion,
  AdministracionClientes,
  AdministracionMensajes,
  AdministracionPedidos,
  ClientePerfil,
  ClienteGuardados,
  ClienteDirecciones,
  ClienteConfirmacionPago,
  ClienteMisCompras,
} from "./pages";

const Routes = () => {
  return (
    <Switch>
      {/* RUTAS PUBLICAS*/}
      <RutaPublica
        component={GeneralInicio}
        exact
        layout={LayoutGeneral}
        path="/"
      />
      <RutaPublica
        component={GeneralIngresar}
        exact
        layout={LayoutGeneral}
        path="/ingresar"
      />
      <RutaPublica
        component={GeneralRegistrar}
        exact
        layout={LayoutGeneral}
        path="/registrar"
      />
      <RutaPublica
        component={GeneralVerificar}
        exact
        layout={LayoutGeneral}
        path="/verificar"
      />
      <RutaPublica
        component={GeneralRecuperar}
        exact
        layout={LayoutGeneral}
        path="/recuperar"
      />
      <RutaPublica
        component={GeneralProductoSolo}
        exact
        layout={LayoutGeneral}
        path="/producto/:producto/:id"
      />
      <RutaPublica
        component={GeneralTienda}
        exact
        layout={LayoutGeneral}
        path="/tienda"
      />
      <RutaPublica
        component={GeneralCheckout}
        exact
        layout={LayoutGeneral}
        path="/checkout"
      />
      <RutaPublica
        component={GeneralCambiosDevoluciones}
        exact
        layout={LayoutGeneral}
        path="/cambios-y-devoluciones"
      />
      <RutaPublica
        component={GeneralPoliticaPrivacidad}
        exact
        layout={LayoutGeneral}
        path="/politicas-de-privacidad"
      />
       <RutaPublica
        component={GeneralTiemposEntrega}
        exact
        layout={LayoutGeneral}
        path="/tiempos-de-entrega"
      />
      <RutaPublica
        component={GeneralAbout}
        exact
        layout={LayoutGeneral}
        path="/about"
      />
       <RutaPublica
        component={GeneralManifiesto}
        exact
        layout={LayoutGeneral}
        path="/manifiesto"
      />
      {/* RUTAS ADMNISTRACIÓN*/}
      <RutaAdministrador
        component={AdministracionReportes}
        exact
        layout={LayoutAdministracion}
        path="/administracion/reportes"
      />
      <RutaAdministrador
        component={AdministracionCategorias}
        exact
        layout={LayoutAdministracion}
        path="/administracion/categorias"
      />
      <RutaAdministrador
        component={AdministracionEtiquetas}
        exact
        layout={LayoutAdministracion}
        path="/administracion/etiquetas"
      />
      <RutaAdministrador
        component={AdministracionProductos}
        exact
        layout={LayoutAdministracion}
        path="/administracion/productos"
      />
      <RutaAdministrador
        component={AdministracionWhatsAppCarrito}
        exact
        layout={LayoutAdministracion}
        path="/administracion/whatsapp-carrito"
      />
      <RutaAdministrador
        component={AdministracionWhatsAppProducto}
        exact
        layout={LayoutAdministracion}
        path="/administracion/whatsapp-producto"
      />
      <RutaAdministrador
        component={AdministracionMenuPrincipal}
        exact
        layout={LayoutAdministracion}
        path="/administracion/menu"
      />
      <RutaAdministrador
        component={AdministracionMenuSocial}
        exact
        layout={LayoutAdministracion}
        path="/administracion/menu-social"
      />
      <RutaAdministrador
        component={AdministracionMenuInfo}
        exact
        layout={LayoutAdministracion}
        path="/administracion/menu-info"
      />
      <RutaAdministrador
        component={AdministracionMenuPromocion}
        exact
        layout={LayoutAdministracion}
        path="/administracion/menu-promocion"
      />
      <RutaAdministrador
        component={AdministracionGaleria}
        exact
        layout={LayoutAdministracion}
        path="/administracion/galeria"
      />
      <RutaAdministrador
        component={AdministracionSlider}
        exact
        layout={LayoutAdministracion}
        path="/administracion/slider"
      />
      <RutaAdministrador
        component={AdministracionManifiesto}
        exact
        layout={LayoutAdministracion}
        path="/administracion/manifiesto"
      />
      <RutaAdministrador
        component={AdministracionPromocion}
        exact
        layout={LayoutAdministracion}
        path="/administracion/promocion"
      />
      <RutaAdministrador
        component={AdministracionClientes}
        exact
        layout={LayoutAdministracion}
        path="/administracion/clientes"
      />
      <RutaAdministrador
        component={AdministracionMensajes}
        exact
        layout={LayoutAdministracion}
        path="/administracion/mensajes"
      />
      <RutaAdministrador
        component={AdministracionPedidos}
        exact
        layout={LayoutAdministracion}
        path="/administracion/pedidos"
      />
      {/* RUTAS CLIENTE*/}
      <RutaCliente
        component={ClientePerfil}
        exact
        layout={LayoutCliente}
        path="/cliente/perfil"
      />
      <RutaCliente
        component={ClienteGuardados}
        exact
        layout={LayoutCliente}
        path="/cliente/productos-guardados"
      />
      <RutaCliente
        component={ClienteDirecciones}
        exact
        layout={LayoutCliente}
        path="/cliente/mis-direcciones"
      />
      <RutaCliente
        component={ClienteConfirmacionPago}
        exact
        layout={LayoutCliente}
        path="/cliente/confirmacion-pago"
      />
      <RutaCliente
        component={ClienteMisCompras}
        exact
        layout={LayoutCliente}
        path="/cliente/mis-compras"
      />
      {/* RUTAS NO ENCONTRADAS*/}
      <RutaPublica
        component={GeneralNoEncontrado}
        exact
        layout={LayoutGeneral}
        path="/pagina-no-encontrada"
      />
      <Redirect to="/pagina-no-encontrada" />{" "}
    </Switch>
  );
};

export default Routes;
