import React, { useState, useContext } from "react";
import { EstadoContexto } from "../../context/EstadoGeneral";
import { Link, useHistory } from "react-router-dom";
import CalcularPantalla from "../../utils/CalcularPantalla";
import CarritoCompras from "./CarritoCompras";
import BuscarProducto from "./BuscarProducto";
import { useLiveQuery } from "dexie-react-hooks";
import { db2 } from "../../controllers/IndexDB";
import "./Menu.css";

export default function Menu({ menuPrincipal }) {
  const history = useHistory();
  const [formBusqueda, setFormBusqueda] = useState("");
  const { usuario } = useContext(EstadoContexto);
  const exiteUsuario = Object.keys(usuario).length;
  const { ancho } = CalcularPantalla();
  const [botonHamburguesa, setBotonHamburguesa] = useState(false);
  const [botonCarrito, setBotonCarrito] = useState(false);
  const [botonBuscar, setBotonBuscar] = useState(false);
  const [estadoSubMenu, setEstadoSubMenu] = useState(false);
  const [tipoCategoria, setTipoCategoria] = useState({
    urlCategoria: menuPrincipal[0].urlCategoria,
  });
  const activarSubCategoria = (e) => {
    setTipoCategoria({
      ...tipoCategoria,
      urlCategoria: e.target.dataset.categoria,
    });
    //setEstadoSubMenu(!estadoSubMenu);
    setEstadoSubMenu(true);
  };
  const activarSubCategoria2 = (e) => {
    setTipoCategoria({
      ...tipoCategoria,
      urlCategoria: e.target.dataset.categoria,
    });
    setEstadoSubMenu(false);
    setBotonHamburguesa(false);
  };
  const productosTotal = useLiveQuery(() => db2.productosIDB.count());
  const enviarBusqueda = (e) => {
    e.preventDefault();
    setFormBusqueda("");
    setBotonHamburguesa(!botonHamburguesa);
    history.push(`/tienda?producto=${formBusqueda}`);
  };

  return (
    <>
      {/**INICIO GRID MENU PRINCIPAL**/}
      <nav className="grid-menu-principal">
        {/**CONTENEDOR MENU HAMBURGUESA PARA CELULAR**/}
        <div
          className="contenedor-menu-hamburguesa"
          onClick={() => setBotonHamburguesa(!botonHamburguesa)}
        >
          <img
            src={botonHamburguesa ? "/icons/Cerrar.svg" : "/icons/Menu.svg"}
            alt="logo"
            onClick={() => setBotonHamburguesa(!botonHamburguesa)}
          />
        </div>
        {/**GRID DE MENU LINKS**/}
        <div
          className={
            botonHamburguesa && ancho <= 1000
              ? "grid-menu-links grid-menu-links-activo"
              : "grid-menu-links"
          }
        >
          {ancho <= 800 ? (
            <>
              {/**GRID DE MENU LINKS - BUSCAR**/}
              <form onSubmit={enviarBusqueda} className="formulario-buscar">
                <input
                  type="search"
                  required
                  name="formBusqueda"
                  placeholder="Buscar"
                  value={formBusqueda}
                  onChange={(event) => setFormBusqueda(event.target.value)}
                />{" "}
                <button>
                  <img src="/icons/BuscarNuevo.svg" alt="" />
                </button>
              </form>

              <div className="menu-links">
                {menuPrincipal.map(
                  (categoria) =>
                    categoria.mostrar && (
                      <div key={categoria.idCategoria}>
                        <div className="menu-links-cabecera">
                          <Link
                            data-categoria={categoria.urlCategoria}
                            to="tienda"
                            onClick={activarSubCategoria2}
                          >
                            {" "}
                            {categoria.nombreCategoria}
                          </Link>
                          {estadoSubMenu &&
                          tipoCategoria.urlCategoria ===
                            categoria.urlCategoria ? (
                            <img
                              style={{ cursor: "pointer" }}
                              data-categoria={categoria.urlCategoria}
                              onClick={activarSubCategoria2}
                              src="/icons/Arriba.svg"
                              alt=""
                            />
                          ) : (
                            <img
                              style={{ cursor: "pointer" }}
                              data-categoria={categoria.urlCategoria}
                              onClick={activarSubCategoria2}
                              src="/icons/Abajo.svg"
                              alt=""
                            />
                          )}
                        </div>

                        {estadoSubMenu && (
                          <div
                            className={
                              categoria.urlCategoria ===
                              tipoCategoria.urlCategoria
                                ? "menu-link-submenu menu-link-submenu-activo"
                                : "menu-link-submenu"
                            }
                          >
                            {categoria.subCategoria.map((item) => {
                              return (
                                <p
                                  key={item.idSubCategoria}
                                  onClick={() => {
                                    setBotonHamburguesa(!botonHamburguesa);
                                    history.push(
                                      `/tienda?categoria=${item.nombreSubCategoria}`
                                    );
                                  }}
                                >
                                  {item.nombreSubCategoria}
                                </p>
                              );
                            })}{" "}
                          </div>
                        )}
                      </div>
                    )
                )}
                <div>
                  <div className="menu-links-cabecera">
                    <Link to="about" onClick={activarSubCategoria2}>
                      {" "}
                      About
                    </Link>
                  </div>
                </div>
                <div className="menu-links-iconos">
                  <Link
                    to={
                      exiteUsuario
                        ? usuario.rol === "administrador"
                          ? "/administracion/menu"
                          : "/cliente/perfil"
                        : "/ingresar"
                    }
                    onClick={() => {
                      setBotonHamburguesa(!botonHamburguesa);
                    }}
                  >
                    {" "}
                    <img src="/icons/UsuarioNuevo.svg" alt="" />
                  </Link>
                  <Link
                    to="#"
                    onClick={() => {
                      setBotonCarrito(!botonCarrito);
                      setBotonHamburguesa(!botonHamburguesa);
                    }}
                  >
                    <img src="/icons/CocheNuevo.svg" alt="" />
                  </Link>
                  <Link
                    to="/tienda"
                    onClick={() => {
                      setBotonHamburguesa(!botonHamburguesa);
                    }}
                  >
                    {" "}
                    <img src="/icons/BolsoNuevo.svg" alt="" />
                  </Link>
                </div>
              </div>
            </>
          ) : (
            <>
              {menuPrincipal.map(
                (categoria) =>
                  categoria.mostrar && (
                    <Link
                      key={categoria.idCategoria}
                      data-categoria={categoria.urlCategoria}
                      to="tienda"
                      onMouseOver={activarSubCategoria}
                      onClick={activarSubCategoria2}
                    >
                      {" "}
                      {categoria.nombreCategoria}
                    </Link>
                  )
              )}
              <Link to="about" onClick={activarSubCategoria2}>
                About
              </Link>
            </>
          )}
        </div>
        {/**GRID DE LOGO**/}
        <div className="grid-menu-logo">
          <p style={{ letterSpacing: "6px" }}>
            <Link to="/">
              <img src="/images/logo-palma.png" alt="" />
            </Link>
          </p>
        </div>
        {/**GRID DE ICONOS**/}
        <div className="grid-menu-iconos">
          {ancho <= 800 ? (
            <></>
          ) : (
            <>
              <Link to="#" onClick={() => setBotonBuscar(!botonBuscar)}>
                <img src="/icons/BuscarNuevo.svg" alt="" />
              </Link>
              <Link to="/tienda">
                <img src="/icons/BolsoNuevo.svg" alt="" />
              </Link>
              <Link
                to={
                  exiteUsuario
                    ? usuario.rol === "administrador"
                      ? "/administracion/menu"
                      : "/cliente/perfil"
                    : "/ingresar"
                }
              >
                <img src="/icons/UsuarioNuevo.svg" alt="" />
              </Link>
              <Link
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
                to="#"
                onClick={() => setBotonCarrito(!botonCarrito)}
              >
                <img src="/icons/CocheNuevo.svg" alt="" />
                {`Cart(${productosTotal})`}
              </Link>
            </>
          )}
        </div>
      </nav>

      {estadoSubMenu &&
        menuPrincipal.length !== 0 &&
        menuPrincipal.map((categoria, index) => (
          /**GRID SUBMENU**/
          <div
            key={index}
            data-categoria={categoria.urlCategoria}
            //onMouseLeave={()=>setEstadoSubMenu(!estadoSubMenu)}
            onMouseLeave={
              ancho <= 800
                ? () => console.log("HOla")
                : () => setEstadoSubMenu(!estadoSubMenu)
            }
            className={
              categoria.urlCategoria === tipoCategoria.urlCategoria
                ? "grid-sub-menu grid-sub-menu-activo"
                : "grid-sub-menu"
            }
          >
            {/**GRID CONTENEDOR SUB MENU LINKS**/}
            <div className="grid-contenedor-sub-menu-links">
              {menuPrincipal[index].subCategoria.map((subCategoria) => (
                <div
                  className="grid-columna-sub-menu"
                  key={subCategoria.idSubCategoria}
                >
                  <p
                    onClick={() => {
                      setEstadoSubMenu(!estadoSubMenu);
                      history.push(
                        `/tienda?categoria=${subCategoria.nombreSubCategoria}`
                      );
                    }}
                  >
                    {subCategoria.nombreSubCategoria}
                  </p>
                </div>
              ))}
            </div>

            {/**GRID CONTENEDOR SUB MENU IMAGENES**/}
            <div className="grid-contenedor-sub-menu-imagenes">
              {categoria.imagenesCategoria.map((galeria, index) => (
                <div
                  key={index}
                  className="grid-contenedor-producto"
                  onClick={() => {
                    setEstadoSubMenu(!estadoSubMenu);
                    history.push(`/tienda?menu=${categoria.nombreCategoria}`);
                  }}
                >
                  <img loading="lazy" src={galeria} alt="" />
                </div>
              ))}
            </div>
          </div>
        ))}

      <CarritoCompras
        setBotonCarrito={setBotonCarrito}
        botonCarrito={botonCarrito}
      />
      <BuscarProducto
        setBotonBuscar={setBotonBuscar}
        botonBuscar={botonBuscar}
      />
    </>
  );
}
