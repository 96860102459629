import {
  collection,
  addDoc,
  doc,
  deleteDoc,
  getDocs,
} from "firebase/firestore";
import { db } from "../firebase/firebase";
const coleccion = "Mensajes";

/* CREAR UNA CATEGORIA SIN IMAGEN */
export const mensajeCrear = async (celular, astro) => {
  try {
    await addDoc(collection(db, coleccion), {
      correo: celular,
      fecha: new Date(),
    });
  } catch (e) {
    console.error("Error al agregar Mensajes ", e);
  }
};

/* ELIMINAR UNA ETIQUETA */
export const mensajeEliminar = async (idMensaje) => {
  await deleteDoc(doc(db, coleccion, idMensaje));
};

/* TRAER UN DOCUMENTO*/
export const mensajesTodas = async () => {
  const mensajeRef = collection(db, coleccion);
  const mensajesDB = await getDocs(mensajeRef);
  return mensajesDB.docs.map((doc) => {
    const id = doc.id;
    return {
      idMensaje: id,
      correo: doc.data().celular,
      fecha: doc.data().fecha
    };
  });
};
