import React, { useReducer, createContext, useEffect } from "react";
import { signOut } from "firebase/auth";
import { auth, db } from "../firebase/firebase";
import { onSnapshot, doc } from "firebase/firestore";

const estadoInicial = {
  usuario: {},
};

if (localStorage.getItem("IdToken")) {
  const usuarioData = {
    idCliente: localStorage.getItem("idCliente"),
    idToken: localStorage.getItem("idToken"),
    correo: localStorage.getItem("correo"),
    nombres: localStorage.getItem("nombres"),
    apellidos: localStorage.getItem("apellidos"),
    celular: localStorage.getItem("celular"),
    rol: localStorage.getItem("rol"),
    confirmacion: localStorage.getItem("confirmacion"),
  };
  estadoInicial.usuario = usuarioData;
} else {
  estadoInicial.usuario = {};
}

const EstadoContexto = createContext({
  usuario: {},
  cerrarSesion: () => {},
  iniciarSesion: (usuarioData) => {},
});

function estadoReductor(state, action) {
  switch (action.type) {
    case "NUEVA_SESION":
      return {
        ...state,
        usuario: Object.assign(state.usuario, action.payload),
      };
    case "CERRAR_SESION":
      return { ...state, usuario: {} };
    default:
      return state;
  }
}

function EstadoProveedor(props) {
  const [state, dispatch] = useReducer(estadoReductor, estadoInicial);

  useEffect(() => {
    if (localStorage.getItem("idToken")) {
      const clienteRef = doc(db, "Usuarios", localStorage.getItem("idCliente"));

      onSnapshot(clienteRef, (doc) => {
        if (localStorage.getItem("idToken") === doc.data().idToken) {
          const userData = {
            idCliente: doc.id,
            idToken: doc.data().idToken,
            correo: doc.data().correo,
            nombres: doc.data().nombres,
            apellidos: doc.data().apellidos,
            celular: doc.data().celular,
            rol: doc.data().rol,
            confirmacion: doc.data().confirmacion,
          };
          dispatch({
            type: "NUEVA_SESION",
            payload: userData,
          });
          localStorage.setItem("idCliente", doc.id);
          localStorage.setItem("idToken", doc.data().idToken);
          localStorage.setItem("correo", doc.data().correo);
          localStorage.setItem("nombres", doc.data().nombres);
          localStorage.setItem("apellidos", doc.data().apellidos);
          localStorage.setItem("celular", doc.data().celular);
          localStorage.setItem("rol", doc.data().rol);
          localStorage.setItem("confirmacion", doc.data().confirmacion);
          console.log("SI HAY USUARIO");
        } else {
          console.log("NO HAY USUARIO");
          cerrarSesion();
        }
      });
    }
  }, [state.usuario]);

  function iniciarSesion(usuarioData) {
    localStorage.setItem("idCliente", usuarioData.idCliente);
    localStorage.setItem("idToken", usuarioData.idToken);
    localStorage.setItem("correo", usuarioData.correo);
    localStorage.setItem("nombres", usuarioData.nombres);
    localStorage.setItem("apellidos", usuarioData.apellidos);
    localStorage.setItem("celular", usuarioData.celular);
    localStorage.setItem("rol", usuarioData.rol);
    localStorage.setItem("confirmacion", usuarioData.confirmacion);

    dispatch({
      type: "NUEVA_SESION",
      payload: usuarioData,
    });
  }

  function cerrarSesion() {
    console.log("Cerrando");
    localStorage.removeItem("idCliente");
    localStorage.removeItem("idToken");
    localStorage.removeItem("correo");
    localStorage.removeItem("nombres");
    localStorage.removeItem("apellidos");
    localStorage.removeItem("celular");
    localStorage.removeItem("rol");
    localStorage.removeItem("confirmacion");
    signOut(auth)
      .then(() => {
        dispatch({
          type: "CERRAR_SESION",
          usuario: {},
        });
      })
      .catch((error) => {
        console.log("Error cerrar sesión: ", error);
      });
  }

  return (
    <EstadoContexto.Provider
      value={{
        usuario: state.usuario,
        cerrarSesion,
        iniciarSesion,
      }}
      {...props}
    />
  );
}

export { EstadoContexto, EstadoProveedor };
