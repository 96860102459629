import React, { useState, useEffect, useContext } from "react";
import { EstadoContexto } from "../../../context/EstadoGeneral";
import { editarPerfilSoloDatos } from "../../../controllers/Perfil";
import "./Perfil.css";

const initFormCliente = {
  idCliente: "",
  nombres: "",
  apellidos: "",
  celular: "",
};

const Perfil = () => {
  const { usuario } = useContext(EstadoContexto);
  const [formCliente, setFormCliente] = useState(initFormCliente);

  useEffect(() => {
    if (Object.keys(usuario).length) {
      setFormCliente({
        idCliente: usuario.idCliente,
        nombres: usuario.nombres === undefined ? "" : usuario.nombres,
        apellidos: usuario.apellidos === undefined? "" : usuario.apellidos,
        celular: usuario.celular === undefined ? "" : usuario.celular,
      });
    }
  }, [usuario]);

  const cambiarDatos = (e) => {
    const { name, value } = e.target;
    setFormCliente({
      ...formCliente,
      [name]: value,
    });
  };

  const editarDatosPerfil = (e) => {
    e.preventDefault();
    editarPerfilSoloDatos(formCliente);
  };

  return (
    <>
      <div className="contenedor-perfil-cliente">
        <h4>My Details</h4>
        <form onSubmit={editarDatosPerfil}>
          <p>Nombres:</p>
          <input
            type="text"
            required
            name="nombres"
            placeholder="Nombres"
            value={formCliente.nombres}
            onChange={cambiarDatos}
          />
          <p>Apellidos:</p>
          <input
            type="text"
            required
            name="apellidos"
            placeholder="Apellidos"
            value={formCliente.apellidos}
            onChange={cambiarDatos}
          />
          <p>Celular:</p>
          <input
            type="text"
            required
            name="celular"
            placeholder="Celular"
            value={formCliente.celular}
            onChange={cambiarDatos}
          />
          <p>Correo:</p>
          <input
            type="email"
            disabled
            required
            name="correo"
            placeholder="Correo"
            value={usuario.correo}
          />
          <input type="submit" value="Submit" />
        </form>
      </div>
    </>
  );
};

export default Perfil;
