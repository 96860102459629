import React, { useContext } from "react";
import { Route, Redirect } from "react-router-dom";
import PropTypes from "prop-types";
import { EstadoContexto } from "../context/EstadoGeneral";

const RutaAdministrador = (props) => {
  const { layout: Layout, component: Component, location, ...rest } = props;
  const { usuario } = useContext(EstadoContexto);
  const exiteUsuario = Object.keys(usuario).length;

  const hola = "Te amo Viviana.";

  const clienteLocal = localStorage.getItem("idCliente");
  const rolLocal = localStorage.getItem("rol");
  return clienteLocal && rolLocal === "administrador" ? (
    <Route
      {...rest}
      render={(matchProps) => (
        <Layout hola={hola}>{<Component {...matchProps} />}</Layout>
      )}
    />
  ) : (
    <Redirect
      to={{
        pathname: "/ingresar",
        state: { from: location },
      }}
    />
  );
};

RutaAdministrador.propTypes = {
  component: PropTypes.any.isRequired,
  layout: PropTypes.any.isRequired,
  path: PropTypes.string,
};

export default RutaAdministrador;
/*
return exiteUsuario && usuario?.rol === "administrador" ? (
    <Route
      {...rest}
      render={(matchProps) => (
        <Layout hola={hola}>{<Component {...matchProps} />}</Layout>
      )}
    />
  ) : (
    <Redirect
      to={{
        pathname: "/ingresar",
        state: { from: location },
      }}
    />
  );
*/
