import React from "react";

const CaracteristicasMenu = ({
  categoria,
  menuPrincipal,
  setMenuPrincipal,
  index,
  abrirModalSubMenuEditar
}) => {
  const bajarSubMenu = (id, index) => {
    let subMenuPrincipal = [
      menuPrincipal[index].subCategoria[
        menuPrincipal[index].subCategoria.length - 1
      ],
      ...menuPrincipal[index].subCategoria.slice(0, -1),
    ];

    const nuevoMenuPrincipal = menuPrincipal.map((elemento) => {
      const subCategoria = [...elemento.subCategoria];
      if (elemento.idCategoria === id) {
        subCategoria.splice(0, subCategoria.length);
        subCategoria.push(...subMenuPrincipal);
      }
      return {
        ...elemento,
        subCategoria,
      };
    });
    setMenuPrincipal(nuevoMenuPrincipal);
  };

  const subirSubMenu = (id, index) => {
    let subMenuPrincipal = [
      ...menuPrincipal[index].subCategoria.slice(1),
      menuPrincipal[index].subCategoria[0],
    ];

    const nuevoMenuPrincipal = menuPrincipal.map((elemento) => {
      const subCategoria = [...elemento.subCategoria];
      if (elemento.idCategoria === id) {
        subCategoria.splice(0, subCategoria.length);
        subCategoria.push(...subMenuPrincipal);
      }
      return {
        ...elemento,
        subCategoria,
      };
    });
    setMenuPrincipal(nuevoMenuPrincipal);
  };

  const eliminarSubMenu = (id, subMenuIndex) => {
    const nuevoMenuPrincipal = menuPrincipal.map((elemento) => {
      const subCategoria = [...elemento.subCategoria];
      if (elemento.idCategoria === id) {
        subCategoria.splice(subMenuIndex, 1);
      }
      return {
        ...elemento,
        subCategoria,
      };
    });
    setMenuPrincipal(nuevoMenuPrincipal);
  };

  return categoria.subCategoria.map((subCategoria, subMenuIndex) => (
    <div key={subMenuIndex} className="contenedor-drop-sub">
      <div>
        {subCategoria.nombreSubCategoria}
        <br />
        {"/" + subCategoria.urlSubCategoria}
      </div>
      <div>
        <img
          onClick={() => subirSubMenu(categoria.idCategoria, index)}
          src="/icons/Arriba.svg"
          alt="logo"
        />{" "}
        <img
          onClick={() => bajarSubMenu(categoria.idCategoria, index)}
          src="/icons/Abajo.svg"
          alt="logo"
        />{" "}
        <img
          onClick={() =>
            abrirModalSubMenuEditar(categoria.idCategoria, subCategoria)
          }
          className="icono-editar"
          src="/icons/Editar.svg"
          alt="logo"
        />{" "}
        <img
          onClick={() => eliminarSubMenu(categoria.idCategoria, subMenuIndex)}
          className="icono-eliminar"
          src="/icons/Eliminar.svg"
          alt="logo"
        />{" "}
      </div>
    </div>
  ));
};
export default CaracteristicasMenu;
