import React from "react";
import Titulo3 from "../../../components/Titulo/Titulo3";
import "./Politicas.css";

const TiemposEntrega = () => {
  return (
    <>
      <Titulo3 titulo={"TIEMPOS DE ENTREGA"} />
      <div className="contenedor-politicas">
        <h2>DELIVERY CARACAS</h2>
        <p>
          Tenemos un plazo de entrega de 1 a 4 días hábiles, a partir del día
          posterior de realizar el pedido.
        </p>

        <p>
          Nuestros pedidos son enviados por empresas tercerizadas, quienes son
          responsables de la seguridad y tiempo de los despachos por lo que no
          tenemos horarios exactos de entrega.
        </p>

        <h2>DELIVERY INTERIOR DEL PAÍS</h2>

        <p>
          Nuestros pedidos son enviados por un courier (empresa tercerizada), el
          que mejor se adapte a la zona de entrega y quienes serán responsables
          de la seguridad y tiempo de los despachos. Tenemos un plazo de entrega
          de 2 a 7 días hábiles, a partir del día posterior a realizar el
          pedido.
        </p>

        <p style={{ fontStyle: "italic" }}>
          *En caso requieras tu pedido de forma urgente puedes escribirnos por
          instagram @thepalmastore o enviarnos un mail a
          palma.store.ve@gmail.com y haremos lo posible por ayudarte.
        </p>

        <p style={{ fontStyle: "italic" }}>
          *Cabe resaltar que pueden haber demoras ocasionadas por factores
          externos.
        </p>
      </div>
    </>
  );
};

export default TiemposEntrega;
