import React, { useState, useEffect } from "react";
import { db } from "../../../firebase/firebase";
import { doc, onSnapshot } from "firebase/firestore";
import {
  editarArrayMenuInfo,
  mostrarArrayMenuInfo,
} from "../../../controllers/MenuInfo";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import {
  MenuModalInfoCrear,
  MenuModalInfoEditar,
} from "../../../components/Modal";
import MenuNavegacion from "./MenuNavegacion";
import "./Menu.css";

const reodernarLista = (nuevaLista, posicionInicio, posicionFinal) => {
  const nuevaListaMenu = [...nuevaLista];
  const [removido] = nuevaListaMenu.splice(posicionInicio, 1);
  nuevaListaMenu.splice(posicionFinal, 0, removido);
  return nuevaListaMenu;
};

const MenuInfo = () => {
  const [menuInfo, setMenuInfo] = useState([]);
  const [estadoMenuModal, setEstadoMenuModal] = useState(false);
  const [estadoMenuModalEditar, setEstadoMenuModalEditar] = useState(false);
  const [menuSeleccionado, setMenuSeleccionado] = useState({});
  const [posicionMenu, setPosicionMenu] = useState(0);
  const [mostrar, setMostrar] = useState(false);

  useEffect(() => {
    const idMenuInfo = "CFIba5VbqoHT0EQP8DrY";
    const menuInfoRef = doc(db, "Menu", idMenuInfo);
    onSnapshot(menuInfoRef, (doc) => {
      setMenuInfo(doc.data().MenuInfo);
      setMostrar(doc.data().mostrar);
    });
  }, []);

  const eliminarMenu = (id) => {
    setMenuInfo(menuInfo.filter((elemento) => elemento.idMenuInfo !== id));
  };

  const editarMenu = (categoria, index) => {
    setPosicionMenu(index);
    setMenuSeleccionado(categoria);
    setEstadoMenuModalEditar(!estadoMenuModalEditar);
  };

  const guardarNuevoMenu = (e) => {
    e.preventDefault();
    editarArrayMenuInfo(menuInfo);
  };

  return (
    <>
      {estadoMenuModal && (
        <MenuModalInfoCrear setEstadoMenuModal={setEstadoMenuModal} />
      )}
      <MenuNavegacion />
      {menuInfo?.length === 0 ? (
        <></>
      ) : (
        <>
          {estadoMenuModalEditar && (
            <MenuModalInfoEditar
              setEstadoMenuModal={setEstadoMenuModalEditar}
              menuSeleccionado={menuSeleccionado}
              menuPrincipal={menuInfo}
              posicionMenu={posicionMenu}
            />
          )}
          <DragDropContext
            onDragEnd={(result) => {
              const { source, destination } = result;
              if (!destination) {
                return;
              }
              if (
                source.index === destination.index &&
                source.droppableId === destination.droppableId
              ) {
                return;
              }

              setMenuInfo((listaAntigua) =>
                reodernarLista(listaAntigua, source.index, destination.index)
              );
            }}
          >
            <Droppable droppableId="tasks">
              {(droppableProvided) => (
                <ul
                  {...droppableProvided.droppableProps}
                  ref={droppableProvided.innerRef}
                  className="contenedor-direcciones-drop"
                >
                  {menuInfo.map((itemInfo, index) => (
                    <Draggable
                      key={itemInfo.idMenuInfo}
                      draggableId={itemInfo.idMenuInfo}
                      index={index}
                    >
                      {(draggableProvided) => (
                        <li
                          {...draggableProvided.draggableProps}
                          ref={draggableProvided.innerRef}
                          {...draggableProvided.dragHandleProps}
                        >
                          <p>{itemInfo.nombre}</p>
                          <p>{itemInfo.url}</p>
                          <div className="contenedor-botones-drop">
                            <div className="drop-botones-drop-menu">
                              <span
                                style={{ backgroundColor: "#f2c9cd" }}
                                onClick={() => editarMenu(itemInfo, index)}
                              >
                                Editar Menu
                              </span>
                              <span
                                style={{
                                  backgroundColor: "#dadad8",
                                }}
                                onClick={() =>
                                  eliminarMenu(itemInfo.idMenuInfo)
                                }
                              >
                                Eliminar Menu
                              </span>
                            </div>
                          </div>
                        </li>
                      )}
                    </Draggable>
                  ))}
                  {droppableProvided.placeholder}
                </ul>
              )}
            </Droppable>
          </DragDropContext>

          <div className="switch-button-modal">
            <input
              type="checkbox"
              name="switch-button"
              id="switch-label"
              checked={mostrar}
              className={
                mostrar
                  ? "switch-button-checkbox switch-button-checkbox-activo"
                  : "switch-button-checkbox"
              }
              onChange={(event) =>
                mostrarArrayMenuInfo(event.currentTarget.checked)
              }
            />
            <label
              htmlFor="switch-label"
              className="switch-button-label"
            ></label>
          </div>

          <span
            className="drop-botones-guardar"
            style={{
              backgroundColor: "#555555",
              color: "white",
            }}
            onClick={guardarNuevoMenu}
          >
            Guardar Menu Info
          </span>
        </>
      )}

      <span
        className="drop-botones-agregar"
        style={{
          backgroundColor: "black",
          color: "white",
        }}
        onClick={() => setEstadoMenuModal(!estadoMenuModal)}
      >
        Agregar Nuevo Info
      </span>
    </>
  );
};

export default MenuInfo;
