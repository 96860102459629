import React from "react";
import "./Paginacion.css";

const Paginacion = ({
  botonRetroceder,
  paginaActual,
  paginas,
  numeroDePaginas,
  botonSiguiente,
}) => {
  return (
    <div className="contenedor-paginacion">
      <button
        onClick={botonRetroceder}
        disabled={paginaActual === paginas[0] ? true : false}
      >
        <img src="/icons/retroceder.svg" alt="" />{" "}
      </button>
      {numeroDePaginas}
      <button
        onClick={botonSiguiente}
        disabled={paginaActual === paginas[paginas.length - 1] ? true : false}
      >
        <img src="/icons/Siguiente.svg" alt="" />{" "}
      </button>
    </div>
  );
};
export default Paginacion;
