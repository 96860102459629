import React from "react";
import Titulo3 from "../../../components/Titulo/Titulo3";
import "./Politicas.css";

const CambiosDevoluciones = () => {
  return (
    <>
      <Titulo3 titulo={"CAMBIOS & DEVOLUCIONES"} />
      <div className="contenedor-politicas">
        <p>
          Si deseas realizar algún cambio por productos adquiridos en nuestra
          web, podrás hacerlo dentro de los siete días hábiles a partir de la
          fecha de la compra.
        </p>
        <p>
          Comunícate con nuestro equipo de atención al cliente
          palma.store.ve@gmail.com para comenzar el trámite de tu devolución al
          correo con la siguiente información:{" "}
        </p>
        <p>
          <span>
            <img src="/icons/Estrellas.svg" alt="" />
          </span>{" "}
          Número de pedido
        </p>
        <p>
          <span>
            <img src="/icons/Estrellas.svg" alt="" />
          </span>{" "}
          Número de teléfono
        </p>
        <p>
          <span>
            <img src="/icons/Estrellas.svg" alt="" />
          </span>{" "}
          Comprobante de compra
        </p>
        <p>
          <span>
            <img src="/icons/Estrellas.svg" alt="" />
          </span>{" "}
          Motivo de la devolución
        </p>
        <p style={{ fontWeight: "bold" }}>
          Tomar en cuenta las siguientes consideraciones:
        </p>
        <ul>
          <li>
            Todo cambio de producto debe realizarse presentando el comprobante
            de pago online.
          </li>
          <li>
            El producto deberá devolverse en óptimas condiciones y con todos sus
            accesorios, sin rastros de haber sido utilizado.
          </li>
          <li>
            Aquellos productos que fueron adquiridos en promoción o con
            descuento y de la sección “SALE” no están sujetos a cambios ni
            devoluciones.
          </li>
          <li>
            Si la solicitud de devolución se realiza fuera del periodo señalado
            o el artículo se ha utilizado, estropeado no podremos aceptar la
            devolución.
          </li>
          <li>
            El cobro por gastos de envío que pagó el cliente no será
            reembolsado.
          </li>
          <li>
            No se realizan devoluciones de dinero. Cambios válidos para un monto
            igual o superior a la venta (cancelando la diferencia).
          </li>
        </ul>
      </div>
    </>
  );
};

export default CambiosDevoluciones;
