import { doc, updateDoc, arrayUnion, getDoc } from "firebase/firestore";
import { getStorage, ref, uploadBytes, getDownloadURL } from "firebase/storage";
import { db } from "../firebase/firebase";
const coleccion = "Menu";
const rutaFoto = "menu-imagenes";
const idMenuPrincipal = "UiD9tShW9IWTuu5qst0P";

export const agregarArrayMenuPrincipal = async (
  datosNuevoMenu,
  linkImagenes
) => {
  const menuPrincipalRef = doc(db, coleccion, idMenuPrincipal);

  const nuevoMenu = {
    idCategoria: "00" + Date.now(),
    mostrar: true,
    nombreCategoria: datosNuevoMenu.nombreCategoria,
    urlCategoria: datosNuevoMenu.urlCategoria,
    imagenesCategoria: linkImagenes,
    subCategoria: [
      {
        idSubCategoria: "00" + Date.now(),
        nombreSubCategoria: datosNuevoMenu.nombreSubCategoria,
        urlSubCategoria: datosNuevoMenu.urlSubCategoria,
      },
    ],
  };

  await updateDoc(menuPrincipalRef, {
    MenuPrincipal: arrayUnion(nuevoMenu),
  });
};

export const crearMenuPrincipalCF = (datosNuevoMenu, fotosVista) => {
  const promises = fotosVista.map((file) => {
    const fechaAhora = Date.now();
    const rutaCompleta = file.name + fechaAhora + file.lastModified + file.size;
    const storage = getStorage();
    const imageRef = ref(storage, `${rutaFoto}/${rutaCompleta}`);
    return uploadBytes(imageRef, file)
      .then((snapshot) => {
        return getDownloadURL(snapshot.ref);
      })
      .catch((error) => {
        console.error("Error al subir imagenes", error);
      });
  });
  Promise.all(promises)
    .then((linkImagenes) => {
      agregarArrayMenuPrincipal(datosNuevoMenu, linkImagenes);
    })
    .catch(() => {
      return "Hubo un error";
    });
};
export const mostrarArrayMenuPrincipal = async (mostrar) => {
  const etiquetaRef = doc(db, coleccion, idMenuPrincipal);
  await updateDoc(etiquetaRef, {
    mostrar: mostrar,
  });
};

export const editarArrayMenuPrincipal = async (menuActualizado) => {
  const etiquetaRef = doc(db, coleccion, idMenuPrincipal);
  await updateDoc(etiquetaRef, {
    MenuPrincipal: menuActualizado,
  });
};

export const editarMenuPrincipalCF = (
  menuPrincipal,
  formMenu,
  posicionMenu,
  fotosAntiguas,
  fotosVista
) => {
  const promises = fotosVista.map((file) => {
    const fechaAhora = Date.now();
    const rutaCompleta = file.name + fechaAhora + file.lastModified + file.size;
    const storage = getStorage();
    const imageRef = ref(storage, `${rutaFoto}/${rutaCompleta}`);
    return uploadBytes(imageRef, file)
      .then((snapshot) => {
        return getDownloadURL(snapshot.ref);
      })
      .catch((error) => {
        console.error("Error al subir imagenes", error);
      });
  });
  Promise.all(promises)
    .then((linkImagenes) => {
      const fotosSubir =
        fotosAntiguas.length === 0
          ? linkImagenes
          : fotosAntiguas.concat(linkImagenes);

      const cambiandoImagenes = formMenu;
      cambiandoImagenes.imagenesCategoria = fotosSubir;
      const copiaMenuPrincipal = [...menuPrincipal];
      copiaMenuPrincipal[posicionMenu] = formMenu;
      editarArrayMenuPrincipal(copiaMenuPrincipal);
    })
    .catch(() => {
      return "Hubo un error";
    });
};

export const traerMenuPrincipal = async () => {
  const menuPrincipalRef = doc(db, coleccion, idMenuPrincipal);
  const docMenuPrincipal = await getDoc(menuPrincipalRef);
  if (docMenuPrincipal.exists()) {
    return docMenuPrincipal.data().MenuPrincipal;
  } else {
    console.log("No existe el documento");
  }
};
