import React from "react";
import { useHistory } from "react-router-dom";
import { useLiveQuery } from "dexie-react-hooks";
import { db2 } from "../../controllers/IndexDB";
import "./CarritoCompras.css";

const CarritoCompras = ({ setBotonCarrito, botonCarrito }) => {
  const history = useHistory();

  const productos = useLiveQuery(() => db2.productosIDB.toArray());
  const productosTotal = useLiveQuery(() => db2.productosIDB.count());
  const totalAPagar = () =>
    productos?.reduce(
      (antes, actual) => parseFloat(actual.precio) * actual.cantidad + antes,
      0
    );
  return (
    <>
      {!productos || productosTotal === undefined ? (
        <h5>No hay productos</h5>
      ) : (
        <>
          <div
            className={
              botonCarrito
                ? "contenedor-carrito contenedor-carrito-activo"
                : "contenedor-carrito"
            }
          >
            <div className="carrito-cabecera">
              <span>{productosTotal} items in your cart</span>
              <img
                style={{ cursor: "pointer" }}
                src={"/icons/Cerrar.svg"}
                alt="logo"
                onClick={() => setBotonCarrito(!botonCarrito)}
              />
            </div>
            <div className="carrito-contenido">
              <div>
                {productos.map((producto, index) => (
                  <div key={index} className="carrito-productos">
                    <div className="carrito-producto-item">
                      <div className="carrito-item-imagen">
                        <img src={producto.imagenes[0]} alt="" />
                      </div>
                    </div>
                    <div className="carrito-producto-descripcion">
                      <div className="carrito-contenedor-info">
                        <div className="carrito-descripcion-info">
                          <p style={{ fontSize: "16px" }}>{producto.nombre}</p>
                          <p>
                            <span>Marca:</span> {producto.marca}
                          </p>
                          <p>
                            <span>Descripcion:</span>
                            {producto.caracteristicas
                              .join()
                              .substr(0, 40)}...{" "}
                          </p>
                          <p>
                            <span>Cantidad:</span> {producto.cantidad}
                          </p>
                          {producto.talla && (
                            <p>
                              <span>Talla:</span> {producto.talla}
                            </p>
                          )}
                          {producto.color && (
                            <p>
                              <span>Color:</span>{" "}
                              <span
                                style={{
                                  padding: "4px 12px",
                                  backgroundColor: `#${producto.color}`,
                                  borderRadius: "50px",
                                  marginLeft: "3px",
                                }}
                              ></span>
                            </p>
                          )}
                        </div>
                        <p>USD {producto.precio}</p>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
              <div className="carrito-detalles">
                {/*<div className="carrito-detalles-contenido">
                  <p>Order Value</p>
                  <span>USD245.00</span>
                </div>
                <div className="carrito-detalles-contenido">
                  <p>Delivery</p>
                  <span>Free</span>
                </div>*/}{" "}
                <div className="carrito-detalles-contenido">
                  <p>TOTAL</p>
                  <span>USD {totalAPagar()} </span>
                </div>
                <div className="carrito-detalles-botones">
                  <p
                    onClick={() => {
                      history.push(`/checkout`);
                      setBotonCarrito(!botonCarrito);
                    }}
                    className="carrito-detalles-botones-comprar"
                  >
                    Checkout
                  </p>
                  <p
                    onClick={() => {
                      history.push(`/tienda`);
                      setBotonCarrito(!botonCarrito);
                    }}
                    className="carrito-detalles-botones-seguir"
                  >
                    Ir a tienda
                  </p>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
};
export default CarritoCompras;
