import React, { useState, useEffect } from "react";
import { db } from "../../../firebase/firebase";
import { collection, onSnapshot, query, orderBy } from "firebase/firestore";
import { productoEliminar } from "../../../controllers/Productos";
import {
  ProductosCrearModal,
  ProductosEditarModal,
} from "../../../components/Modal";
import { TablaProductos } from "../../../components/Tablas";
//import "./Productos.css";

const Productos = () => {
  const [estadoModal, setEstadoModal] = useState(false);
  const [estadoModalEditar, setEstadoModalEditar] = useState(false);
  const [productos, setProductos] = useState([]);
  const [productoSeleccionado, setProductoSeleccionado] = useState({});

  useEffect(() => {
    const productosRef = collection(db, "Productos");
    const queryProductos = query(
      productosRef,
      orderBy("fechaCreacion", "desc")
    );

    onSnapshot(queryProductos, (snapshot) => {
      setProductos(
        snapshot.docs.map((doc) => ({
          idProducto: doc.id,
          ...doc.data(),
        }))
      );
    });
  }, []);

  const habilitarEditar = (productoItem) => {
    setProductoSeleccionado(productoItem);
    setEstadoModalEditar(!estadoModalEditar);
  };

  return (
    <>
      {estadoModal && <ProductosCrearModal setEstadoModal={setEstadoModal} />}
      {estadoModalEditar && (
        <ProductosEditarModal
          setEstadoModalEditar={setEstadoModalEditar}
          productoSeleccionado={productoSeleccionado}
        />
      )}
      <h4 className="titulo-panel-administracion">Productos</h4>

      <button
        onClick={() => setEstadoModal(!estadoModal)}
        className="boton-administracion"
      >
        Agregar Producto
      </button>
      {productos?.length === 0 ? (
        <></>
      ) : (
        <>
          <TablaProductos
            productos={productos}
            habilitarEditar={habilitarEditar}
            productoEliminar={productoEliminar}
          />
        </>
      )}
    </>
  );
};

export default Productos;
