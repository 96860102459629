import React, { useState } from "react";
import { useHistory } from "react-router-dom";

import "./BuscarProducto.css";

const BuscarProducto = ({ botonBuscar, setBotonBuscar }) => {
  const history = useHistory();

  const [formBusqueda, setFormBusqueda] = useState("");

  const enviarBusqueda = (e) => {
    e.preventDefault();
    setBotonBuscar(!botonBuscar);
    setFormBusqueda("");
    history.push(`/tienda?producto=${formBusqueda}`);
  };

  return (
    <div
      className={
        botonBuscar
          ? "contenedor-busqueda"
          : "contenedor-busqueda contenedor-busqueda-activo"
      }
    >
      <p>What are you looking for?</p>
      <form onSubmit={enviarBusqueda}>
        <input
          type="search"
          required
          name="formBusqueda"
          placeholder="Buscar"
          value={formBusqueda}
          onChange={(event) => setFormBusqueda(event.target.value)}
        />
        <button>
          <img src="/icons/Buscar.svg" alt="" />
        </button>
      </form>
    </div>
  );
};
export default BuscarProducto;
