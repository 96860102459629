import React, { useState, useEffect } from "react";
import { db } from "../../../firebase/firebase";
import { doc, onSnapshot } from "firebase/firestore";
import { Link } from "react-router-dom";

import { actualizarWhatsAppProducto } from "../../../controllers/WhatsApp";

const initFormWhatsApp = {
  celular: "",
  mensaje: "",
};

const WhatsAppProducto = () => {
  const [formWhatsApp, setFormWhatsApp] = useState({});

  useEffect(() => {
    const whatsAppRef = doc(db, "WhatsApp", "TAdNCM1ectJZj9HqaHEj");
    onSnapshot(whatsAppRef, (doc) => {
      setFormWhatsApp({
        whatsAppId: doc.id,
        celular: doc.data().celular,
        mensaje: doc.data().mensaje,
      });
    });
  }, []);

  const cambiarDatos = (e) => {
    const { name, value } = e.target;
    setFormWhatsApp({
      ...formWhatsApp,
      [name]: value,
    });
  };

  const guardarWhatsApp = (e) => {
    e.preventDefault();
    actualizarWhatsAppProducto(formWhatsApp);
    setFormWhatsApp(initFormWhatsApp);
  };

  return (
    <>
      <div className="contenedor-perfil-cliente">
        <h4>
          <Link
            to="/administracion/whatsapp-carrito"
            style={{ textDecoration: "underline" }}
          >
            Whatsapp Carrito
          </Link>{" "}
          <span> / </span>{" "}
          <Link
            to="/administracion/whatsapp-producto"
            style={{ textDecoration: "underline" }}
          >
            Whatsapp Producto
          </Link>
        </h4>
        <form onSubmit={guardarWhatsApp}>
          <p>Numero de WhatsApp con código:</p>
          <input
            type="text"
            required
            name="celular"
            placeholder="Numero de WhatsApp con código"
            value={formWhatsApp.celular}
            onChange={cambiarDatos}
          />
          <p>Mensaje en Producto solo:</p>
          <input
            type="text"
            required
            name="mensaje"
            placeholder="Mensaje en Producto solo"
            value={formWhatsApp.mensaje}
            onChange={cambiarDatos}
          />

          <input type="submit" value="Editar WhatsApp" />
        </form>
      </div>
    </>
  );
};

export default WhatsAppProducto;
