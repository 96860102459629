import React, { useState, useEffect, useContext } from "react";
import { db } from "../../../firebase/firebase";
import { doc, onSnapshot } from "firebase/firestore";
import { editarArrayDireccion } from "../../../controllers/Direcciones";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import {
  DireccionModalCrear,
  DireccionModalEditar,
} from "../../../components/Modal";
import { EstadoContexto } from "../../../context/EstadoGeneral";

//import "./Direcciones.css";

const reodernarLista = (nuevaLista, posicionInicio, posicionFinal) => {
  const nuevaListaMenu = [...nuevaLista];
  const [removido] = nuevaListaMenu.splice(posicionInicio, 1);
  nuevaListaMenu.splice(posicionFinal, 0, removido);
  return nuevaListaMenu;
};

const Direcciones = () => {
  const { usuario } = useContext(EstadoContexto);
  const [direcciones, setDirecciones] = useState([]);
  const [estadoMenuModal, setEstadoMenuModal] = useState(false);
  const [estadoMenuModalEditar, setEstadoMenuModalEditar] = useState(false);
  const [menuSeleccionado, setMenuSeleccionado] = useState({});
  const [posicionMenu, setPosicionMenu] = useState(0);

  const eliminarMenu = (id) => {
    setDirecciones(
      direcciones.filter((elemento) => elemento.idDireccion !== id)
    );
  };

  const editarMenu = (categoria, index) => {
    setPosicionMenu(index);
    setMenuSeleccionado(categoria);
    setEstadoMenuModalEditar(!estadoMenuModalEditar);
  };

  useEffect(() => {
    const idDireccion = "s3IcWRkZIO6uvk9S0JSz";
    const rrutaDireccion = `${"Usuarios"}/${
      usuario.idCliente
    }/${"Direcciones"}`;
    const direccionesRef = doc(db, rrutaDireccion, idDireccion);
    onSnapshot(direccionesRef, (doc) => {
      if (doc.data()) {
        setDirecciones(doc.data().Direcciones);
      }
    });
  }, [usuario.idCliente]);

  const guardarNuevoMenu = (e) => {
    e.preventDefault();
    if (direcciones?.length !== 0) {
      editarArrayDireccion(direcciones, usuario.idCliente);
    }
  };

  return (
    <>
      {estadoMenuModal && (
        <DireccionModalCrear
          setEstadoMenuModal={setEstadoMenuModal}
          idCliente={usuario.idCliente}
          tamanio={direcciones?.length}
        />
      )}
      {direcciones?.length === 0 ? (
        <h5>No hay menu</h5>
      ) : (
        <>
          {estadoMenuModalEditar && (
            <DireccionModalEditar
              idCliente={usuario.idCliente}
              setEstadoMenuModal={setEstadoMenuModalEditar}
              menuSeleccionado={menuSeleccionado}
              menuPrincipal={direcciones}
              posicionMenu={posicionMenu}
            />
          )}
          <DragDropContext
            onDragEnd={(result) => {
              const { source, destination } = result;
              if (!destination) {
                return;
              }
              if (
                source.index === destination.index &&
                source.droppableId === destination.droppableId
              ) {
                return;
              }

              setDirecciones((listaAntigua) =>
                reodernarLista(listaAntigua, source.index, destination.index)
              );
            }}
          >
            <div className="app">
              <h1>Direcciones</h1>
              <Droppable droppableId="tasks">
                {(droppableProvided) => (
                  <ul
                    {...droppableProvided.droppableProps}
                    ref={droppableProvided.innerRef}
                    className="contenedor-direcciones-drop"
                  >
                    {direcciones.map((itemDireccion, index) => (
                      <Draggable
                        key={itemDireccion.idDireccion}
                        draggableId={itemDireccion.idDireccion}
                        index={index}
                      >
                        {(draggableProvided) => (
                          <li
                            {...draggableProvided.draggableProps}
                            ref={draggableProvided.innerRef}
                            {...draggableProvided.dragHandleProps}
                            className="item-drop-direccion"
                          >
                            <p>{itemDireccion.estado}</p>
                            <p>{itemDireccion.municipio}</p>
                            <p>{itemDireccion.parroquia}</p>
                            <p>{itemDireccion.direccion}</p>
                            <p>{itemDireccion.recomendacion}</p>

                            <div className="drop-control-direccion">
                              <button
                                className="drop-botones-comprar"
                                onClick={() => editarMenu(itemDireccion, index)}
                              >
                                Editar Menu
                              </button>
                              <button
                                className="drop-botones-seguir"
                                onClick={() =>
                                  eliminarMenu(itemDireccion.idDireccion)
                                }
                              >
                                Eliminar Menu
                              </button>
                            </div>
                          </li>
                        )}
                      </Draggable>
                    ))}
                    {droppableProvided.placeholder}
                  </ul>
                )}
              </Droppable>
            </div>
          </DragDropContext>
          <button className="drop-botones-guardar" onClick={guardarNuevoMenu}>
            Guardar Menu
          </button>
        </>
      )}

      <button
        className="drop-botones-agregar"
        onClick={() => setEstadoMenuModal(!estadoMenuModal)}
      >
        Agregar Nuevo Menu
      </button>
    </>
  );
};

export default Direcciones;
