import React, { useState, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import {
  verificarCuentaCorreo,
  actualizarCuentaContrasena,
} from "../../../controllers/Sesion";
import Notificacion from "../../../components/Notificacion/Notificacion";
import Titulo2 from "../../../components/Titulo/Titulo2";
import "./Ingresar.css";

const Verificar = (props) => {
  const [confirmarCorreo, setConfirmarCorreo] = useState("esperando");
  const [correoCliente, setCorreoCliente] = useState("");
  const [formContrasena, setFormContrasena] = useState("");
  const [estadoNotificacion, setEstadoNotificacion] = useState(false);
  const [mensajeNotificacion, setMensajeEstadoNotificacion] = useState(null);

  const cambiarDatosFormVerificar = (e) => {
    const value = e.target.value;
    setFormContrasena(value);
  };

  const useQuery = () => {
    return new URLSearchParams(useLocation().search);
  };
  let query = useQuery();
  let codigoVerificacion = query.get("oobCode");
  let modoVerificacion = query.get("mode");

  useEffect(() => {
    if (codigoVerificacion !== null && modoVerificacion === "verifyEmail") {
      verificarCuentaCorreo(codigoVerificacion).then((res) => {
        if (res === "error") {
          setConfirmarCorreo("error");
        } else if (res === "expirado") {
          setConfirmarCorreo("expirado");
        } else {
          setConfirmarCorreo("correcto");
          setCorreoCliente(res);
        }
      });
    }
  }, [codigoVerificacion, modoVerificacion]);

  const verificarContrasenaCliente = (e) => {
    e.preventDefault();
    actualizarCuentaContrasena(codigoVerificacion, formContrasena).then(
      (res) => {
        if (res === "cambiado") {
          setEstadoNotificacion(true);
          setMensajeEstadoNotificacion("Contraseña cambiada.");
          setTimeout(() => {
            props.history.push("/ingresar");
          }, 2000);
        } else if (res === "no") {
          setEstadoNotificacion(true);
          setMensajeEstadoNotificacion("No se puedo cambiar la contraseña.");
        } else {
          setEstadoNotificacion(true);
          setMensajeEstadoNotificacion("Hubo un error.");
        }
      }
    );
  };

  return (
    <div className="contenedor-ingresar-fondo">
      {" "}
      <div className="contenedor-ingresar">
        {modoVerificacion === "verifyEmail" && (
          <>
            <Titulo2 titulo={"VERIFICANDO CUENTA DE PALMA"} />

            {confirmarCorreo === "esperando2" && <h3>Procesando....</h3>}
            {confirmarCorreo === "error" && <h3>Error en la confirmación</h3>}
            {confirmarCorreo === "expirado" && (
              <h3>Codigo de verificación expirado</h3>
            )}
            {confirmarCorreo === "correcto" && (
              <>
                <h3>Correo: {correoCliente} verificado exitosamente</h3>
                <form>
                  <p>Ya puede iniciar sesión</p>
                  <span className="boton-span">
                    <Link to="/ingresar">Ingresar</Link>
                  </span>
                </form>
              </>
            )}
          </>
        )}
        {modoVerificacion === "resetPassword" && (
          <>
            {estadoNotificacion && (
              <Notificacion
                mensajeNotificacion={mensajeNotificacion}
                setEstadoNotificacion={setEstadoNotificacion}
                setMensajeEstadoNotificacion={setMensajeEstadoNotificacion}
              />
            )}
            <Titulo2 titulo={"RECUPERANDO CUENTA DE PALMA"} />

            <div className="contenedor-ingresar">
              <h3>
                <span>ESCRIBE NUEVA CONTRASEÑA</span>
              </h3>{" "}
              <form onSubmit={verificarContrasenaCliente}>
                <input
                  type="password"
                  required
                  name="contrasena"
                  placeholder="Contraseña"
                  value={formContrasena}
                  onChange={cambiarDatosFormVerificar}
                />{" "}
                <input type="submit" value="CAMBIAR" />
                <p>
                  <Link to="/ingresar">Ingresar</Link>
                </p>
              </form>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default Verificar;
