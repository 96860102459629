import React, { useState } from "react";
import { agregarArrayMenuInfo } from "../../../controllers/MenuInfo";
import "./../Modal.css";

const initFormMenuInfo = {
  nombre: "",
  url: "",
};

const MenuModalInfoCrear = ({ setEstadoMenuModal }) => {
  const [formMenuInfo, setFormMenuInfo] = useState(initFormMenuInfo);

  const cambiarDatosDelMenuInfo = (e) => {
    const { name, value } = e.target;
    setFormMenuInfo({
      ...formMenuInfo,
      [name]: value,
    });
  };

  const agregarNuevoMenuInfo = (e) => {
    e.preventDefault();
    agregarArrayMenuInfo(formMenuInfo);
    setFormMenuInfo(initFormMenuInfo);
    setEstadoMenuModal(false);
  };

  return (
    <div className="contenedor-padre-modal">
      <div className="contenedor-modal">
        <div className="modal-cerrar">
          {" "}
          <button onClick={() => setEstadoMenuModal(false)}>
            <img src="/icons/Cerrar.svg" alt="" />
          </button>
        </div>

        <form onSubmit={agregarNuevoMenuInfo}>
          <p>Nombre Menu:</p>
          <input
            type="text"
            required
            name="nombre"
            placeholder="Nombre Menu"
            value={formMenuInfo.nombre}
            onChange={cambiarDatosDelMenuInfo}
          />
          <p>URL Menu:</p>
          <input
            type="text"
            required
            name="url"
            placeholder="URL Menu"
            value={formMenuInfo.url}
            onChange={cambiarDatosDelMenuInfo}
          />
          <input type="submit" value="Guardar Menu" />
        </form>

      </div>
    </div>
  );
};
export default MenuModalInfoCrear;
