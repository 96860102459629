import { doc, updateDoc } from "firebase/firestore";
import { db } from "../firebase/firebase";
const coleccion = "Usuarios";

export const editarPerfilSoloDatos = async (formCliente) => {
  const perfilRef = doc(db, coleccion, formCliente.idCliente);

  await updateDoc(perfilRef, {
    nombres: formCliente.nombres,
    apellidos: formCliente.apellidos,
    celular: formCliente.celular,
  });
};
