import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { todosProductos } from "../../../controllers/Productos";
import { categoriasTodas } from "../../../controllers/Categorias";
import { etiquetasTodas } from "../../../controllers/Etiquetas";
import ProductosPaginacion from "../../../components/ProductosSimilares/ProductosPaginacion";
import { traerWhatsAppProducto } from "../../../controllers/WhatsApp";
import Titulo from "../../../components/Titulo/Titulo";
import Controles from "./Controles";
import Instagram from "../../../components/Instagram/Instagram";
import "./Tienda.css";

const Tienda = () => {
  const [productos, setProductos] = useState([]);
  const [productosFiltrados, setProductosFiltrados] = useState([]);
  const [categorias, setCategorias] = useState([]);
  const [categoriaSelect, setCategoriaSelec] = useState("");
  const [etiquetas, setEtiquetas] = useState([]);
  const [etiquetaSelect, setEtiquetaSelect] = useState("");
  const [whatsAppProducto, setWhatsAppProducto] = useState({});
  const useQuery = () => {
    return new URLSearchParams(useLocation().search);
  };
  let query = useQuery();
  let productoBusqueda = query.get("producto");
  let menuBusqueda = query.get("menu");
  let categoriaBusqueda = query.get("categoria");

  useEffect(() => {
    (async () => {
      const productosDB = await todosProductos();
      setProductos(productosDB);
      setProductosFiltrados(productosDB);
      const categoriasDB = await categoriasTodas();
      setCategorias(categoriasDB);
      const etiquetasDB = await etiquetasTodas();
      setEtiquetas(etiquetasDB);
      const whatsAppProductoDoc = await traerWhatsAppProducto();
      setWhatsAppProducto(whatsAppProductoDoc);
    })();
  }, []);

  useEffect(() => {
    if (productoBusqueda) {
      const busquedaFiltrado = productos.filter((producto) => {
        const busqueda = producto.nombre.search(productoBusqueda);
        return busqueda > -1;
      });
      if (busquedaFiltrado.length !== 0) {
        setProductosFiltrados(busquedaFiltrado);
      } else {
        console.log("NO hay producto");
      }
    } else if (menuBusqueda) {
      const busquedaFiltrado = productos.filter((producto) => {
        const busqueda = producto.menuPrincipal.search(menuBusqueda);
        return busqueda > -1;
      });
      if (busquedaFiltrado.length !== 0) {
        setProductosFiltrados(busquedaFiltrado);
      } else {
        console.log("NO hay producto");
      }
    } else if (categoriaBusqueda) {
      const busquedaFiltrado = productos.filter((producto) => {
        const busqueda = producto.categoria.search(categoriaBusqueda);
        return busqueda > -1;
      });
      if (busquedaFiltrado.length !== 0) {
        setProductosFiltrados(busquedaFiltrado);
      } else {
        console.log("NO hay producto");
      }
    }
  }, [productoBusqueda, menuBusqueda, categoriaBusqueda, productos]);

  const filtrarCategoria = (e) => {
    const categoriaNombre = e.target.value;
    if (categoriaNombre !== categoriaSelect) {
      const categoriaFiltrado = productosFiltrados.filter(
        (producto) => producto.categoria === categoriaNombre
      );
      if (categoriaFiltrado.length !== 0) {
        setProductosFiltrados(categoriaFiltrado);
        setCategoriaSelec(categoriaNombre);
      } else {
        const categoriaFiltrado = productos.filter(
          (producto) => producto.categoria === categoriaNombre
        );
        if (categoriaFiltrado.length !== 0) {
          setProductosFiltrados(categoriaFiltrado);
          setCategoriaSelec(categoriaNombre);
        }
      }
    }
  };

  const filtrarEtiqueta = (e) => {
    const etiquetaNombre = e.target.value;
    if (etiquetaNombre !== etiquetaSelect) {
      const etiquetaFiltrado = productosFiltrados.filter((producto) => {
        const arrayEtiqueta = producto.etiqueta;
        var result = arrayEtiqueta.filter((el) => el === etiquetaNombre);
        return result.length > 0;
      });

      if (etiquetaFiltrado.length !== 0) {
        setProductosFiltrados(etiquetaFiltrado);
        setEtiquetaSelect(etiquetaNombre);
      } else {
        const etiquetaFiltrado = productos.filter((producto) => {
          const arrayEtiqueta = producto.etiqueta;
          var result = arrayEtiqueta.filter((el) => el === etiquetaNombre);
          return result.length > 0;
        });

        if (etiquetaFiltrado.length !== 0) {
          setProductosFiltrados(etiquetaFiltrado);
          setEtiquetaSelect(etiquetaNombre);
        }
      }
    }
  };

  const borrarFiltros = () => {
    setProductosFiltrados(productos);
    setCategoriaSelec("");
    setEtiquetaSelect("");
  };

  return (
    <>
      {productos?.length === 0 ? (
        <></>
      ) : (
        <div className="contenedor-tienda">
          <Titulo titulo={"TIENDA"} />
          {/*<Controles
            categorias={categorias}
            categoriaSelect={categoriaSelect}
            filtrarCategoria={filtrarCategoria}
            etiquetas={etiquetas}
            etiquetaSelect={etiquetaSelect}
            filtrarEtiqueta={filtrarEtiqueta}
            borrarFiltros={borrarFiltros}
          />*/}
          <ProductosPaginacion productos={productosFiltrados} />
        </div>
      )}
      <Instagram />
    </>
  );
};

export default Tienda;
