import React, { useState, useEffect, useContext } from "react";
import { Link } from "react-router-dom";
import { EstadoContexto } from "../../../context/EstadoGeneral";
import Notificacion from "../../../components/Notificacion/Notificacion";
import {
  ingresarClienteAuth,
  traerUnCliente,
} from "../../../controllers/Sesion";
import Titulo2 from "../../../components/Titulo/Titulo2";
import "./Ingresar.css";

const initFormIngresar = {
  correo: "",
  contrasena: "",
};
const Ingresar = (props) => {
  const { iniciarSesion } = useContext(EstadoContexto);
  const [formIngresar, setFormIngresar] = useState(initFormIngresar);
  const [usuarioVerificado, setUsuarioVerificado] = useState(null);

  const cambiarDatosFormIngresar = (e) => {
    const { name, value } = e.target;
    setFormIngresar({
      ...formIngresar,
      [name]: value,
    });
  };
  const [estadoNotificacion, setEstadoNotificacion] = useState(false);
  const [mensajeNotificacion, setMensajeEstadoNotificacion] = useState(null);

  useEffect(() => {
    if (usuarioVerificado) {
      (async () => {
        const usuarioData = await traerUnCliente(usuarioVerificado);
        iniciarSesion(usuarioData);
        setTimeout(() => {
          props.history.push(
            `${
              usuarioData?.rol === "administrador"
                ? "/administracion/menu"
                : "/cliente/perfil"
            }`
          );
        }, 2000);
      })();
    }
    setUsuarioVerificado(null);
  }, [usuarioVerificado, iniciarSesion, props.history]);

  const ingresarCliente = (e) => {
    e.preventDefault();
    ingresarClienteAuth(formIngresar).then((res) => {
      if (res.idUsuario) {
        setUsuarioVerificado(res);
      } else if (res === "noVerificado") {
        setEstadoNotificacion(true);
        setMensajeEstadoNotificacion("Primero verifica tu correo");
      } else if (res === "contrasenaIncorrecta") {
        setEstadoNotificacion(true);
        setMensajeEstadoNotificacion("Contraseña Incorrecta");
      } else {
        setEstadoNotificacion(true);
        setMensajeEstadoNotificacion("Esta cuenta no existe, crea tu cuenta.");
      }
      setFormIngresar(initFormIngresar);
    });
  };
  return (
    <div className="contenedor-ingresar-fondo">
      <Titulo2 titulo={"INGRESA A TU CUENTA DE PALMA"} />

      {estadoNotificacion && (
        <Notificacion
          mensajeNotificacion={mensajeNotificacion}
          setEstadoNotificacion={setEstadoNotificacion}
          setMensajeEstadoNotificacion={setMensajeEstadoNotificacion}
        />
      )}
      <div className="contenedor-ingresar">
        <h3>
          <span>LOGIN</span>
        </h3>

        <form onSubmit={ingresarCliente}>
          <input
            type="email"
            required
            name="correo"
            placeholder="Email"
            value={formIngresar.correo}
            onChange={cambiarDatosFormIngresar}
          />{" "}
          <input
            type="password"
            required
            name="contrasena"
            placeholder="Password"
            value={formIngresar.contrasena}
            onChange={cambiarDatosFormIngresar}
          />
          <input type="submit" value="INGRESAR" />
          <span className="contenedor-clave">
            <Link to="/recuperar">¿Olvidáste tu contraseña?</Link>
          </span>
          <p>
            <Link to="/registrar">Crear cuenta</Link>
          </p>
          <br />
        </form>
      </div>
    </div>
  );
};

export default Ingresar;
