import React, { useState, useEffect, useRef } from "react";
import { traerUnSlider } from "../../controllers/Slider";
import "./Slider.css";

const delay = 18500;

const Slider = () => {
  const [sliders, setSliders] = useState([]);

  useEffect(() => {
    (async () => {
      const slidersDB = await traerUnSlider();
      setSliders(slidersDB.SliderPrincipal);
    })();
  }, []);

  const [indexSlider, setIndexSlider] = useState(0);
  const tiempoRef = useRef(null);

  function resetSlider() {
    if (tiempoRef.current) {
      clearTimeout(tiempoRef.current);
    }
  }
  useEffect(() => {
    resetSlider();
    tiempoRef.current = setTimeout(
      () =>
        setIndexSlider((prevIndex) =>
          prevIndex === sliders.length - 1 ? 0 : prevIndex + 1
        ),
      delay
    );

    return () => {
      resetSlider();
    };
  }, [indexSlider, sliders.length]);

  return (
    <div className="contenedor-slider">
      <div
        className="contenedor-imagenes"
        style={{ transform: `translate3d(${-indexSlider * 100}%, 0, 0)` }}
      >
        {sliders.map((slide, index) => (
          <div className="slide-imagen" key={index}>
            <img src={slide.imagen} alt="" />
          </div>
        ))}
      </div>

      {sliders.length > 1 && (
        <div className="contenedor-slide-controles">
          {sliders.map((_, indexSlide) => (
            <div
              key={indexSlide}
              className={`boton-slider${
                indexSlide === indexSlider ? " activo" : ""
              }`}
              onClick={() => {
                setIndexSlider(indexSlide);
              }}
            ></div>
          ))}
        </div>
      )}
    </div>
  );
};

export default Slider;
