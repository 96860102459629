import React from "react";
import EnviarMensaje from "./EnviarMensaje";
import PiePoliticas from "./PiePoliticas";
import PiePagina from "./PiePagina";
import PieMenu from "./PieMenu";
import "./Pie.css";

export default function Pie({
  menuInfo,
  mostrarMenuInfo,
  menuSocial,
  mostrarMenuSocial,
}) {
  return (
    <>
      <div className="contenedor-pie">
        <div className="pie-logo">
          <img src="/images/logo-palma-pie.png" alt="" />
        </div>

        <PieMenu
          menuSocial={menuSocial}
          mostrarMenuSocial={mostrarMenuSocial}
        />
        <PiePoliticas menuInfo={menuInfo} mostrarMenuInfo={mostrarMenuInfo} />
        <EnviarMensaje />
      </div>
      <PiePagina />
    </>
  );
}
