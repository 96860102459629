import React, { useState } from "react";
import { editarArrayPromocion } from "../../../controllers/Promocion";
import "./../Modal.css";
const initFormMenuSocial = ""

const PromocionModalEditar = ({
  setEstadoMenuModal,
  menuSeleccionado,
  menuPrincipal,
  posicionMenu,
}) => {
  const [formMenuSocial, setFormMenuSocial] = useState(menuSeleccionado);
  
  const editarNuevoMenuSocial = (e) => {
    e.preventDefault();
    const copiaMenuPrincipal = [...menuPrincipal];
    copiaMenuPrincipal[posicionMenu] = formMenuSocial;
    editarArrayPromocion(copiaMenuPrincipal);
    setFormMenuSocial(initFormMenuSocial);
    setEstadoMenuModal(false);
  };

  return (
    <div className="contenedor-padre-modal">
      <div className="contenedor-modal">
        <div className="modal-cerrar">
          {" "}
          <button onClick={() => setEstadoMenuModal(false)}>
            <img src="/icons/Cerrar.svg" alt="" />
          </button>
        </div>

        <form onSubmit={editarNuevoMenuSocial}>
          <p>Nombre Menu:</p>
          <input
            type="text"
            required
            name="formMenuSocial"
            placeholder="Nombre Menu"
            value={formMenuSocial}
            onChange={(event) => setFormMenuSocial(event.target.value)}
          />
          <input type="submit" value="Editar Promición" />
        </form>
      </div>
    </div>
  );
};
export default PromocionModalEditar;
