import { doc, getDoc, updateDoc, arrayUnion } from "firebase/firestore";
import { getStorage, ref, uploadBytes, getDownloadURL } from "firebase/storage";
import { db } from "../firebase/firebase";
const coleccion = "Slider";
const idSlider = "MO8S53b26NEcOt3SEzxW";
const rutaFoto = "slider-imagenes";

export const traerUnSlider = async () => {
  const sliderRef = doc(db, coleccion, idSlider);
  const docSlider = await getDoc(sliderRef);

  if (docSlider.exists()) {
    return docSlider.data();
  } else {
    console.log("No existe slider");
  }
};

export const agregarArraySlider = async (linkImagen) => {
  const sliderRef = doc(db, coleccion, idSlider);

  const nuevoSlider = {
    idSlider: "00" + Date.now(),
    imagen: linkImagen,
  };

  await updateDoc(sliderRef, {
    SliderPrincipal: arrayUnion(nuevoSlider),
  });
};

export const sliderAgregarCF = (fotosVista) => {
  const fechaAhora = Date.now();
  const rutaCompleta =
    fotosVista.name + fechaAhora + fotosVista.lastModified + fotosVista.size;
  const storage = getStorage();
  const imageRef = ref(storage, `${rutaFoto}/${rutaCompleta}`);
  uploadBytes(imageRef, fotosVista)
    .then((snapshot) => {
      getDownloadURL(snapshot.ref).then((linkImagen) => {
        agregarArraySlider(linkImagen);
      });
    })
    .catch((error) => {
      console.error("Error al subir imagenes", error);
    });
};

export const editarArraySlider = async (datosNuevo) => {
  const sliderRef = doc(db, coleccion, idSlider);
  await updateDoc(sliderRef, {
    SliderPrincipal: datosNuevo,
  });
};
export const slideEditarCF = (principal, formGaleria, posicion, fotosVista) => {
  const fechaAhora = Date.now();
  const rutaCompleta =
    fotosVista.name + fechaAhora + fotosVista.lastModified + fotosVista.size;
  const storage = getStorage();
  const imageRef = ref(storage, `${rutaFoto}/${rutaCompleta}`);
  uploadBytes(imageRef, fotosVista)
    .then((snapshot) => {
      getDownloadURL(snapshot.ref).then((linkImagen) => {
        const cambiandoImagenes = formGaleria;
        cambiandoImagenes.imagen = linkImagen;

        const copiaGaleria = [...principal];
        copiaGaleria[posicion] = formGaleria;
        editarArraySlider(copiaGaleria);
      });
    })
    .catch((error) => {
      console.error("Error al subir imagenes", error);
    });
};

export const editarSliderSinFoto = async (url) => {
  const sliderRef = doc(db, coleccion, idSlider);
  await updateDoc(sliderRef, {
    imagen: url === undefined ? null : url,
  });
};

export const editarSliderConFoto = (foto) => {
  const fechaAhora = Date.now();
  const rutaCompleta = foto.name + fechaAhora + foto.lastModified + foto.size;
  const storage = getStorage();
  const imageRef = ref(storage, `${rutaFoto}/${rutaCompleta}`);
  uploadBytes(imageRef, foto)
    .then((snapshot) => {
      getDownloadURL(snapshot.ref).then((url) => {
        editarSliderSinFoto(url, idSlider);
      });
    })
    .catch((error) => {
      console.error("Error al subir imagen", error);
    });
};

export const editarSliderTitulares = async (titulo, subTitulo) => {
  const sliderRef = doc(db, coleccion, idSlider);
  await updateDoc(sliderRef, {
    titulo: titulo,
    subTitulo: subTitulo,
  });
};
