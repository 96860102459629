import React from "react";
import "./DireccionCheckout.css";

const DireccionCheckout = ({ direcciones, setDireccionSeleccionado }) => {
  const cambiarDireccion = (event) => {
    let idDireccionRadio = event.target.value;
    const direccionFiltrado = direcciones.filter(
      (direc) => direc.idDireccion === idDireccionRadio
    );
    setDireccionSeleccionado(direccionFiltrado[0]);
  };

  return (
    <>
      <p>Escoge tu direccion</p>
      {direcciones.map((item) => (
        <div key={item.idDireccion}>
          <label className="contenedor-direccion">
            <input
              required
              type="radio"
              name="idDireccion"
              value={item.idDireccion}
              onChange={cambiarDireccion}
            />
            <span className="nuevaseleccion"></span>
            <div className="contenedor-datos-direccion">
              <p><span>Estado: </span> {item.estado}</p>
              <p><span>Municipio: </span> {item.municipio}</p>
              <p><span>Parroquia:</span> {item.parroquia}</p>
              <p><span>Dirección: </span>{item.direccion}</p>
              <p><span>Recomendación: </span>{item.recomendacion}</p>
            </div>
          </label>
        </div>
      ))}
    </>
  );
};

export default DireccionCheckout;
