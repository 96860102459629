import React, { useState, useEffect } from "react";
import { db } from "../../../firebase/firebase";
import { collection, onSnapshot } from "firebase/firestore";
import { etiquetaEliminar } from "../../../controllers/Etiquetas";
import {
  EtiquetasCrearModal,
  EtiquetasEditarModal,
} from "../../../components/Modal";
import { TablaEditar } from "../../../components/Tablas";

const Etiquetas = () => {
  const [estadoModal, setEstadoModal] = useState(false);
  const [estadoModalEditar, setEstadoModalEditar] = useState(false);
  const [etiquetas, setEtiquetas] = useState([]);
  const [etiquetaSeleccionada, setEtiquetaSeleccionada] = useState({});

  useEffect(() => {
    onSnapshot(collection(db, "Etiquetas"), (snapshot) => {
      setEtiquetas(
        snapshot.docs.map((doc) => ({
          idEtiqueta: doc.id,
          ...doc.data(),
        }))
      );
    });
  }, []);

  const habilitarEditar = (etiquetaItem) => {
    setEtiquetaSeleccionada(etiquetaItem);
    setEstadoModalEditar(!estadoModalEditar);
  };

  return (
    <>
      {estadoModal && <EtiquetasCrearModal setEstadoModal={setEstadoModal} />}
      {estadoModalEditar && (
        <EtiquetasEditarModal
          setEstadoModalEditar={setEstadoModalEditar}
          etiquetaSeleccionada={etiquetaSeleccionada}
        />
      )}
      <h4 className="titulo-panel-administracion">Etiquetas</h4>

      <button
        onClick={() => setEstadoModal(!estadoModal)}
        className="boton-administracion"
      >
        Agregar Etiqueta{" "}
      </button>
      {etiquetas?.length === 0 ? (
        <h5>No hay etiquetas</h5>
      ) : (
        <>
          <TablaEditar
            etiquetas={etiquetas}
            habilitarEditar={habilitarEditar}
            etiquetaEliminar={etiquetaEliminar}
          />
        </>
      )}
    </>
  );
};

export default Etiquetas;
