import React, { useContext, useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { useLiveQuery } from "dexie-react-hooks";
import { db2 } from "../../../controllers/IndexDB";
import ProductoCheckout from "../../../components/ProductoCheckout/ProductoCheckout";
import FormularioPagar from "../../../components/FormularioPagar/FormularioPagar";
import { EstadoContexto } from "../../../context/EstadoGeneral";
import { traerWhatsAppCarrito } from "../../../controllers/WhatsApp";
import { traerDireccion } from "../../../controllers/Direcciones";
import DireccionCheckout from "./DireccionCheckout";
import "./Checkout.css";

const Checkout = () => {
  const { usuario } = useContext(EstadoContexto);
  const [whatsAppCarrito, setWhatsAppCarrito] = useState({});
  const [direcciones, setDirecciones] = useState([]);
  const [direccionSeleccionado, setDireccionSeleccionado] = useState({});

  const exiteUsuario = Object.keys(usuario).length;
  useEffect(() => {
    (async () => {
      const whatsAppCarritoDoc = await traerWhatsAppCarrito();
      setWhatsAppCarrito(whatsAppCarritoDoc);
    })();
  }, []);

  useEffect(() => {
    (async () => {
      if (Object.keys(usuario).length) {
        const direccionDoc = await traerDireccion(usuario.idCliente);
        setDirecciones(direccionDoc);
        setDireccionSeleccionado(direccionDoc[0])
      }
    })();
  }, [usuario]);

  const productos = useLiveQuery(() => db2.productosIDB.toArray());
  const productosTotal = useLiveQuery(() => db2.productosIDB.count());

  const totalAPagar = () =>
    productos?.reduce(
      (antes, actual) => parseFloat(actual.precio) * actual.cantidad + antes,
      0
    );

  return (
    <>
      {!productos || productosTotal === 0 ? (
        <div className="no-existe"></div>
      ) : (
        <>
          <div className="contenedor-titulo">
            <h2 className="titulo">CART</h2>
          </div>
          <div className="contenedor-grid-carrito">
            <div className="grid-carrito-productos">
              <ProductoCheckout productos={productos} />
              <br />
              {exiteUsuario && usuario.rol === "cliente"  ? (
                <>
                  <p className="titulo-checkout">DIRECCIÓN DE ENVIO</p>
                  {direcciones.length !== 0 ? (
                    <DireccionCheckout
                      direcciones={direcciones}
                      setDireccionSeleccionado={setDireccionSeleccionado}
                    />
                  ) : (
                    <p style={{ fontSize: "12px" }}>
                      Registra tu{" "}
                      <Link
                        to="/cliente/mis-direcciones"
                        style={{ textDecoration: "underline" }}
                      >
                        Dirección en tu perfil
                      </Link>
                    </p>
                  )}
                </>
              ) : (
                <>
                  <p style={{ fontSize: "12px" }}>
                    Already have an account?{" "}
                    <Link
                      to="/ingresar"
                      style={{ textDecoration: "underline" }}
                    >
                      Sign In
                    </Link>
                  </p>
                </>
              )}
            </div>
            <div className="grid-carrito-pagar">
              <FormularioPagar
                productosTotal={productosTotal}
                totalAPagar={totalAPagar}
                productos={productos}
                whatsAppCarrito={whatsAppCarrito}
                direccionSeleccionado={direccionSeleccionado}
              />
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default Checkout;
