export default function EnviarContacto(formContacto, whatsAppProducto) {
  //console.log(formContacto, whatsAppProducto)
  let saltoLinea = "%0D%0A";
  const numeroCelular = whatsAppProducto.celular;
  const textoMensaje = `Hola Palma, soy ${formContacto.nombres}: ${saltoLinea} 
  ${saltoLinea}-Mi correo es: ${formContacto.correo}
  ${saltoLinea}-Asunto: ${formContacto.asunto}
  ${saltoLinea}-Mensaje: ${formContacto.mensaje}
  `;
  const wspLink = `https://api.whatsapp.com/send/?phone=${numeroCelular}&text=${textoMensaje}&app_absent=0`;
  window.open(wspLink, "_blank");
}
