import React from "react";
import "./CaracteristicasProducto.css";

const CaracteristicasProducto = ({
  caracteristicas,
  setCaracteristicas,
  color,
}) => {
  const subirCaracteristica = () => {
    setCaracteristicas((elemento) => [...elemento.slice(1), elemento[0]]);
  };

  const bajarCaracteristica = () => {
    setCaracteristicas((elemento) => [
      caracteristicas[caracteristicas.length - 1],
      ...elemento.slice(0, -1),
    ]);
  };

  const eliminarCaracteristica = (index) => {
    const nuevaArray = [...caracteristicas];
    nuevaArray.splice(index, 1);
    setCaracteristicas(nuevaArray);
  };

  return caracteristicas.map((carac, index) => (
    <div className="contenedor-caracteristica" key={index}>
      <div key={index} className="contenedor-caracteristica-letra">
        <img src="/icons/Estrellas.svg" alt="" />
        <span>{carac}</span>
        {color && (
          <span
            style={{
              width: "20px",
              height: "20px",
              backgroundColor: `#${carac}`,
              borderRadius: "50px",
              marginLeft: "3px"
            }}
          ></span>
        )}
      </div>
      <div>
        <span onClick={() => subirCaracteristica()}>
          <img className="icono-editar" src="/icons/Arriba.svg" alt="logo" />{" "}
        </span>
        <span onClick={() => bajarCaracteristica()}>
          <img className="icono-editar" src="/icons/Abajo.svg" alt="logo" />
        </span>
        <span onClick={() => eliminarCaracteristica(index)}>
          <img className="icono-editar" src="/icons/Eliminar.svg" alt="logo" />
        </span>
      </div>
    </div>
  ));
};
export default CaracteristicasProducto;
