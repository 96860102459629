import { doc, updateDoc, arrayUnion } from "firebase/firestore";
import { db } from "../firebase/firebase";
const coleccion = "Menu";
const idMenuInfo = "CFIba5VbqoHT0EQP8DrY";

export const agregarArrayMenuInfo = async (datosNuevoMenu) => {
  const menuInfoRef = doc(db, coleccion, idMenuInfo);

  const nuevoMenu = {
    idMenuInfo: "00" + Date.now(),
    nombre: datosNuevoMenu.nombre,
    url: datosNuevoMenu.url,
    mostrar: true
  };

  await updateDoc(menuInfoRef, {
    MenuInfo: arrayUnion(nuevoMenu),
  });
};

export const editarArrayMenuInfo = async (menuActualizado) => {
  const menuInfoRef = doc(db, coleccion, idMenuInfo);
  await updateDoc(menuInfoRef, {
    MenuInfo: menuActualizado,
  });
};

export const mostrarArrayMenuInfo = async (mostrar) => {
  const menuInfoRef = doc(db, coleccion, idMenuInfo);
  await updateDoc(menuInfoRef, {
    mostrar: mostrar,
  });
};
