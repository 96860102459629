import React, { useState, useRef } from "react";
import { crearMenuPrincipalCF } from "../../../controllers/MenuPrincipal";
import MostrarImagenes from "../../MostrarImagenes/MostrarImagenes";
import "./../Modal.css";

const initFormMenuPrincipal = {
  nombreCategoria: "",
  urlCategoria: "",
  nombreSubCategoria: "",
  urlSubCategoria: "",
};

const MenuModalPrincipalEditar = ({ setEstadoMenuModal }) => {
  const [formMenuPrincipal, setFormMenuPrincipal] = useState(
    initFormMenuPrincipal
  );
  const [fotos, setFotos] = useState([]);
  const [fotosVista, setFotosVista] = useState([]);

  const imagenRef = useRef();
  const cambiarDatosDelMenuPrincipal = (e) => {
    const { name, value } = e.target;
    setFormMenuPrincipal({
      ...formMenuPrincipal,
      [name]: value,
    });
  };

  const cambiarFotos = (e) => {
    if (e.target.files) {
      const fotosArray = Array.from(e.target.files).map((file) =>
        URL.createObjectURL(file)
      );
      const fotosArray2 = Array.from(e.target.files);

      setFotos((prevImages) => prevImages.concat(fotosArray));
      Array.from(e.target.files).map((file) => URL.revokeObjectURL(file));

      setFotosVista((prevImages) => prevImages.concat(fotosArray2));
    }
  };

  const agregarNuevoMenuPrincipal = (e) => {
    e.preventDefault();

    if (fotosVista.length > 0) {
      crearMenuPrincipalCF(formMenuPrincipal, fotosVista);
      setFormMenuPrincipal(initFormMenuPrincipal);
      setFotos([]);
      setFotosVista([]);
      imagenRef.current.value = "";
      setEstadoMenuModal(false);
    } else {
      console.log("Añade 1 imagen por lo menos.");
    }
  };

  return (
    <div className="contenedor-padre-modal">
      <div className="contenedor-modal">
        <div className="modal-cerrar">
          {" "}
          <button onClick={() => setEstadoMenuModal(false)}>
            <img src="/icons/Cerrar.svg" alt="" />
          </button>
        </div>

        <form onSubmit={agregarNuevoMenuPrincipal}>
          <p>Nombre Menu:</p>
          <input
            type="text"
            required
            name="nombreCategoria"
            placeholder="Nombre Menu"
            value={formMenuPrincipal.nombreCategoria}
            onChange={cambiarDatosDelMenuPrincipal}
          />

          <p>URL Menu:</p>
          <input
            type="text"
            required
            name="urlCategoria"
            placeholder="URL Menu"
            value={formMenuPrincipal.urlCategoria}
            onChange={cambiarDatosDelMenuPrincipal}
          />

          <p>Submenu:</p>
          <input
            type="text"
            required
            name="nombreSubCategoria"
            placeholder="Nombre Submenu"
            value={formMenuPrincipal.nombreSubCategoria}
            onChange={cambiarDatosDelMenuPrincipal}
          />

          <p>URL Sub Menu:</p>
          <input
            type="text"
            required
            name="urlSubCategoria"
            placeholder="URL Sub Menu"
            value={formMenuPrincipal.urlSubCategoria}
            onChange={cambiarDatosDelMenuPrincipal}
          />

          <p>Imagenes:</p>
          <span
            className="agregar-caracteristica"
            onClick={() => imagenRef.current.click()}
          >
            Subir Imagen
          </span>
          <input
            ref={imagenRef}
            type="file"
            id="file"
            required
            multiple
            onChange={cambiarFotos}
            style={{
              display: "none",
            }}
          />
          <MostrarImagenes
            fotos={fotos}
            fotosVista={fotosVista}
            setFotos={setFotos}
            setFotosVista={setFotosVista}
          />
          <input type="submit" value="Guardar Menu" />
        </form>
      </div>
    </div>
  );
};
export default MenuModalPrincipalEditar;
