import React, { useState, useEffect } from "react";
import { db } from "../../../firebase/firebase";
import { doc, onSnapshot } from "firebase/firestore";
import { editarArrayMenuPromocion } from "../../../controllers/MenuPromocion";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { GaleriaModalEditar } from "../../../components/Modal";
import "./Galeria.css";

const reodernarLista = (nuevaLista, posicionInicio, posicionFinal) => {
  const nuevaListaMenu = [...nuevaLista];
  const [removido] = nuevaListaMenu.splice(posicionInicio, 1);
  nuevaListaMenu.splice(posicionFinal, 0, removido);
  return nuevaListaMenu;
};

const Galeria = () => {
  const [galeriaPrincipal, setGaleriaPrincipal] = useState([]);
  const [estadoMenuModalEditar, setEstadoMenuModalEditar] = useState(false);
  const [itemSeleccionado, setMenuSeleccionado] = useState({});
  const [posicion, setPosicionMenu] = useState(0);

  const editarMenu = (categoria, index) => {
    setPosicionMenu(index);
    setMenuSeleccionado(categoria);
    setEstadoMenuModalEditar(!estadoMenuModalEditar);
  };

  useEffect(() => {
    const galeriaRef = doc(db, "Galeria", "nlmnSRfI8UDIXxjVb3gY");
    onSnapshot(galeriaRef, (doc) => {
      setGaleriaPrincipal(doc.data().GaleriaPrincipal);
    });
  }, []);

  const guardarNuevoMenu = (e) => {
    e.preventDefault();
    editarArrayMenuPromocion(galeriaPrincipal);
  };

  return (
    <>
      {galeriaPrincipal?.length === 0 ? (
        <></>
      ) : (
        <>
          {estadoMenuModalEditar && (
            <GaleriaModalEditar
              setEstadoModal={setEstadoMenuModalEditar}
              seleccionado={itemSeleccionado}
              principal={galeriaPrincipal}
              posicion={posicion}
            />
          )}
          <h1>Galeria</h1>
          <p>Son con 4 imagenes obligatorias.</p>

          <DragDropContext
            onDragEnd={(result) => {
              const { source, destination } = result;
              if (!destination) {
                return;
              }
              if (
                source.index === destination.index &&
                source.droppableId === destination.droppableId
              ) {
                return;
              }

              setGaleriaPrincipal((listaAntigua) =>
                reodernarLista(listaAntigua, source.index, destination.index)
              );
            }}
          >
            <div className="app">
              <Droppable droppableId="tasks">
                {(droppableProvided) => (
                  <ul
                    {...droppableProvided.droppableProps}
                    ref={droppableProvided.innerRef}
                    className="contenedor-direcciones-drop"
                  >
                    {galeriaPrincipal.map((galeria, index) => (
                      <Draggable
                        key={galeria.idGaleria}
                        draggableId={galeria.idGaleria}
                        index={index}
                      >
                        {(draggableProvided) => (
                          <li
                            {...draggableProvided.draggableProps}
                            ref={draggableProvided.innerRef}
                            {...draggableProvided.dragHandleProps}
                          >
                            <p>{galeria.nombre}</p>
                            <p>{galeria.url}</p>
                            <br />
                            <img
                              style={{ width: "100px" }}
                              src={galeria.imagen}
                              alt=""
                            />
                            <div className="contenedor-botones-drop">
                              <div className="drop-botones-drop-menu">
                                <span
                                  style={{ backgroundColor: "#f2c9cd" }}
                                  onClick={() => editarMenu(galeria, index)}
                                >
                                  Editar Menu
                                </span>
                              </div>
                            </div>{" "}
                          </li>
                        )}
                      </Draggable>
                    ))}
                    {droppableProvided.placeholder}
                  </ul>
                )}
              </Droppable>
            </div>
          </DragDropContext>
          <span
            className="drop-botones-guardar"
            style={{
              backgroundColor: "#555555",
              color: "white",
            }}
            onClick={guardarNuevoMenu}
          >
            Guardar Galeria
          </span>
        </>
      )}
    </>
  );
};

export default Galeria;
