import React from "react";
import "./Notificacion.css";

const Notificacion = ({
  mensajeNotificacion,
  setEstadoNotificacion,
  setMensajeEstadoNotificacion,
}) => {
  setTimeout(() => {
    setEstadoNotificacion(false);
    if (setMensajeEstadoNotificacion) {
      setMensajeEstadoNotificacion(null);
    }
  }, 4000);
  return <div className="contenedor-notificacion">{mensajeNotificacion}</div>;
};
export default Notificacion;
