import React, { useState, useEffect } from "react";
import { db } from "../../../firebase/firebase";
import { doc, onSnapshot } from "firebase/firestore";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { editarArraySlider } from "../../../controllers/Slider";
import {
  SlidersModalAgregar,
  SlidersModalEditar,
} from "../../../components/Modal";
import "./Slider.css";

const reodernarLista = (nuevaLista, posicionInicio, posicionFinal) => {
  const nuevaListaMenu = [...nuevaLista];
  const [removido] = nuevaListaMenu.splice(posicionInicio, 1);
  nuevaListaMenu.splice(posicionFinal, 0, removido);
  return nuevaListaMenu;
};

const Slider = () => {
  const [sliderPrincipal, setSliderPrincipal] = useState([]);
  const [estadoMenuModal, setEstadoMenuModal] = useState(false);
  const [estadoMenuModalEditar, setEstadoMenuModalEditar] = useState(false);
  const [itemSeleccionado, setItemSeleccionado] = useState({});
  const [posicion, setPosicionMenu] = useState(0);

  useEffect(() => {
    const sliderRef = doc(db, "Slider", "MO8S53b26NEcOt3SEzxW");
    onSnapshot(sliderRef, (doc) => {
      setSliderPrincipal(doc.data().SliderPrincipal);
    });
  }, []);

  const editarMenu = (slider, index) => {
    setPosicionMenu(index);
    setItemSeleccionado(slider);
    setEstadoMenuModalEditar(!estadoMenuModalEditar);
  };

  const eliminarMenu = (id) => {
    setSliderPrincipal(
      sliderPrincipal.filter((elemento) => elemento.idSlider !== id)
    );
  };

  const guardarNuevoMenu = (e) => {
    e.preventDefault();
    editarArraySlider(sliderPrincipal);
  };

  return (
    <>
      {estadoMenuModal && (
        <SlidersModalAgregar setEstadoMenuModal={setEstadoMenuModal} />
      )}
      <h2 style={{ fontSize: "16px" }}>Slider </h2>
      <p>Tamaño: 2000px X 855px</p>

      {sliderPrincipal?.length === 0 ? (
        <></>
      ) : (
        <>
          {estadoMenuModalEditar && (
            <SlidersModalEditar
              setEstadoMenuModal={setEstadoMenuModalEditar}
              seleccionado={itemSeleccionado}
              principal={sliderPrincipal}
              posicionMenu={posicion}
            />
          )}
          <DragDropContext
            onDragEnd={(result) => {
              const { source, destination } = result;
              if (!destination) {
                return;
              }
              if (
                source.index === destination.index &&
                source.droppableId === destination.droppableId
              ) {
                return;
              }

              setSliderPrincipal((listaAntigua) =>
                reodernarLista(listaAntigua, source.index, destination.index)
              );
            }}
          >
            <div className="app">
              <Droppable droppableId="tasks">
                {(droppableProvided) => (
                  <ul
                    {...droppableProvided.droppableProps}
                    ref={droppableProvided.innerRef}
                    className="contenedor-direcciones-drop"
                  >
                    {sliderPrincipal.map((galeria, index) => (
                      <Draggable
                        key={galeria.idSlider}
                        draggableId={galeria.idSlider}
                        index={index}
                      >
                        {(draggableProvided) => (
                          <li
                            {...draggableProvided.draggableProps}
                            ref={draggableProvided.innerRef}
                            {...draggableProvided.dragHandleProps}
                          >
                            <img
                              style={{ width: "300px" }}
                              src={galeria.imagen}
                              alt=""
                            />
                            <div className="contenedor-botones-drop">
                              <div className="drop-botones-drop-menu">
                                <span
                                  style={{ backgroundColor: "#f2c9cd" }}
                                  onClick={() => editarMenu(galeria, index)}
                                >
                                  Editar Slider
                                </span>
                                <span
                                  style={{
                                    backgroundColor: "#dadad8",
                                  }}
                                  onClick={() => eliminarMenu(galeria.idSlider)}
                                >
                                  Eliminar Slider
                                </span>
                              </div>
                            </div>{" "}
                          </li>
                        )}
                      </Draggable>
                    ))}
                    {droppableProvided.placeholder}
                  </ul>
                )}
              </Droppable>
            </div>
          </DragDropContext>
          <span
            className="drop-botones-guardar"
            style={{
              backgroundColor: "#555555",
              color: "white",
            }}
            onClick={guardarNuevoMenu}
          >
            Guardar Orden
          </span>
        </>
      )}
      <span
        className="drop-botones-agregar"
        style={{
          backgroundColor: "black",
          color: "white",
        }}
        onClick={() => setEstadoMenuModal(!estadoMenuModal)}
      >
        Agregar Nuevo Slider
      </span>
    </>
  );
};

export default Slider;
