import {
  collection,
  addDoc,
  deleteDoc,
  doc,
  getDoc,
  updateDoc,
  getDocs,
  query,
  where,
} from "firebase/firestore";
import { getStorage, ref, uploadBytes, getDownloadURL } from "firebase/storage";

import { db } from "../firebase/firebase";
const coleccion = "Productos";
const rutaFoto = "productos-imagenes";

/* TRAER TODOS LOS PRODUCTOS*/
export const todosProductos = async () => {
  const productosRef = collection(db, coleccion);
  const productosDB = await getDocs(productosRef);
  return productosDB.docs.map((doc) => {
    return {
      idProducto: doc.id,
      ...doc.data(),
    };
  });
};

/*Trae productos por categoria */
export const productoCategoria = async (categoriaProducto) => {
  const productosRef = collection(db, "Productos");
  const queryProducto = query(
    productosRef,
    where("categoria", "==", categoriaProducto)
  );
  const productosDB = await getDocs(queryProducto);
  return productosDB.docs.map((doc) => {
    return {
      idProducto: doc.id,
      ...doc.data(),
    };
  });
};

export const productoCrearSF = async (
  formProducto,
  categoriaSelect,
  etiquetaFinal,
  caracteristicas,
  fotosSubir,
  menuPrincipalSelect,
  tallaFinal,
  colores
) => {
  try {
    await addDoc(collection(db, coleccion), {
      nombre: formProducto.nombre,
      marca: formProducto.marca,
      url: formProducto.url,
      caracteristicas: caracteristicas,
      precio: formProducto.precio,
      oferta: formProducto.oferta,
      stock: formProducto.stock,
      tiempoEntrega: formProducto.tiempoEntrega,
      categoria: categoriaSelect,
      menuPrincipal: menuPrincipalSelect,
      etiqueta: etiquetaFinal,
      imagenes: fotosSubir,
      estado: true,
      tallas: tallaFinal,
      colores: colores,
      fechaCreacion: new Date(),
    });
  } catch (e) {
    console.error("Error al agregar producto ", e);
  }
};
/* SUBIR UNA IMAGEN*/
export const productoCrearCF = (
  formProducto,
  categoriaSelect,
  etiquetaFinal,
  caracteristicas,
  fotosVista,
  menuPrincipalSelect,
  tallaFinal,
  colores
) => {
  const promises = fotosVista.map((file) => {
    const fechaAhora = Date.now();
    const rutaCompleta = file.name + fechaAhora + file.lastModified + file.size;
    const storage = getStorage();
    const imageRef = ref(storage, `${rutaFoto}/${rutaCompleta}`);
    return uploadBytes(imageRef, file)
      .then((snapshot) => {
        return getDownloadURL(snapshot.ref);
      })
      .catch((error) => {
        console.error("Error al subir imagenes", error);
      });
  });
  Promise.all(promises)
    .then((linkImagenes) => {
      productoCrearSF(
        formProducto,
        categoriaSelect,
        etiquetaFinal,
        caracteristicas,
        linkImagenes,
        menuPrincipalSelect,
        tallaFinal,
        colores
      );
    })
    .catch(() => {
      return "Hubo un error";
    });
};

/* ELIMINAR UNA PRODUCTO */
export const productoEliminar = async (idProducto) => {
  await deleteDoc(doc(db, coleccion, idProducto));
};

export const productoUno = async (idProducto) => {
  const productoRef = doc(db, coleccion, idProducto);
  const docProducto = await getDoc(productoRef);
  if (docProducto.exists()) {
    return docProducto.data();
  } else {
    console.log("No existe el documento");
  }
};

/* EDITAR UN PRODUCTO SIN IMAGEN */
export const productoEditarSF = async (
  formProducto,
  categoriaSelect,
  etiquetaFinal,
  caracteristicas,
  fotosAntiguas,
  menuPrincipalSelect,
  tallaFinal,
  colores
) => {
  const categoriaRef = doc(db, coleccion, formProducto.idProducto);
  await updateDoc(categoriaRef, {
    nombre: formProducto.nombre,
    marca: formProducto.marca,
    url: formProducto.url,
    caracteristicas: caracteristicas,
    precio: formProducto.precio,
    oferta: formProducto.oferta,
    stock: formProducto.stock,
    tiempoEntrega: formProducto.tiempoEntrega,
    categoria: categoriaSelect,
    menuPrincipal: menuPrincipalSelect,
    etiqueta: etiquetaFinal,
    imagenes: fotosAntiguas,
    tallas: tallaFinal,
    colores,
  });
};

// EDITAR PRODUCTO CON FOTO //
export const productoEditarCF = (
  formProducto,
  categoriaSelect,
  etiquetaFinal,
  caracteristicas,
  fotosAntiguas,
  fotosVista,
  menuPrincipalSelect,
  tallaFinal,
  colores
) => {
  const promises = fotosVista.map((file) => {
    const fechaAhora = Date.now();
    const rutaCompleta = file.name + fechaAhora + file.lastModified + file.size;
    const storage = getStorage();
    const imageRef = ref(storage, `${rutaFoto}/${rutaCompleta}`);
    return uploadBytes(imageRef, file)
      .then((snapshot) => {
        return getDownloadURL(snapshot.ref);
      })
      .catch((error) => {
        console.error("Error al subir imagenes", error);
      });
  });
  Promise.all(promises)
    .then((linkImagenes) => {
      const fotosSubir =
        fotosAntiguas.length === 0
          ? linkImagenes
          : fotosAntiguas.concat(linkImagenes);

      productoEditarSF(
        formProducto,
        categoriaSelect,
        etiquetaFinal,
        caracteristicas,
        fotosSubir,
        menuPrincipalSelect,
        tallaFinal,
        colores
      );
    })
    .catch(() => {
      return "Hubo un error";
    });
};
