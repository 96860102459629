import React, { useState, useEffect, useContext } from "react";
import { EstadoContexto } from "../../../context/EstadoGeneral";
import { pedidosTodos } from "../../../controllers/Pedidos";
import Recibo from "./Recibo";
import "./Compras.css";

const MisCompras = () => {
  const { usuario } = useContext(EstadoContexto);
  const [pedidos, setPedidos] = useState([]);
  useEffect(() => {
    (async () => {
      const pedidosDB = await pedidosTodos(usuario.idCliente);
      setPedidos(pedidosDB);
    })();
  }, [usuario]);
  const mostrarRecibo = (pedido) => {
    Recibo(pedido);
  };

  return (
    <>
      <div className="titulo-checkout">
        <p>MIS PEDIDOS</p>
      </div>
      {pedidos?.length === 0 ? (
        <p>No hay pedidos</p>
      ) : (
        <>
          {pedidos.map((pedido) => (
            <div key={pedido.idPedido} className="contenedor-pedido">
              <div className="titulo-pedido">
                <div>
                  <p>N° Pedido: {pedido.numeroPedido}</p>
                  <span>|</span>
                  <p>Fecha: {pedido.fecha.toDate().toLocaleDateString()}</p>
                  <span>|</span>
                  <p style={{ fontWeight: "700" }}>
                    Total: USD {pedido.monto}.00{" "}
                  </p>
                  <button
                    onClick={() => mostrarRecibo(pedido)}
                    style={{ color: "white", backgroundColor: "black" }}
                  >
                    PDF
                  </button>
                </div>
                <div>
                  <button
                    style={
                      pedido.estado === "pedido"
                        ? { color: "white", backgroundColor: "black" }
                        : { color: "black" }
                    }
                  >
                    Pedido
                  </button>
                  <button
                    style={
                      pedido.estado === "ruta"
                        ? { color: "white", backgroundColor: "black" }
                        : { color: "black" }
                    }
                  >
                    Ruta
                  </button>
                  <button
                    style={
                      pedido.estado === "entregado"
                        ? { color: "white", backgroundColor: "black" }
                        : { color: "black" }
                    }
                  >
                    Entregado
                  </button>
                </div>
              </div>

              <div className="productos-pedido">
                {pedido.productos.map((producto) => (
                  <div
                    key={producto.idProducto}
                    style={{ marginBottom: "5px" }}
                  >
                    <img src={producto.imagenes[0]} alt="" />
                    <p>{producto.nombre}</p>
                    <span>|</span>
                    <p>Cantidad: {producto.cantidad}</p>
                    <span>|</span>
                    <p style={{ fontWeight: "700" }}>
                      Precio: USD {producto.precio}.00{" "}
                    </p>{" "}
                  </div>
                ))}
              </div>
              <hr />
              <div style={{ padding: "8px", fontSize:"14px" }}>
                <label>Destinatario: </label>
                <span>{pedido.usuario.nombres + pedido.usuario.apellidos}</span>
                <br />
                <label>Correo: </label>
                <span>{pedido.usuario.correo}</span>
                <br />
                <label>Celular: </label>
                <span>{pedido.usuario.celular}</span>
                <br />             
                <hr />
                <label>Estado: </label>
                <span>{pedido.direccion.estado}</span>
                <br />
                <label>Municipio: </label>
                <span>{pedido.direccion.municipio}</span>
                <br />
                <label>Parroquia: </label>
                <span>{pedido.direccion.parroquia}</span>
                <br />
                <label>Dirección: </label>
                <span>{pedido.direccion.direccion}</span>
                <br />
                <label>Recomendación de envio: </label>
                <span>{pedido.direccion.recomendacion}</span>
              </div>
            </div>
          ))}
        </>
      )}{" "}
    </>
  );
};

export default MisCompras;
