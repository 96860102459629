import { doc, updateDoc, arrayUnion } from "firebase/firestore";
import { db } from "../firebase/firebase";
const coleccion = "Menu";
const idMenuSocial = "WsIW0MIqPVrL16QIGEcq";

export const agregarArrayMenuSocial = async (datosNuevoMenu) => {
  const menuSocialRef = doc(db, coleccion, idMenuSocial);

  const nuevoMenu = {
    idMenuSocial: "00" + Date.now(),
    nombre: datosNuevoMenu.nombre,
    url: datosNuevoMenu.url,
    mostrar: true
  };

  await updateDoc(menuSocialRef, {
    MenuSocial: arrayUnion(nuevoMenu),
  });
};

export const editarArrayMenuSocial = async (menuActualizado) => {
  const menuSocialRef = doc(db, coleccion, idMenuSocial);
  await updateDoc(menuSocialRef, {
    MenuSocial: menuActualizado,
  });
};

export const mostrarArrayMenuSocial = async (mostrar) => {
  const menuSocialRef = doc(db, coleccion, idMenuSocial);
  await updateDoc(menuSocialRef, {
    mostrar: mostrar,
  });
};

