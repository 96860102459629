import React, { useContext, useState, useEffect } from "react";
import Menu from "../../components/Menu/Menu";
import Promocion from "../../components/Promocion/Promocion";
import Pie from "../../components/Pie/Pie";
import { todosMenu } from "../../controllers/Menu";
import { EstadoContexto } from "../../context/EstadoGeneral";
import { Link } from "react-router-dom";
import "./LayoutCliente.css";

const LayoutCliente = (props) => {
  const [menus, setMenus] = useState([]);
  const [botonLayout, setBotonLayout] = useState(false);

  const { cerrarSesion } = useContext(EstadoContexto);
  const { children } = props;

  useEffect(() => {
    (async () => {
      const menusDB = await todosMenu();
      setMenus(menusDB);
    })();
  }, []);

  return (
    <>
      {menus?.length === 0 ? (
        <></>
      ) : (
        <>
          <Menu menuPrincipal={menus[1].MenuPrincipal} />
          <section style={{ marginTop: "135px" }}>
            <Promocion />

            <div className="contenedor-perfil">
              <div className="cabecera-perfil">
                {/*<h3>HOLA, Emerson</h3>
                <p>Thanks for creating an account with P&amp;Co</p>*/}
                <button
                  onClick={() => setBotonLayout(!botonLayout)}
                  className="boton-menu-layout"
                >
                  {botonLayout ? "Mostrar Menu" : "Ocultar Menu"}
                </button>
              </div>
              <div className="grid-perfil">
                <div
                  className="grid-perfil-menu"
                  style={{ display: botonLayout ? "none" : "block" }}
                >
                  <ul>
                    <li>
                      <Link to="/cliente/perfil">Mis datos / My Details</Link>
                    </li>
                    <li>
                      <Link to="/cliente/productos-guardados">
                        Mi lista de deseo / My Saved Items
                      </Link>
                    </li>
                    <li>
                      <Link to="/cliente/mis-compras">Mis ordenes / My Orders</Link>
                    </li>
                    <li>
                      <Link to="/cliente/mis-direcciones">
                        Mi dirección de envio / Shipping Addresses
                      </Link>
                    </li>
                  </ul>
                  <p
                    className="boton-cerrar-sesion"
                    onClick={cerrarSesion}
                  >
                    Sign Out
                  </p>
                </div>
                <div className="grid-perfil-detalles">{children}</div>
              </div>
            </div>
          </section>
          <Pie
            menuInfo={menus[0].MenuInfo}
            menuSocial={menus[2].MenuSocial}
            menuPromocion={menus[3].MenuPromocion}
          />
        </>
      )}
    </>
  );
};

export default LayoutCliente;
