import React, { useState } from "react";
import EnviarContacto from "../../../utils/EnviarContacto";

import "./../Modal.css";

const initFormMenuInfo = {
  nombres: "",
  correo: "",
  asunto: "",
  mensaje: ""
};

const MenuModalInfoCrear = ({ setEstadoContacto, whatsAppProducto }) => {
  const [formContacto, setFormContacto] = useState(initFormMenuInfo);

  const cambiarDatosContacto = (e) => {
    const { name, value } = e.target;
    setFormContacto({
      ...formContacto,
      [name]: value,
    });
  };

  const enviarMensajeContacto = (e) => {
    e.preventDefault();
    EnviarContacto(formContacto, whatsAppProducto)
    setFormContacto(initFormMenuInfo);
    setEstadoContacto(false);
  };

  return (
    <div className="contenedor-padre-modal">
      <div className="contenedor-modal">
        <div className="modal-cerrar">
          {" "}
          <button onClick={() => setEstadoContacto(false)}>
            <img src="/icons/Cerrar.svg" alt="" />
          </button>
        </div>
        <div>Escribenos</div>

        <form onSubmit={enviarMensajeContacto}>
          <p>Nombres:</p>
          <input
            type="text"
            required
            name="nombres"
            placeholder="Nombres"
            value={formContacto.nombres}
            onChange={cambiarDatosContacto}
          />
          <p>Correo:</p>
          <input
            type="text"
            required
            name="correo"
            placeholder="Correo"
            value={formContacto.correo}
            onChange={cambiarDatosContacto}
          />
          <p>Asunto:</p>
          <input
            type="text"
            required
            name="asunto"
            placeholder="Asunto"
            value={formContacto.asunto}
            onChange={cambiarDatosContacto}
          />
          <p>Mensaje:</p>
          <textarea
            name="mensaje"
            required
            cols="30"
            rows="2"
            placeholder="Mensaje"
            value={formContacto.mensaje}
            onChange={cambiarDatosContacto}
          ></textarea>

          <input type="submit" value="Enviar mensaje" />
        </form>

        <br />
      </div>
    </div>
  );
};
export default MenuModalInfoCrear;
