import React, { useState, useEffect } from "react";
import { db } from "../../../firebase/firebase";
import { Link } from "react-router-dom";
import { doc, onSnapshot } from "firebase/firestore";
import {
  editarArrayMenuPromocion,
  mostrarArrayMenuPromocion,
} from "../../../controllers/MenuPromocion";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { MenuModalPromocionEditar } from "../../../components/Modal";
import "./Menu.css";

const reodernarLista = (nuevaLista, posicionInicio, posicionFinal) => {
  const nuevaListaMenu = [...nuevaLista];
  const [removido] = nuevaListaMenu.splice(posicionInicio, 1);
  nuevaListaMenu.splice(posicionFinal, 0, removido);
  return nuevaListaMenu;
};

const MenuPromocion = () => {
  const [formMenuPromocion, setFormMenuPromocion] = useState([]);
  const [estadoMenuModalEditar, setEstadoMenuModalEditar] = useState(false);
  const [itemSeleccionado, setMenuSeleccionado] = useState({});
  const [posicion, setPosicionMenu] = useState(0);
  const [mostrar, setMostrar] = useState(false);

  const editarMenu = (categoria, index) => {
    setPosicionMenu(index);
    setMenuSeleccionado(categoria);
    setEstadoMenuModalEditar(!estadoMenuModalEditar);
  };

  useEffect(() => {
    const menuPromocionRef = doc(db, "Menu", "cdI8GVb6xUuUTd5rzRuf");
    onSnapshot(menuPromocionRef, (doc) => {
      setFormMenuPromocion(doc.data().MenuPromocion);
      setMostrar(doc.data().mostrar);
    });
  }, []);

  const guardarNuevoMenu = (e) => {
    e.preventDefault();
    editarArrayMenuPromocion(formMenuPromocion);
  };

  return (
    <>
      {formMenuPromocion?.length === 0 ? (
        <h5>No hay menu</h5>
      ) : (
        <>
          {estadoMenuModalEditar && (
            <MenuModalPromocionEditar
              setEstadoModal={setEstadoMenuModalEditar}
              seleccionado={itemSeleccionado}
              principal={formMenuPromocion}
              posicion={posicion}
            />
          )}
          <DragDropContext
            onDragEnd={(result) => {
              const { source, destination } = result;
              if (!destination) {
                return;
              }
              if (
                source.index === destination.index &&
                source.droppableId === destination.droppableId
              ) {
                return;
              }

              setFormMenuPromocion((listaAntigua) =>
                reodernarLista(listaAntigua, source.index, destination.index)
              );
            }}
          >
            <div className="app">
              <h1 style={{ fontSize: "16px" }}>
                <Link
                  to="/administracion/menu"
                  style={{ textDecoration: "underline" }}
                >
                  Menu Principal
                </Link>{" "}
                <span> / </span>{" "}
                <Link
                  to="/administracion/menu-social"
                  style={{ textDecoration: "underline" }}
                >
                  Menu Social
                </Link>
                <span> / </span>{" "}
                <Link
                  to="/administracion/menu-info"
                  style={{ textDecoration: "underline" }}
                >
                  Menu Info
                </Link>
                <span> / </span>{" "}
                <Link
                  to="/administracion/menu-promocion"
                  style={{ textDecoration: "underline" }}
                >
                  Menu Promoción
                </Link>
              </h1>
              <Droppable droppableId="tasks">
                {(droppableProvided) => (
                  <ul
                    {...droppableProvided.droppableProps}
                    ref={droppableProvided.innerRef}
                    className="contenedor-direcciones-drop"
                  >
                    {formMenuPromocion.map((itemPromocion, index) => (
                      <Draggable
                        key={itemPromocion.idMenuPromocion}
                        draggableId={itemPromocion.idMenuPromocion}
                        index={index}
                      >
                        {(draggableProvided) => (
                          <li
                            {...draggableProvided.draggableProps}
                            ref={draggableProvided.innerRef}
                            {...draggableProvided.dragHandleProps}
                            className="item-drop-direccion"
                          >
                            <p>{itemPromocion.titulo}</p>
                            <br />
                            <p>{itemPromocion.descripcion}</p>
                            <br />
                            <img
                              src={itemPromocion.icono}
                              style={{ width: "100px" }}
                              alt=""
                            />

                            <div className="drop-control-direccion">
                              <button
                                className="drop-botones-seguir"
                                onClick={() => editarMenu(itemPromocion, index)}
                              >
                                Editar Menu
                              </button>
                            </div>
                          </li>
                        )}
                      </Draggable>
                    ))}
                    {droppableProvided.placeholder}
                  </ul>
                )}
              </Droppable>
            </div>
          </DragDropContext>
          <button className="drop-botones-guardar" onClick={guardarNuevoMenu}>
            Guardar Menu
          </button>
          <div className="switch-button">
            <input
              type="checkbox"
              name="switch-button"
              id="switch-label"
              checked={mostrar}
              className={
                mostrar
                  ? "switch-button__checkbox switch-button__checkbox-activo"
                  : "switch-button__checkbox"
              }
              onChange={(event) =>
                mostrarArrayMenuPromocion(event.currentTarget.checked)
              }
            />
            <label
              htmlFor="switch-label"
              className="switch-button__label"
            ></label>
          </div>
        </>
      )}
    </>
  );
};

export default MenuPromocion;
