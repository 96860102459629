import React, { useContext, useState } from "react";
import Axios from "axios";
import { EstadoContexto } from "../../context/EstadoGeneral";
import EnviarWhatsAppCarrito from "../../utils/EnviarWhatsAppCarrito";
import CargandoPagina from "./CargandoPagina";
import Notificacion from "../../components/Notificacion/Notificacion";
import "./FormularioPagar.css";
//let functionCrearOrden =  "http://localhost:5001/ecommerce-palma-5acc0/us-central1/crearOrden";
let functionCrearOrden = "https://us-central1-ecommerce-palma-5acc0.cloudfunctions.net/crearOrden";
const FormularioPagar = ({
  productosTotal,
  totalAPagar,
  productos,
  whatsAppCarrito,
  direccionSeleccionado,
}) => {
  const { usuario } = useContext(EstadoContexto);
  const [estadoNotificacion, setEstadoNotificacion] = useState(false);
  const [estadoNotificacion2, setEstadoNotificacion2] = useState(false);
  const exiteUsuario = Object.keys(usuario).length;
  const [cargando, setCargando] = useState(false);

  const pagarPayPal = async () => {
    if (exiteUsuario && Object.keys(direccionSeleccionado).length !== 0) {
      console.log(direccionSeleccionado);
      setCargando(true);
      localStorage.setItem("direccion", direccionSeleccionado.direccion);
      localStorage.setItem("estado", direccionSeleccionado.estado);
      localStorage.setItem("municipio", direccionSeleccionado.municipio);
      localStorage.setItem("parroquia", direccionSeleccionado.parroquia);
      localStorage.setItem(
        "recomendacion",
        direccionSeleccionado.recomendacion
      );

      const request = await Axios({
        method: "post",
        baseURL: functionCrearOrden,
        data: JSON.stringify(productos),
        headers: {
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "https://thepalmastore.com",
          //"Access-Control-Allow-Origin": "http://localhost:3000",
        },
        withCredentials: true,
      });
      if (request.data) {
        setTimeout(() => {
          setCargando(false);
          window.location.href = request.data.linkPago[1].href;
        }, 1000);
      } else {
        console.log("Hubo un error en crear orden de pago");
      }
    } else {
      setEstadoNotificacion2(true);
    }
  };

  return (
    <>
      {estadoNotificacion && (
        <Notificacion
          mensajeNotificacion={"No tienes cuenta, puedes comprar por WhatsApp."}
          setEstadoNotificacion={setEstadoNotificacion}
        />
      )}
      {estadoNotificacion2 && (
        <Notificacion
          mensajeNotificacion={
            "Tienes que elegir tu dirección de envio o agregar."
          }
          setEstadoNotificacion={setEstadoNotificacion2}
        />
      )}
      {cargando && <CargandoPagina />}
      <div className="formulario-pagar">
        <div className="formulario-pagar-titulo">
          <h3>RESUMEN PEDIDO</h3>
        </div>
        <div className="contenedor-resultado">
          <p>Cantidad productos: </p>
          <span>{productosTotal} </span>
        </div>
        <div className="contenedor-resultado">
          <p>Total a pagar: </p>
          <span>USD {totalAPagar()} </span>
        </div>
        <div className="contenedor-resultado">
          <p>Envio: </p>
          <span>A tratar</span>
        </div>

        <div className="contenedor-descripcion-imagen">
          {exiteUsuario && productos.length !== 0 ? (
            <img
              onClick={() => pagarPayPal()}
              style={{ marginTop: "8px" }}
              src="/images/pagos/Imagen-Boton-PayPal.png"
              alt=""
            />
          ) : (
            <img
              style={{ marginTop: "8px" }}
              onClick={() => setEstadoNotificacion(true)}
              src="/images/pagos/Imagen-Boton-PayPal.png"
              alt=""
            />
          )}
          <img
            style={{ marginTop: "8px" }}
            onClick={() =>
              EnviarWhatsAppCarrito(productos, totalAPagar(), whatsAppCarrito)
            }
            src="/images/pagos/Imagen-Boton-WhatsApp.png"
            alt=""
          />
        </div>
      </div>
    </>
  );
};

export default FormularioPagar;
