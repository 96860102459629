import React from "react";
import "./MostrarImagenes.css";

const MostrarImagenes = ({ fotos, fotosVista, setFotos, setFotosVista }) => {
  const eliminarFoto = (index) => {
    if (index > -1) {
      const nuevaFotoArray = [...fotos];
      nuevaFotoArray.splice(index, 1);
      setFotos(nuevaFotoArray);

      const nuevaFotoArray2 = [...fotosVista];
      nuevaFotoArray2.splice(index, 1);
      setFotosVista(nuevaFotoArray2);

      if (nuevaFotoArray.length === 0) {
        console.log("NO hay nada");
      }
    }
  };
  return (
    <div className="contenedor-mostrar-imagenes">
      <div className="contenedor-card-mostrar">
        {fotos.map((photo, index) => (
          <div key={index}>
            <img className="contenedor-card-imagen" src={photo} alt="" />
            <span onClick={() => eliminarFoto(index)}>
              <img
                className="icono-editar"
                src="/icons/Cerrar.svg"
                alt="logo"
              />
            </span>
          </div>
        ))}
      </div>
    </div>
  );
};
export default MostrarImagenes;
