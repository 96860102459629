export default function EnviarWhatsAppProducto(producto, whatsAppProducto) {
  const urlProducto = `https://thepalmastore.com/producto/${producto.url}/${producto.idProducto}`;
  let saltoLinea = "%0D%0A";
  const numeroCelular = whatsAppProducto.celular;
  const textoMensaje = `${whatsAppProducto.mensaje}: ${saltoLinea} 
  ${saltoLinea}-${producto.nombre} a USD ${producto.precio}
   ${saltoLinea} ${saltoLinea} ${urlProducto} `;
  const wspLink = `https://api.whatsapp.com/send/?phone=${numeroCelular}&text=${textoMensaje}&app_absent=0`;
  window.open(wspLink, "_blank");
}
