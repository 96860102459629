import Dexie from "dexie";

export const db2 = new Dexie("ReactDexie");

db2.version(1).stores({
  productosIDB:
    "++id, idProducto, nombre, marca, url, caracteristicas, color, talla, precio, stock, tiempoEntrega, categoria, etiqueta, imagenes, estado, cantidad",
  /*productosIDB:
    "idProducto, nombre, marca, url, caracteristicas, color, talla, precio, stock, tiempoEntrega, categoria, etiqueta, imagenes, estado, cantidad",*/
});

db2.open().catch((err) => {
  console.log(err.stack || err);
});

export const productosTraerIDB = async () => {
  const productosIDBCarrito = await db2.productosIDB.toArray();
  return productosIDBCarrito;
};

export const productoCrearIDB = async (productoIDB) => {
  /*const getProductoID = await db2.productosIDB.get({
    idProducto: productoIDB.idProducto,
  });*/
  const productosMismoID = await db2.productosIDB
    .where("idProducto")
    .equalsIgnoreCase(productoIDB.idProducto)
    .toArray();

  const tallaIgual = productosMismoID.find(function (produc, index) {
    if (produc.talla === productoIDB.talla) return true;
  });
  if (productosMismoID) {
    console.log("producto existe");
    if (tallaIgual) {
      console.log("tallas iguales");
    } else {
      console.log("tallas no iguales");
      db2.productosIDB.add(productoIDB);
    }
  } else {
    console.log("producto no existe");
    db2.productosIDB.add(productoIDB);
  }
};
export const productoEliminarIDB = (productoIDB) => {
  db2.productosIDB.delete(productoIDB);
};

export const productoAumentarUnidadIDB = (productoIDB, formCantidad) => {
  db2.productosIDB.update(productoIDB, {
    cantidad: formCantidad + 1,
  });
};

export const productoDisminuirUnidadIDB = (productoIDB, formCantidad) => {
  db2.productosIDB.update(productoIDB, {
    cantidad: formCantidad - 1,
  });
};

export const elminarCarritoIDB = () => {
  db2.productosIDB.clear();
};

export const eliminarIndexDB = () => {
  db2.delete();
};
