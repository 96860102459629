import {
  collection,
  addDoc,
  doc,
  deleteDoc,
  getDoc,
  updateDoc,
  getDocs,
} from "firebase/firestore";
import { db } from "../firebase/firebase";
const coleccion = "Etiquetas";

/* CREAR UNA CATEGORIA SIN IMAGEN */
export const etiquetaCrear = async (formEtiqueta) => {
  try {
    await addDoc(collection(db, coleccion), {
      nombre: formEtiqueta.nombre,
      url: formEtiqueta.url,
      descripcion: formEtiqueta.descripcion,
    });
  } catch (e) {
    console.error("Error al agregar etiqueta ", e);
  }
};

/* ELIMINAR UNA ETIQUETA */
export const etiquetaEliminar = async (idEtiqueta) => {
  await deleteDoc(doc(db, coleccion, idEtiqueta));
};

/* EDITAR UNA CATEGORIA SIN IMAGEN */
export const etiquetaEditar = async (formEtiqueta) => {
  const etiquetaRef = doc(db, coleccion, formEtiqueta.idEtiqueta);
  await updateDoc(etiquetaRef, {
    nombre: formEtiqueta.nombre,
    url: formEtiqueta.url,
    descripcion: formEtiqueta.descripcion,
  });
};


/* TRAER UN DOCUMENTO*/
export const etiquetaUna = async (idEtiqueta) => {
  const etiquetaRef = doc(db, coleccion, idEtiqueta);
  const docEtiqueta = await getDoc(etiquetaRef);
  if (docEtiqueta.exists()) {
    return docEtiqueta.data();
  } else {
    console.log("No existe el documento");
  }
};

/* TRAER UN DOCUMENTO*/
export const etiquetasTodas = async () => {
  const etiquetaRef = collection(db, coleccion);
  const etiquetasDB = await getDocs(etiquetaRef);
  return etiquetasDB.docs.map((doc) => {
    const id = doc.id;
    return {
      idEtiqueta: id,
      nombre: doc.data().nombre,
    };
  });
};
