import React, { useState, useRef } from "react";
import { slideEditarCF } from "../../../controllers/Slider";
import "./../Modal.css";

const initFormGaleria = {
  nombre: "",
  url: "",
};

const SlidersModalEditar = ({
  setEstadoMenuModal,
  seleccionado,
  principal,
  posicion,
}) => {
  const [formGaleria, setFormGaleria] = useState(seleccionado);
  const [foto, setFoto] = useState(seleccionado.imagen);
  const [fotoVista, setFotoVista] = useState(seleccionado.imagen);
  const [fotoFirebase, setFotoFirebase] = useState(seleccionado.imagen);

  const imagenRef = useRef();
  function cambiarImagen(e) {
    let fotoSeleccionado;
    if (e.target.files && e.target.files.length === 1) {
      fotoSeleccionado = e.target.files[0];
      setFoto(fotoSeleccionado);
      const fotoCargada = new FileReader();
      fotoCargada.onload = () => {
        setFotoVista(fotoCargada.result);
      };
      fotoCargada.readAsDataURL(fotoSeleccionado);
    }
    imagenRef.current.value = "";
  }

  function eliminarImagen(e) {
    setFoto(undefined);
    if (fotoFirebase) {
      setFotoVista(fotoFirebase);
    } else {
      setFotoVista(undefined);
    }
    imagenRef.current.value = "";
  }

  function cambiarImagenSubir() {
    imagenRef.current.click();
  }

  const editarNuevoMenuPrincipal = (e) => {
    e.preventDefault();
    if (fotoVista.includes("data:image")) {
      slideEditarCF(principal,formGaleria, posicion, foto);
    }
    setEstadoMenuModal(false);
  };

  return (
    <div className="contenedor-padre-modal">
      <div className="contenedor-modal">
        <div className="modal-cerrar">
          {" "}
          <button onClick={() => setEstadoMenuModal(false)}>
            <img src="/icons/Cerrar.svg" alt="" />
          </button>
        </div>

        <form onSubmit={editarNuevoMenuPrincipal}>
          <div className="grid-perfil-cliente-imagen">
            {fotoVista && (
              <>
                <img
                  style={{ width: "300px" }}
                  src={fotoVista}
                  alt="vista previa"
                />
                <br />
                <button
                  type="button"
                  style={{
                    backgroundColor: "black",
                    color: "white",
                    cursor: "pointer",
                    border: "none",
                    marginLeft: "2px",
                    padding: "5px",
                  }}
                  onClick={cambiarImagenSubir}
                >
                  Cambiar slider
                </button>
                {foto !== undefined && (
                  <>
                    <button
                      type="button"
                      style={{
                        backgroundColor: "#dadad8",
                        cursor: "pointer",
                        border: "none",
                        marginLeft: "2px",
                        padding: "5px",
                      }}
                      onClick={eliminarImagen}
                    >
                      Cancelar cambio
                    </button>
                  </>
                )}
              </>
            )}

            <input
              ref={imagenRef}
              type="file"
              accept=".jpg, .png, .jpeg"
              onChange={cambiarImagen}
              style={{
                display: "none",
              }}
            />
          </div>
          <br />
          <input type="submit" value="Editar Slider" />
        </form>

        <br />
      </div>
    </div>
  );
};
export default SlidersModalEditar;
