import React from "react";
import "./PiePagina.css";

const PiePagina = () => {
  return (
    <div className="contenedor-pie-derechos">
      <p>Copyright PALMA &copy; {new Date().getFullYear()}</p>
      <div>
        <img src="/icons/IconoPaypal.svg" alt="" />
        <img src="/icons/IconoVisa.svg" alt="" />
        <img src="/icons/IconoAmericanExpress.svg" alt="" />
        <img src="/icons/IconoMasterCard.svg" alt="" />
      </div>
      <p>Todos los derechos reservados</p>
    </div>
  );
};

export default PiePagina;
