import React, { useState } from "react";
import { agregarArrayPromocion } from "../../../controllers/Promocion";
import "./../Modal.css";

const initFormMenuSocial = "";

const PromocionModalCrear = ({ setEstadoMenuModal }) => {
  const [formMenuSocial, setFormMenuSocial] = useState(initFormMenuSocial);

  const agregarNuevoMenuSocial = (e) => {
    e.preventDefault();
    agregarArrayPromocion(formMenuSocial);
    setFormMenuSocial(initFormMenuSocial);
    setEstadoMenuModal(false);
  };

  return (
    <div className="contenedor-padre-modal">
      <div className="contenedor-modal">
        <div className="modal-cerrar">
          {" "}
          <button onClick={() => setEstadoMenuModal(false)}>
            <img src="/icons/Cerrar.svg" alt="" />
          </button>
        </div>

        <form onSubmit={agregarNuevoMenuSocial}>
          <p>Nombre Menu:</p>
          <input
            type="text"
            required
            name="formMenuSocial"
            placeholder="Nombre Menu"
            value={formMenuSocial}
            onChange={(event) => setFormMenuSocial(event.target.value)}
          />
          <input type="submit" value="Guardar Promición" />
        </form>
      </div>
    </div>
  );
};
export default PromocionModalCrear;
