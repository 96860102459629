import React, { useState, useEffect, useContext } from "react";
import { useParams, Link } from "react-router-dom";
import { productoCrearIDB } from "../../../controllers/IndexDB";
import { productoUno } from "../../../controllers/Productos";
import { favoritoCrear } from "../../../controllers/Favoritos";
import { EstadoContexto } from "../../../context/EstadoGeneral";
import Titulo from "../../../components/Titulo/Titulo";
import { traerWhatsAppProducto } from "../../../controllers/WhatsApp";
import ProductosSimilares from "../../../components/ProductosSimilares/ProductosSimilares";
import Instagram from "../../../components/Instagram/Instagram";
import Notificacion from "../../../components/Notificacion/Notificacion";
import "./ProductoSolo.css";
const ProductoSolo = () => {
  const { usuario } = useContext(EstadoContexto);
  const exiteUsuario = Object.keys(usuario).length;
  const [formCantidad, setFormCantidad] = useState(1);
  const [producto, setProducto] = useState({});
  const [imagen, setImagen] = useState("");
  const [talla, setTalla] = useState("");
  const [color, setColor] = useState("");
  const [estadoNotificacion, setEstadoNotificacion] = useState(false);
  const [estadoNotificacion2, setEstadoNotificacion2] = useState(false);
  const [estadoNotificacion3, setEstadoNotificacion3] = useState(false);
  const [whatsAppProducto, setWhatsAppProducto] = useState({});

  const cambiarTalla = (event) => {
    setTalla(event.target.value);
  };
  const cambiarColor = (event) => {
    setColor(event.target.value);
  };

  const aumentar = () => {
    setFormCantidad(formCantidad + 1);
  };

  const disminuir = () => {
    if (formCantidad > 1) {
      setFormCantidad(formCantidad - 1);
    }
  };
  let { id } = useParams();
  useEffect(() => {
    (async () => {
      const productoDB = await productoUno(id);
      setProducto({
        idProducto: id,
        ...productoDB,
      });
      setTalla(productoDB.tallas[0]);
      setColor(productoDB.colores[0]);
      setImagen(productoDB.imagenes[0]);
      const whatsAppProductoDoc = await traerWhatsAppProducto();
      setWhatsAppProducto(whatsAppProductoDoc);
    })();
  }, [id]);
  const agregarCarritoIDB = (producto) => {
    let productoIDB = {
      idProducto: producto.idProducto,
      nombre: producto.nombre,
      marca: producto.marca,
      url: producto.url,
      caracteristicas: producto.caracteristicas,
      color: color,
      talla: talla,
      precio: producto.precio,
      stock: producto.stock,
      tiempoEntrega: producto.tiempoEntrega,
      categoria: producto.categoria,
      etiqueta: producto.etiqueta,
      imagenes: producto.imagenes,
      estado: true,
      cantidad: formCantidad,
    };
    //console.log(productoIDB);
    productoCrearIDB(productoIDB);
  };
  const enviarLink = () => {
    const wspLink = `https://wa.link/4lgzxf`;
    window.open(wspLink, "_blank");
  };
  const descuento =
    (100 * (parseFloat(producto.oferta) - parseFloat(producto.precio))) /
    parseFloat(producto.oferta);

  return (
    <>
      {estadoNotificacion && (
        <Notificacion
          mensajeNotificacion={"Registrese para agregar productos a favoritos."}
          setEstadoNotificacion={setEstadoNotificacion}
        />
      )}
      {estadoNotificacion2 && (
        <Notificacion
          mensajeNotificacion={"Ya fué agregado tu producto."}
          setEstadoNotificacion={setEstadoNotificacion2}
        />
      )}
      {estadoNotificacion3 && (
        <Notificacion
          mensajeNotificacion={"Ya está en tus favoritos."}
          setEstadoNotificacion={setEstadoNotificacion3}
        />
      )}
      {Object.keys(producto).length === 0 ? (
        <></>
      ) : (
        <div className="contenedor-producto-solo">
          <Titulo titulo={"TIENDA"} />
          <div className="contenedor-grid-producto">
            <div className="grid-producto-imagen">
              <div className="grid-producto-imagen-contenedor">
                <div className="grid-producto-imagen-contenedor-items">
                  {producto.imagenes.map((imagen, index) => (
                    <div key={index}>
                      <img
                        src={imagen}
                        style={{ cursor: "pointer" }}
                        alt=""
                        onClick={() => setImagen(imagen)}
                      />
                    </div>
                  ))}
                </div>
                <div className="grid-producto-imagen-contenedor-solo">
                  <img src={imagen} alt="" />
                </div>
              </div>
            </div>
            <div className="grid-producto-descripcion">
              <img
                loading="lazy"
                src="/images/imagen-sol-rosado.png"
                style={{ width: "50px" }}
                alt=""
              />
              <h1>{producto.nombre}</h1>
              <div className="contenedor-producto-precio">
                {producto.precio !== producto.oferta && (
                  <span
                    style={{
                      textDecoration: "line-through",
                      color: "#848484",
                    }}
                  >
                    USD{producto.oferta}
                  </span>
                )}

                <span>USD{producto.precio}</span>
                {producto.precio !== producto.oferta && (
                  <span
                    style={{
                      background: "#f2c9cd",
                      padding: "7px 28px",
                      fontWeight: "bold",
                    }}
                  >
                    -{descuento.toFixed(2)} % OFF
                  </span>
                )}
              </div>

              <div className="contenedor-caracteristicas">
                {producto.caracteristicas.map((carac, index) => (
                  <div key={index} className="caracteristicas-productos">
                    <img src="/icons/Estrellas.svg" alt="" />
                    <span>{carac}</span>
                  </div>
                ))}
              </div>
              {producto.colores.length > 0 && (
                <div className="contenedor-producto-color">
                  <p>Color: </p>
                  {producto.colores.map((colr, index) => (
                    <label
                      key={index}
                      className={color === colr ? "labelselec" : ""}
                    >
                      <input
                        type="radio"
                        value={colr}
                        checked={color === colr}
                        onChange={cambiarColor}
                      />
                      <span style={{ backgroundColor: `#${colr}` }}></span>
                    </label>
                  ))}
                </div>
              )}

              <br />
              {producto.tallas.length > 0 && (
                <div className="contenedor-producto-tallas">
                  <p>Size:</p>
                  <div style={{ display: "flex" }}>
                    {producto.tallas.map((tall, index) => (
                      <label key={index}>
                        <input
                          type="radio"
                          value={tall}
                          checked={talla === tall}
                          onChange={cambiarTalla}
                        />
                        <span className={talla === tall ? "spanselect" : ""}>
                          {tall}
                        </span>{" "}
                      </label>
                    ))}
                  </div>
                </div>
              )}

              <div className="contenedor-incrementar">
                <div className="contenedor-incrementar-botones">
                  <button
                    style={{ width: "20px", height: "25px" }}
                    onClick={() => disminuir()}
                  >
                    -
                  </button>
                  |<span>{formCantidad}</span>|
                  <button
                    style={{ width: "20px", height: "25px" }}
                    onClick={() => aumentar()}
                  >
                    +
                  </button>
                </div>
                <div className="contenedor-incrementar-texto">
                  <span
                    onClick={() => {
                      agregarCarritoIDB(producto);
                      setEstadoNotificacion2(true);
                    }}
                  >
                    ADD TO CART
                  </span>
                </div>
              </div>
              <div className="contenedor-agregar-favorito">
                <img src="/icons/CorazonNuevo.svg" alt="logo" />
                {exiteUsuario ? (
                  <span
                    onClick={() => {
                      favoritoCrear(usuario.idCliente, producto);
                      setEstadoNotificacion3(true);
                    }}
                  >
                    Agregar a la lista de deseos
                  </span>
                ) : (
                  <span onClick={() => setEstadoNotificacion(true)}>
                    Agregar a la lista de deseos
                  </span>
                )}
              </div>
              <div className="contenedor-producto-descripcion">
                <p>
                  <span style={{ fontWeight: "bold" }}>SKW: </span>
                  {producto.etiqueta.join(", ")}{" "}
                </p>
                <p>
                  <span style={{ fontWeight: "bold" }}> Categorias: </span>
                  {producto.categoria}{" "}
                </p>
              </div>
            </div>
          </div>
          <div className="contenedor-link-producto">
            <Link to={`/tiempos-de-entrega`}>SHIPPING</Link>
            <span>|</span>
            <Link to={`/cambios-y-devoluciones`}>RETURN & EXCHANGE</Link>{" "}
            <span>|</span>
            <a style={{ cursor: "pointer" }} onClick={() => enviarLink()}>
              ASSISTANCE
            </a>{" "}
          </div>
        </div>
      )}
      <ProductosSimilares whatsAppProducto={whatsAppProducto} />
      <Instagram />
    </>
  );
};

export default ProductoSolo;
