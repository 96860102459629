import React from "react";
import { Link } from "react-router-dom";

const PieMenu = ({ menuSocial, mostrarMenuSocial }) => {
  return (
    <div className="pie-empresa">
      {mostrarMenuSocial && (
        <>
          <h3>EMPRESA</h3>
          {menuSocial.map(
            (itemMenuSocial, indexMenuSocial) =>
              itemMenuSocial.mostrar && (
                <Link
                  to={{ pathname: `https://${itemMenuSocial.url}` }}
                  key={indexMenuSocial}
                  target="_blank"
                >
                  {itemMenuSocial.nombre}{" "}
                </Link>
              )
          )}
        </>
      )}
    </div>
  );
};

export default PieMenu;
