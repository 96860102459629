import React, { useState } from "react";
import { etiquetaEditar } from "../../../controllers/Etiquetas";
import GenerarUrl from "../../../utils/GenerarUrl";
import "./../Modal.css";

const initFormEtiqueta = {
  nombre: "",
  descripcion: "",
  url: "",
};

const EtiquetasEditarModal = ({
  setEstadoModalEditar,
  etiquetaSeleccionada,
}) => {
  const [formEtiqueta, setFormEtiqueta] = useState(etiquetaSeleccionada);
  const cambiarDatosEditarEtiqueta = (e) => {
    const { name, value } = e.target;
    setFormEtiqueta({
      ...formEtiqueta,
      [name]: value,
    });
  };

  function onBlur() {
    setFormEtiqueta({
      ...formEtiqueta,
      url: GenerarUrl(formEtiqueta.nombre),
    });
  }

  const editarEtiqueta = (e) => {
    e.preventDefault();
    etiquetaEditar(formEtiqueta);
    setFormEtiqueta(initFormEtiqueta);
    setEstadoModalEditar(false);
  };

  return (
    <div className="contenedor-padre-modal">
      <div className="contenedor-modal">
        <div className="modal-cerrar">
          {" "}
          <button onClick={() => setEstadoModalEditar(false)}>
            <img src="/icons/Cerrar.svg" alt="" />
          </button>
        </div>
        <form onSubmit={editarEtiqueta}>
          <p>Nombre:</p>

          <input
            type="text"
            required
            name="nombre"
            placeholder="Nombre de Etiqueta"
            value={formEtiqueta.nombre}
            onChange={cambiarDatosEditarEtiqueta}
            onBlur={onBlur}
          />
          <p>URL:</p>

          <input
            type="text"
            disabled
            required
            value={GenerarUrl(formEtiqueta.nombre)}
            placeholder="URL Etiqueta generado."
          />
          <p>Descripción:</p>

          <textarea
            name="descripcion"
            required
            cols="30"
            rows="2"
            placeholder="Descripción de la Etiqueta"
            value={formEtiqueta.descripcion}
            onChange={cambiarDatosEditarEtiqueta}
          ></textarea>
          <input type="submit" value="Editar Etiqueta" />
        </form>
        <br />
      </div>
    </div>
  );
};
export default EtiquetasEditarModal;
