import { doc, updateDoc } from "firebase/firestore";
import { getStorage, ref, uploadBytes, getDownloadURL } from "firebase/storage";
import { db } from "../firebase/firebase";
const coleccion = "Menu";
const rutaFoto = "menu-imagenes";
const idPromocion = "cdI8GVb6xUuUTd5rzRuf";

export const editarArrayMenuPromocion = async (datosNuevo) => {
  const galeriaRef = doc(db, coleccion, idPromocion);
  await updateDoc(galeriaRef, {
    MenuPromocion: datosNuevo,
  });
};

export const mostrarArrayMenuPromocion = async (mostrar) => {
  const galeriaRef = doc(db, coleccion, idPromocion);
  await updateDoc(galeriaRef, {
    mostrar: mostrar,
  });
};

export const menuPromocionEditarCF = (
  principal,
  formGaleria,
  posicion,
  fotosVista
) => {
  const fechaAhora = Date.now();
  const rutaCompleta =
    fotosVista.name + fechaAhora + fotosVista.lastModified + fotosVista.size;
  const storage = getStorage();
  const imageRef = ref(storage, `${rutaFoto}/${rutaCompleta}`);
  uploadBytes(imageRef, fotosVista)
    .then((snapshot) => {
      getDownloadURL(snapshot.ref).then((linkImagen) => {
        const cambiandoImagenes = formGaleria;
        cambiandoImagenes.icono = linkImagen;
        const copiaGaleria = [...principal];
        copiaGaleria[posicion] = formGaleria;
        editarArrayMenuPromocion(copiaGaleria);
      });
    })
    .catch((error) => {
      console.error("Error al subir imagenes", error);
    });
};
