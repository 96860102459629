import React, { useState } from "react";
import { Link } from "react-router-dom";
import { recuperarContrasena } from "../../../controllers/Sesion";
import Notificacion from "../../../components/Notificacion/Notificacion";
import Titulo2 from "../../../components/Titulo/Titulo2";
import "./Ingresar.css";

const Recuperar = () => {
  const [formCorreo, setFormCorreo] = useState("");
  const cambiarDatosFormRecuperar = (e) => {
    const value = e.target.value;
    setFormCorreo(value);
  };
  const [estadoNotificacion, setEstadoNotificacion] = useState(false);
  const [mensajeNotificacion, setMensajeEstadoNotificacion] = useState(null);

  const recuperarCliente = (e) => {
    e.preventDefault();
    recuperarContrasena(formCorreo).then((res) => {
      if (res === "correcto") {
        setEstadoNotificacion(true);
        setMensajeEstadoNotificacion(
          "Ya se envió a tu correo para que cambies de contraseña."
        );
      } else {
        setEstadoNotificacion(true);
        setMensajeEstadoNotificacion("Error no se pudo");
      }
    });
    setFormCorreo("");
  };
  return (
    <div className="contenedor-ingresar-fondo">
      <Titulo2 titulo={"RECUPERA TU CUENTA DE PALMA"} />
      {estadoNotificacion && (
        <Notificacion
          mensajeNotificacion={mensajeNotificacion}
          setEstadoNotificacion={setEstadoNotificacion}
          setMensajeEstadoNotificacion={setMensajeEstadoNotificacion}
        />
      )}
      <div className="contenedor-ingresar">
        <h3>
          <span>CONTRASEÑA</span>
        </h3>{" "}
        <form onSubmit={recuperarCliente}>
          <input
            type="email"
            required
            name="correo"
            placeholder="Correo"
            value={formCorreo}
            onChange={cambiarDatosFormRecuperar}
          />{" "}
          <input type="submit" value="RECUPERAR" />
          <p>
            <Link to="/ingresar">Ingresar</Link>
          </p>
        </form>
      </div>
    </div>
  );
};

export default Recuperar;
