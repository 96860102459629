import React, { useContext, useState } from "react";
import { productoCrearIDB } from "../../controllers/IndexDB";
import EnviarWhatsAppProducto from "../../utils/EnviarWhatsAppProducto";
import { EstadoContexto } from "../../context/EstadoGeneral";
import { favoritoAgregar } from "../../controllers/Favoritos";
import { Link } from "react-router-dom";
import Notificacion from "../../components/Notificacion/Notificacion";
import "../SlideProductos/SlideProducto.css";

const ProductoSolo = ({ producto, favorito, whatsAppProducto }) => {
  const { usuario } = useContext(EstadoContexto);
  const exiteUsuario = Object.keys(usuario).length;
  const [estadoNotificacion, setEstadoNotificacion] = useState(false);
  const [estadoNotificacion2, setEstadoNotificacion2] = useState(false);
  const [estadoNotificacion3, setEstadoNotificacion3] = useState(false);

  const agregarCarritoIDB = (producto) => {
    let productoIDB = {
      idProducto: producto.idProducto,
      nombre: producto.nombre,
      marca: producto.marca,
      url: producto.url,
      caracteristicas: producto.caracteristicas,
      color: producto.colores[0],
      talla: producto.tallas[0],
      precio: producto.precio,
      stock: producto.stock,
      tiempoEntrega: producto.tiempoEntrega,
      categoria: producto.categoria,
      etiqueta: producto.etiqueta,
      imagenes: producto.imagenes,
      estado: true,
      cantidad: 1,
    };
    //console.log(productoIDB);
    productoCrearIDB(productoIDB);
  };

  return (
    <>
      {estadoNotificacion && (
        <Notificacion
          mensajeNotificacion={"Registrese para agregar productos a favoritos."}
          setEstadoNotificacion={setEstadoNotificacion}
        />
      )}
      {estadoNotificacion2 && (
        <Notificacion
          mensajeNotificacion={"Produdo fué agregado al carrito."}
          setEstadoNotificacion={setEstadoNotificacion2}
        />
      )}
      {estadoNotificacion3 && (
        <Notificacion
          mensajeNotificacion={"Producto agregado a favoritos."}
          setEstadoNotificacion={setEstadoNotificacion3}
        />
      )}
      <div className="contenedor-producto">
        <Link to={`/producto/${producto.url}/${producto.idProducto}`}>
          <img
            className="contenedor-producto-img"
            alt={"descripcion"}
            src={producto.imagenes[0]}
          />
        </Link>

        <div className="slide-productos-botones">
          <img
            src="/icons/CocheNuevo.svg"
            onClick={() => {
              agregarCarritoIDB(producto);
              setEstadoNotificacion2(true);
            }}
            alt="logo"
          />

          {exiteUsuario ? (
            <img
              src="/icons/CorazonNuevo.svg"
              onClick={() => {
                favoritoAgregar(usuario.idCliente, producto);
                setEstadoNotificacion3(true);
              }}
              alt="logo"
            />
          ) : (
            <img
              onClick={() => setEstadoNotificacion(true)}
              src="/icons/CorazonNuevo.svg"
              alt="logo"
            />
          )}

          <img
            onClick={() => EnviarWhatsAppProducto(producto, whatsAppProducto)}
            src="/icons/WhatsAppNuevo.svg"
            alt="logo"
          />
        </div>
        <div>
          <h3>{producto.nombre}</h3>
          <p>USD {producto.precio}</p>
        </div>
      </div>
    </>
  );
};

export default ProductoSolo;
