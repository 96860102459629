import React, { useState, useEffect } from "react";
import { db } from "../../../firebase/firebase";
import {
  collectionGroup,
  onSnapshot,
  query,
  orderBy,
} from "firebase/firestore";
import { pedidoEditar } from "../../../controllers/Pedidos";

const Pedidos = () => {
  const [pedidos, setPedidos] = useState([]);

  useEffect(() => {
    const pedidosRef = collectionGroup(db, "Pedidos");
    const queryPedidos = query(pedidosRef, orderBy("fecha", "desc"));
    onSnapshot(queryPedidos, (snapshot) => {
      setPedidos(
        snapshot.docs.map((doc) => ({
          idPedido: doc.id,
          ...doc.data(),
        }))
      );
    });
  }, []);

  const cambiarRuta = (IdCliente, idPedido) => {
    const estado = "ruta";
    pedidoEditar(IdCliente, idPedido, estado);
  };
  const cambiarPedido = (IdCliente, idPedido) => {
    const estado = "pedido";
    pedidoEditar(IdCliente, idPedido, estado);
  };
  const cambiarEntregado = (IdCliente, idPedido) => {
    const estado = "entregado";
    pedidoEditar(IdCliente, idPedido, estado);
  };

  return (
    <>
      <h4 className="titulo-panel-administracion">Pedidos</h4>
      {pedidos?.length === 0 ? (
        ""
      ) : (
        <>
          {pedidos.map((pedido) => (
            <div key={pedido.idPedido} className="contenedor-pedido">
              <div className="titulo-pedido">
                <div>
                  <p>N° Pedido: {pedido.numeroPedido}</p>
                  <span>|</span>
                  <p>Fecha: {pedido.fecha.toDate().toLocaleDateString()}</p>
                  <span>|</span>
                  <p style={{ fontWeight: "700" }}>
                    Total: USD {pedido.monto}.00{" "}
                  </p>
                </div>
                <div>
                  <button
                    style={
                      pedido.estado === "pedido"
                        ? { color: "white", backgroundColor: "black" }
                        : { color: "black" }
                    }
                    onClick={() =>
                      cambiarPedido(pedido.usuario.idCliente, pedido.idPedido)
                    }
                  >
                    Pedido
                  </button>
                  <button
                    style={
                      pedido.estado === "ruta"
                        ? { color: "black", backgroundColor: "#dadad8" }
                        : { color: "black" }
                    }
                    onClick={() =>
                      cambiarRuta(pedido.usuario.idCliente, pedido.idPedido)
                    }
                  >
                    Ruta
                  </button>
                  <button
                    style={
                      pedido.estado === "entregado"
                        ? { color: "black", backgroundColor: "#f2c9cd" }
                        : { color: "black" }
                    }
                    onClick={() =>
                      cambiarEntregado(pedido.usuario.idCliente, pedido.idPedido)
                    }
                  >
                    Entregado
                  </button>
                </div>
              </div>

              <div className="productos-pedido">
                {pedido.productos.map((producto) => (
                  <div
                    key={producto.idProducto}
                    style={{ marginBottom: "5px" }}
                  >
                    <img src={producto.imagenes[0]} alt="" />
                    <p>{producto.nombre}</p>
                    <span>|</span>
                    <p>Cantidad: {producto.cantidad}</p>
                    <span>|</span>
                    <p style={{ fontWeight: "700" }}>
                      Precio: USD {producto.precio}.00{" "}
                    </p>{" "}
                  </div>
                ))}
              </div>
              <hr />
              <div style={{ padding: "8px", fontSize: "14px" }}>
                <label>Destinatario: </label>
                <span>{pedido.usuario.nombres + pedido.usuario.apellidos}</span>
                <br />
                <label>Correo: </label>
                <span>{pedido.usuario.correo}</span>
                <br />
                <label>Celular: </label>
                <span>{pedido.usuario.celular}</span>
                <br />
                <hr />
                <label>Estado: </label>
                <span>{pedido.direccion.estado}</span>
                <br />
                <label>Municipio: </label>
                <span>{pedido.direccion.municipio}</span>
                <br />
                <label>Parroquia: </label>
                <span>{pedido.direccion.parroquia}</span>
                <br />
                <label>Dirección: </label>
                <span>{pedido.direccion.direccion}</span>
                <br />
                <label>Recomendación de envio: </label>
                <span>{pedido.direccion.recomendacion}</span>
              </div>
            </div>
          ))}
        </>
      )}
    </>
  );
};

export default Pedidos;
