import React, { useState } from "react";
import { categoriaEditar } from "../../../controllers/Categorias";
import GenerarUrl from "../../../utils/GenerarUrl";
import "./../Modal.css";

const initFormCategoria = {
  nombre: "",
  descripcion: "",
  url: "",
};

const CategoriasEditarModal = ({
  setEstadoModalEditar,
  categoriaSeleccionada,
}) => {
  const [formCategoria, setFormCategoria] = useState(categoriaSeleccionada);
  const cambiarDatosEditarCategoria = (e) => {
    const { name, value } = e.target;
    setFormCategoria({
      ...formCategoria,
      [name]: value,
    });
  };

  function onBlur() {
    setFormCategoria({
      ...formCategoria,
      url: GenerarUrl(formCategoria.nombre),
    });
  }

  const editarCategoria = (e) => {
    e.preventDefault();
    categoriaEditar(formCategoria);
    setFormCategoria(initFormCategoria);
    setEstadoModalEditar(false);
  };

  return (
    <div className="contenedor-padre-modal">
      <div className="contenedor-modal">
        <div className="modal-cerrar">
          {" "}
          <button onClick={() => setEstadoModalEditar(false)}>
            <img src="/icons/Cerrar.svg" alt="" />
          </button>
        </div>
        <form onSubmit={editarCategoria}>
          <p>Nombre:</p>
          <input
            type="text"
            required
            name="nombre"
            placeholder="Nombre de Categoria"
            value={formCategoria.nombre}
            onChange={cambiarDatosEditarCategoria}
            onBlur={onBlur}
          />

          <p>URL:</p>
          <input
            type="text"
            disabled
            required
            value={GenerarUrl(formCategoria.nombre)}
            placeholder="URL Categoria generado."
          />

          <p>Descripción:</p>
          <textarea
            name="descripcion"
            required
            cols="30"
            rows="2"
            placeholder="Descripción de la Categoria"
            value={formCategoria.descripcion}
            onChange={cambiarDatosEditarCategoria}
          ></textarea>
          <input type="submit" value="Editar Categoria" />
        </form>
      </div>
    </div>
  );
};
export default CategoriasEditarModal;
