import React, { useState, useEffect } from "react";
import { todosProductos } from "../../controllers/Productos";
import ProductosPaginacion from "./ProductosPaginacion";

const ProductosSimilares = ({ whatsAppProducto }) => {
  const [productos, setProductos] = useState([]);

  useEffect(() => {
    (async () => {
      const productosDB = await todosProductos();
      setProductos(productosDB);
    })();
  }, []);

  return (
    productos.length !== 0 && (
      <ProductosPaginacion
        productos={productos}
        whatsAppProducto={whatsAppProducto}
      />
    )
  );
};
export default ProductosSimilares;
