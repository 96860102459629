import { doc, updateDoc, arrayUnion, setDoc, getDoc } from "firebase/firestore";
import { db } from "../firebase/firebase";
const coleccion = "Usuarios";
const subColeccion = "Direcciones";
const idDireccion = "s3IcWRkZIO6uvk9S0JSz";

export const crearDireccion = async (
  idCliente,
  formDireccion,
  direcionEnvio
) => {
  const rrutaDireccion = `${coleccion}/${idCliente}/${subColeccion}`;
  const direccionesRef = doc(db, rrutaDireccion, idDireccion);

  const Direcciones = [
    {
      idDireccion: "00" + Date.now(),
      estado: direcionEnvio.estado,
      municipio: direcionEnvio.municipio,
      parroquia: direcionEnvio.parroquia,
      direccion: formDireccion.direccion,
      recomendacion: formDireccion.recomendacion,
    },
  ];
  try {
    await setDoc(direccionesRef, {
      Direcciones: Direcciones,
    });
  } catch (e) {
    console.error("No se pudo agregar la dirección ", e);
  }
};

export const agregarArrayDireccion = async (
  idCliente,
  formDireccion,
  direcionEnvio
) => {
  const rutaDireccion = `${coleccion}/${idCliente}/${subColeccion}`;
  const direcionRef = doc(db, rutaDireccion, idDireccion);
  const nuevaDireccion = {
    idDireccion: "00" + Date.now(),
    estado: direcionEnvio.estado,
    municipio: direcionEnvio.municipio,
    parroquia: direcionEnvio.parroquia,
    direccion: formDireccion.direccion,
    recomendacion: formDireccion.recomendacion,
  };

  await updateDoc(direcionRef, {
    Direcciones: arrayUnion(nuevaDireccion),
  });
};

export const editarArrayDireccion = async (direcciones, idCliente) => {
  const rutaDireccion = `${coleccion}/${idCliente}/${subColeccion}`;

  const direcionRef = doc(db, rutaDireccion, idDireccion);
  await updateDoc(direcionRef, {
    Direcciones: direcciones,
  });
};

export const traerDireccion = async (idCliente) => {
  const rutaDireccion = `${coleccion}/${idCliente}/${subColeccion}`;

  const direcionRef = doc(db, rutaDireccion, idDireccion);
  const docDireccion = await getDoc(direcionRef);
  if (docDireccion.exists()) {
    return docDireccion.data().Direcciones;
  } else {
    console.log("No existe el documento");
  }
};
