import React from "react";
import "./Titulo.css";

const Titulo = ({ titulo }) => {
  return (
    <div className="titulo-componente">
      <img loading="lazy" src="/images/imagen-sol.png" alt="" />
      <h2>{titulo}</h2>
      <img loading="lazy" src="/images/imagen-sol.png" alt="" />
    </div>
  );
};
export default Titulo;
