import React from "react";
import EnviarWhatsAppProducto from "../../utils/EnviarWhatsAppProducto";
import { favoritoAgregar } from "../../controllers/Favoritos";
import { Link } from "react-router-dom";
import "./SlideProducto.css";

const SlideProducto = ({
  productos,
  idCliente,
  setEstadoNotificacion,
  setEstadoNotificacion2,
  setEstadoNotificacion3,
  agregarCarritoIDB,
  whatsAppProducto,
  exiteUsuario,
}) => {
  return productos.map((producto) => (
    <div className="card-producto-slide" key={producto.idProducto}>
      <Link to={`/producto/${producto.url}/${producto.idProducto}`}>
        <img
          className="slide-productos-imagen-item"
          src={producto.imagenes[0]}
          alt=""
        />
      </Link>
      <div className="slide-productos-botones">
        <img
          src="/icons/CocheNuevo.svg"
          onClick={() => {
            agregarCarritoIDB(producto);
            setEstadoNotificacion2(true);
          }}
          alt="logo"
        />
        {exiteUsuario ? (
          <img
            src="/icons/CorazonNuevo.svg"
            onClick={() => {
              favoritoAgregar(idCliente, producto);
              setEstadoNotificacion3(true);
            }}
            alt="logo"
          />
        ) : (
          <img
            onClick={() => setEstadoNotificacion(true)}
            src="/icons/CorazonNuevo.svg"
            alt="logo"
          />
        )}

        <img
          onClick={() => EnviarWhatsAppProducto(producto, whatsAppProducto)}
          src="/icons/WhatsAppNuevo.svg"
          alt="logo"
        />
      </div>
      <div>
        <h3>{producto.nombre}</h3>
        <p>USD {producto.precio}</p>
        </div>
    </div>
  ));
};
export default SlideProducto;
