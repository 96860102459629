
export default function EnviarWhatsAppCarrito(productos, total, whatsAppCarrito) {
  const numeroCelular = whatsAppCarrito.celular;
  let saltoLinea = "%0D%0A";
  var descripcionProductos = productos.map(function (des) {
    return (
      "- " +
      des.cantidad +
      " unidad(es) de " +
      des.nombre +
      " a USD " +
      des.precio +
      "" +
      saltoLinea
    );
  });
  const textoMensaje = `${whatsAppCarrito.saludo}: ${saltoLinea} ${saltoLinea}${descripcionProductos.join(
    ""
  )} ${saltoLinea}${whatsAppCarrito.ayuda} de USD ${total} `;
  const wspLink = `https://api.whatsapp.com/send/?phone=${numeroCelular}&text=${textoMensaje}&app_absent=0`;
  window.open(wspLink, "_blank");
}
