import React, { useState, useEffect, useRef } from "react";
import { etiquetasTodas } from "../../../controllers/Etiquetas";
import { categoriasTodas } from "../../../controllers/Categorias";
import { productoCrearCF } from "../../../controllers/Productos";
import { traerMenuPrincipal } from "../../../controllers/MenuPrincipal";
import GenerarUrl from "../../../utils/GenerarUrl";
import JuntarEtiquetas from "../../../utils/JuntarEtiquetas";
import MostrarImagenes from "../../MostrarImagenes/MostrarImagenes";
import CaracteristicasProducto from "../../CaracteristicasProducto/CaracteristicasProducto";
import EtiquetasProducto from "../../EtiquetasProducto/EtiquetasProducto";
import "./../Modal.css";

const initFormProducto = {
  nombre: "",
  url: "",
  marca: "",
  precio: 0,
  oferta: 0,
  stock: 0,
  tiempoEntrega: 0,
};

const tallas = [
  {
    idEtiqueta: "00001",
    nombre: "S",
  },
  {
    idEtiqueta: "00002",
    nombre: "M",
  },
  {
    idEtiqueta: "00003",
    nombre: "L",
  },
  {
    idEtiqueta: "00004",
    nombre: "XL",
  },
  {
    idEtiqueta: "00005",
    nombre: "EG",
  },
];

const ProductosCrearModal = ({ setEstadoModal }) => {
  const [categorias, setCategorias] = useState([]);
  const [categoriaSelect, setCategoriaSelect] = useState(
    "Seleccione la categoria"
  );
  const [menuPrincipal, setMenuPrincipal] = useState([]);
  const [menuPrincipalSelect, setMenuPrincipalSelect] =
    useState("Seleccione el menu");
  const [etiquetas, setEtiquetas] = useState([]);
  const [formEtiqueta, setFormEtiqueta] = useState({});
  const [formTallas, setFormTallas] = useState({});
  const [formProducto, setFormProducto] = useState(initFormProducto);
  const [fotos, setFotos] = useState([]);
  const [fotosVista, setFotosVista] = useState([]);
  const [formCaracteristica, setFormCaracteristica] = useState("");
  const [caracteristicas, setCaracteristicas] = useState([]);
  const [formColor, setFormColor] = useState("");
  const [colores, setColores] = useState([]);
  const imagenRef = useRef();

  useEffect(() => {
    (async () => {
      const categoriaDB = await categoriasTodas();
      setCategorias(categoriaDB);
      const etiquetaDB = await etiquetasTodas();
      setEtiquetas(etiquetaDB);
      const menuPrincipalDB = await traerMenuPrincipal();
      setMenuPrincipal(menuPrincipalDB);
    })();
  }, []);

  const cambiarDatosCrearProducto = (e) => {
    const { name, value } = e.target;
    setFormProducto({
      ...formProducto,
      [name]: value,
    });
  };

  function onBlur() {
    setFormProducto({
      ...formProducto,
      url: GenerarUrl(formProducto.nombre),
    });
  }

  const cambiarFotos = (e) => {
    if (e.target.files) {
      const fotosArray = Array.from(e.target.files).map((file) =>
        URL.createObjectURL(file)
      );
      const fotosArray2 = Array.from(e.target.files);

      setFotos((prevImages) => prevImages.concat(fotosArray));
      Array.from(e.target.files).map((file) => URL.revokeObjectURL(file));

      setFotosVista((prevImages) => prevImages.concat(fotosArray2));
    }
  };

  const crearProducto = (e) => {
    e.preventDefault();
    if (fotosVista.length !== 0 && caracteristicas.length !== 0) {
      const etiquetaFinal = JuntarEtiquetas(formEtiqueta);
      const tallaFinal = JuntarEtiquetas(formTallas);
      productoCrearCF(
        formProducto,
        categoriaSelect,
        etiquetaFinal,
        caracteristicas,
        fotosVista,
        menuPrincipalSelect,
        tallaFinal,
        colores
      );
      setCategoriaSelect("Seleccione la categoria");
      setMenuPrincipalSelect("Seleccione el menu");
      setFormEtiqueta({});
      setFormTallas({});
      setFormProducto(initFormProducto);
      setFotos([]);
      setFotosVista([]);
      setFormCaracteristica("");
      setFormColor("");
      imagenRef.current.value = "";
      setEstadoModal(false);
    } else {
      console.log("Agregue todos los elementos");
    }
  };

  return (
    <div className="contenedor-padre-modal">
      <div className="contenedor-modal">
        <div className="modal-cerrar">
          {" "}
          <button onClick={() => setEstadoModal(false)}>
            <img src="/icons/Cerrar.svg" alt="" />
          </button>{" "}
        </div>
        <form onSubmit={crearProducto}>
          <p>Nombre:</p>
          <input
            type="text"
            required
            name="nombre"
            placeholder="Nombre de Producto"
            value={formProducto.nombre}
            onChange={cambiarDatosCrearProducto}
            onBlur={onBlur}
          />

          <p>URL:</p>
          <input
            type="text"
            disabled
            required
            defaultValue={GenerarUrl(formProducto.nombre)}
            placeholder="URL Producto generado."
          />

          <p>Categoria:</p>
          <select
            onChange={(e) => setCategoriaSelect(e.target.value)}
            value={categoriaSelect}
          >
            <option disabled value={"Seleccione la categoria"}>
              Seleccione la categoria
            </option>
            {categorias.map((categoria) => (
              <option key={categoria.idCategoria} value={categoria.nombre}>
                {categoria.nombre}
              </option>
            ))}
          </select>

          <p>Menu Principal:</p>
          <select
            onChange={(e) => setMenuPrincipalSelect(e.target.value)}
            value={menuPrincipalSelect}
          >
            <option disabled value={"Seleccione el menu"}>
              Seleccione el menu
            </option>
            {menuPrincipal.map((menu) => (
              <option key={menu.idCategoria} value={menu.nombreCategoria}>
                {menu.nombreCategoria}
              </option>
            ))}
          </select>

          {/*<p>Etiqueta:</p>
          <EtiquetasProducto
            etiquetas={etiquetas}
            formEtiqueta={formEtiqueta}
            setFormEtiqueta={setFormEtiqueta}
          />*/}

          {/*<p>Tallas:</p>
          <EtiquetasProducto
            etiquetas={tallas}
            formEtiqueta={formTallas}
            setFormEtiqueta={setFormTallas}
            nombresComp={"talla"}
          />
          <p>Colores:</p>
          <input
            type="text"
            required
            name="colores"
            placeholder="Agregar Colores"
            value={formColor}
            onChange={(e) => setFormColor(e.target.value)}
          />
          <span
            className="agregar-caracteristica"
            onClick={() => {
              setColores([...colores, formColor]);
            }}
          >
            Agregar color
          </span>
          {colores?.length === 0 ? (
            <></>
          ) : (
            <CaracteristicasProducto
              caracteristicas={colores}
              setCaracteristicas={setColores}
              color={true}
            />
          )}*/}
          <p>Marca:</p>
          <input
            type="text"
            required
            name="marca"
            placeholder="Marca de Producto"
            value={formProducto.marca}
            onChange={cambiarDatosCrearProducto}
          />

          <p>Precio real:</p>
          <input
            type="number"
            required
            name="oferta"
            placeholder="Precio de Oferta"
            step={1}
            precision={2}
            min={1}
            value={formProducto.oferta}
            onChange={cambiarDatosCrearProducto}
          />
          <p>
            Precio oferta:{" "}
            {formProducto.oferta > 0 &&
              (100 *
                (parseFloat(formProducto.oferta) -
                  parseFloat(formProducto.precio))) /
                parseFloat(formProducto.oferta)}
            % descuento{" "}
          </p>
          <input
            type="number"
            required
            name="precio"
            placeholder="Precio de Producto"
            step={1}
            precision={2}
            min={1}
            value={formProducto.precio}
            onChange={cambiarDatosCrearProducto}
          />
          <p>Cantidad:</p>
          <input
            type="number"
            required
            name="stock"
            placeholder="stock de Producto"
            step={1}
            min={1}
            value={formProducto.stock}
            onChange={cambiarDatosCrearProducto}
          />

          <p>Tiempo de Entrega:</p>
          <input
            type="number"
            required
            name="tiempoEntrega"
            placeholder="Tiempo de entrega"
            step={1}
            min={1}
            value={formProducto.tiempoEntrega}
            onChange={cambiarDatosCrearProducto}
          />

          <p>Características:</p>
          <input
            type="text"
            required
            name="caracteristicas"
            placeholder="Agregar Característica"
            value={formCaracteristica}
            onChange={(e) => setFormCaracteristica(e.target.value)}
          />
          <span
            className="agregar-caracteristica"
            onClick={() => {
              setCaracteristicas([...caracteristicas, formCaracteristica]);
            }}
          >
            Agregar característica
          </span>
          {caracteristicas?.length === 0 ? (
            <></>
          ) : (
            <CaracteristicasProducto
              caracteristicas={caracteristicas}
              setCaracteristicas={setCaracteristicas}
            />
          )}

          <p>Imagenes:</p>
          <span
            className="agregar-caracteristica"
            onClick={() => imagenRef.current.click()}
          >
            Subir Imagen
          </span>
          <input
            ref={imagenRef}
            type="file"
            id="file"
            required
            multiple
            onChange={cambiarFotos}
            style={{
              display: "none",
            }}
          />
          <MostrarImagenes
            fotos={fotos}
            fotosVista={fotosVista}
            setFotos={setFotos}
            setFotosVista={setFotosVista}
          />
          <input type="submit" value="Guardar Producto" />
        </form>
      </div>
    </div>
  );
};
export default ProductosCrearModal;
