import React from "react";

const Feed = (props) => {
  const { id, caption, media_type, media_url, permalink } = props.feed;
  let post;

  switch (media_type) {
    case "VIDEO":
      post = (
        <>
          <video
            className="instagram-video"
            onClick={() => window.open(permalink, "_blank")}
            width="100%"
            height="auto"
            src={media_url}
            type="video/mp4"
            controls
            playsInline
          ></video>
          <span className="instagram-icono">
            <img src="/icons/Video.svg" alt="logo" />
          </span>
        </>
      );
      break;
    case "CAROUSEL_ALBUM":
      post = (
        <>
          <img
            className="instagram-imagen"
            onClick={() => window.open(permalink, "_blank")}
            width="100%"
            height="auto"
            id={id}
            src={media_url}
            alt={caption}
          />
          <span className="instagram-icono">
            <img src="/icons/Album.svg" alt="logo" />
          </span>
        </>
      );
      break;
    default:
      post = (
        <>
          <img
            className="instagram-imagen"
            onClick={() => window.open(permalink, "_blank")}
            width="100%"
            height="auto"
            id={id}
            src={media_url}
            alt={caption}
          />
          <span className="instagram-icono">
            <img src="/icons/Imagen.svg" alt="logo" />
          </span>
        </>
      );
  }

  return <div className="contenedor-post-instagram">{post}</div>;
};

export default Feed;
