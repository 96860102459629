import React, { useState, useEffect } from "react";
import { db } from "../../../firebase/firebase";
import { doc, onSnapshot } from "firebase/firestore";
import { editarArrayPromocion } from "../../../controllers/Promocion";
import {
  PromocionModalCrear,
  PromocionModalEditar,
} from "../../../components/Modal";
import "./Promocion.css";

const MenuSocial = () => {
  const [promociones, setPromociones] = useState([]);
  const [estadoMenuModal, setEstadoMenuModal] = useState(false);
  const [estadoMenuModalEditar, setEstadoMenuModalEditar] = useState(false);
  const [menuSeleccionado, setMenuSeleccionado] = useState({});
  const [posicionMenu, setPosicionMenu] = useState(0);

  const eliminarMenu = (index) => {
    const nuevaFotoArray = [...promociones];
    nuevaFotoArray.splice(index, 1);
    setPromociones(nuevaFotoArray);
  };

  const bajarSubMenu = () => {
    setPromociones((elemento) => [
      promociones[promociones.length - 1],
      ...elemento.slice(0, -1),
    ]);
  };

  const subirSubMenu = () => {
    setPromociones((elemento) => [...elemento.slice(1), elemento[0]]);
  };

  const editarMenu = (categoria, index) => {
    setPosicionMenu(index);
    setMenuSeleccionado(categoria);
    setEstadoMenuModalEditar(!estadoMenuModalEditar);
  };

  useEffect(() => {
    const idPromocion = "sRwNA0tAurZIq2uOdVAj";
    const promocionRef = doc(db, "Promocion", idPromocion);
    onSnapshot(promocionRef, (doc) => {
      setPromociones(doc.data().Promocion);
    });
  }, []);

  const guardarNuevoMenu = (e) => {
    e.preventDefault();
    editarArrayPromocion(promociones);
  };

  return (
    <>
      {estadoMenuModal && (
        <PromocionModalCrear setEstadoMenuModal={setEstadoMenuModal} />
      )}
      <h2 style={{fontSize:"16px"}}>Menu Promoción</h2>
      {promociones?.length === 0 ? (
        <></>
      ) : (
        <>
          {estadoMenuModalEditar && (
            <PromocionModalEditar
              setEstadoMenuModal={setEstadoMenuModalEditar}
              menuSeleccionado={menuSeleccionado}
              menuPrincipal={promociones}
              posicionMenu={posicionMenu}
            />
          )}

          <div className="contenedor-direcciones-drop">
            {promociones.map((itemSocial, index) => (
              <>
                <li key={index}>
                  <div className="contenedor-drop-sub">
                    <p>{itemSocial}</p>
                    <div>
                      <img
                        onClick={() => subirSubMenu()}
                        src="/icons/Arriba.svg"
                        alt="logo"
                      />{" "}
                      <img
                        onClick={() => bajarSubMenu()}
                        src="/icons/Abajo.svg"
                        alt="logo"
                      />{" "}
                      <img
                        onClick={() => editarMenu(itemSocial, index)}
                        className="icono-editar"
                        src="/icons/Editar.svg"
                        alt="logo"
                      />{" "}
                      <img
                        onClick={() => eliminarMenu(index)}
                        className="icono-eliminar"
                        src="/icons/Eliminar.svg"
                        alt="logo"
                      />{" "}
                    </div>
                  </div>
                </li>
              </>
            ))}
          </div>

          <span
            className="drop-botones-guardar"
            style={{
              backgroundColor: "#555555",
              color: "white",
            }}
            onClick={guardarNuevoMenu}
          >
            Guardar Menu Promoción
          </span>
        </>
      )}
      <span
        className="drop-botones-agregar"
        style={{
          backgroundColor: "black",
          color: "white",
        }}
        onClick={() => setEstadoMenuModal(!estadoMenuModal)}
      >
        Agregar Nueva Promoción
      </span>
    </>
  );
};

export default MenuSocial;
