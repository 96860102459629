import { doc, getDoc, updateDoc } from "firebase/firestore";
import { getStorage, ref, uploadBytes, getDownloadURL } from "firebase/storage";
import { db } from "../firebase/firebase";
const coleccion = "Galeria";
const rutaFoto = "galeria-imagenes";
const idGaleria = "nlmnSRfI8UDIXxjVb3gY";

export const traerUnaGaleria = async () => {
  const galeriaRef = doc(db, coleccion, idGaleria);
  const docGaleria = await getDoc(galeriaRef);

  if (docGaleria.exists()) {
    return docGaleria.data();
  } else {
    console.log("No existe galeria");
  }
};

export const editarArrayGaleria = async (datosNuevo) => {
  const galeriaRef = doc(db, coleccion, idGaleria);
  await updateDoc(galeriaRef, {
    GaleriaPrincipal: datosNuevo,
  });
};

export const mostrarArrayPromocion = async (mostrar) => {
  const galeriaRef = doc(db, coleccion, idGaleria);
  await updateDoc(galeriaRef, {
    mostrar: mostrar,
  });
};


export const galeriaEditarCF = (
  principal,
  formGaleria,
  posicion,
  fotosVista
) => {
  const fechaAhora = Date.now();
  const rutaCompleta =
    fotosVista.name + fechaAhora + fotosVista.lastModified + fotosVista.size;
  const storage = getStorage();
  const imageRef = ref(storage, `${rutaFoto}/${rutaCompleta}`);
  uploadBytes(imageRef, fotosVista)
    .then((snapshot) => {
      getDownloadURL(snapshot.ref).then((linkImagen) => {
        const cambiandoImagenes = formGaleria;
        cambiandoImagenes.imagen = linkImagen;
        const copiaGaleria = [...principal];
        copiaGaleria[posicion] = formGaleria;
        editarArrayGaleria(copiaGaleria);
      });
    })
    .catch((error) => {
      console.error("Error al subir imagenes", error);
    });
};
