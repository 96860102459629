import { getDocs, collection, doc, getDoc } from "firebase/firestore";
import { db } from "../firebase/firebase";
const coleccion = "Menu";
const idMenuPromocion = "cdI8GVb6xUuUTd5rzRuf";

export const todosMenu = async () => {
  const menuRef = collection(db, coleccion);
  const menusDB = await getDocs(menuRef);
  return menusDB.docs.map((doc) => {
    return {
      ...doc.data(),
    };
  });
};


export const traerMenuPromocion = async () => {
  const menuPromocionRef = doc(db, coleccion, idMenuPromocion);
  const docMenuPromocion = await getDoc(menuPromocionRef);

  if (docMenuPromocion.exists()) {
    return docMenuPromocion.data();
  } else {
    console.log("No existe menu promocion");
  }
};