import React, { useState } from "react";
import "./../Modal.css";

const initFormSubCategoria = {
  nombreSubCategoria: "",
  urlSubCategoria: "",
};

const SubMenuModal = ({
  setEstadoModal,
  idSeleccionado,
  menuPrincipal,
  setMenuPrincipal,
}) => {
  const [nuevoSubMenu, setNuevoSubMenu] = useState(initFormSubCategoria);

  const cambiarDatosDelSubMenuPrincipal = (e) => {
    const { name, value } = e.target;
    setNuevoSubMenu({
      ...nuevoSubMenu,
      [name]: value,
    });
  };

  const agregarSubMenu = (e) => {
    e.preventDefault();
    const nuevoMenuPrincipal = menuPrincipal.map((elemento) => {
      const subCategoria = [...elemento.subCategoria];
      if (elemento.idCategoria === idSeleccionado) {
        const enviarSubCategoria = {
          idSubCategoria: "00" + Date.now(),
          nombreSubCategoria: nuevoSubMenu.nombreSubCategoria,
          urlSubCategoria: nuevoSubMenu.urlSubCategoria,
        };

        subCategoria.push(enviarSubCategoria);
      }
      return {
        ...elemento,
        subCategoria,
      };
    });
    setMenuPrincipal(nuevoMenuPrincipal);
    setNuevoSubMenu("");
    setEstadoModal(false);
  };

  return (
    <div className="contenedor-padre-modal">
      <div className="contenedor-modal">
        <div className="modal-cerrar">
          <button onClick={() => setEstadoModal(false)}>
            <img src="/icons/Cerrar.svg" alt="" />
          </button>{" "}
        </div>

        <form onSubmit={agregarSubMenu}>
          <p>Nombre:</p>
          <input
            type="text"
            required
            name="nombreSubCategoria"
            placeholder="Nombre"
            value={nuevoSubMenu.nombreSubCategoria}
            onChange={cambiarDatosDelSubMenuPrincipal}
          />
          <p>URL:</p>
          <input
            type="text"
            required
            name="urlSubCategoria"
            placeholder="URL"
            value={nuevoSubMenu.urlSubCategoria}
            onChange={cambiarDatosDelSubMenuPrincipal}
          />
          <input type="submit" value="Guardar Submenu" />
        </form>
      </div>
    </div>
  );
};
export default SubMenuModal;
