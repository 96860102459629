import React, { useRef, useContext, useState } from "react";
import { productoCrearIDB } from "../../controllers/IndexDB";
import { EstadoContexto } from "../../context/EstadoGeneral";
import Notificacion from "../../components/Notificacion/Notificacion";
import SlideProducto from "./SlideProducto";
import "./SlideProductos.css";

const SlideProductos = ({ productos, whatsAppProducto }) => {
  const contenedorProductosRef = useRef();
  const [estadoNotificacion, setEstadoNotificacion] = useState(false);
  const [estadoNotificacion2, setEstadoNotificacion2] = useState(false);
  const [estadoNotificacion3, setEstadoNotificacion3] = useState(false);

  const { usuario } = useContext(EstadoContexto);
  const exiteUsuario = Object.keys(usuario).length;

  const siguienteSlide = (e) => {
    const slide = contenedorProductosRef.current;
    slide.scrollLeft -= slide.offsetWidth;
    if (slide.scrollLeft <= 0) {
      slide.scrollLeft = slide.scrollWidth;
    }
  };

  const atrasSlide = (e) => {
    const slide = contenedorProductosRef.current;
    slide.scrollLeft += slide.offsetWidth;
    if (slide.scrollLeft >= slide.scrollWidth - slide.offsetWidth) {
      slide.scrollLeft = 0;
    }
  };

  const agregarCarritoIDB = (producto) => {
    let productoIDB = {
      idProducto: producto.idProducto,
      nombre: producto.nombre,
      marca: producto.marca,
      url: producto.url,
      caracteristicas: producto.caracteristicas,
      color: producto.colores[0],
      talla: producto.tallas[0],
      precio: producto.precio,
      stock: producto.stock,
      tiempoEntrega: producto.tiempoEntrega,
      categoria: producto.categoria,
      etiqueta: producto.etiqueta,
      imagenes: producto.imagenes,
      estado: true,
      cantidad: 1,
    };
    //console.log(productoIDB);
    productoCrearIDB(productoIDB);
  };

  return (
    <>
      {estadoNotificacion && (
        <Notificacion
          mensajeNotificacion={"Registrese para agregar productos a favoritos."}
          setEstadoNotificacion={setEstadoNotificacion}
        />
      )}
      {estadoNotificacion2 && (
        <Notificacion
          mensajeNotificacion={"Produdo fué agregado al carrito."}
          setEstadoNotificacion={setEstadoNotificacion2}
        />
      )}
      {estadoNotificacion3 && (
        <Notificacion
          mensajeNotificacion={"Producto agregado a favoritos."}
          setEstadoNotificacion={setEstadoNotificacion3}
        />
      )}
      <div className="contenedor-titulo">
        <h2 className="titulo">PRODUCTS</h2>
      </div>
      <div className="slide-productos-herramienta">
        <div className="slide-productos-imagen" ref={contenedorProductosRef}>
          <SlideProducto
            productos={productos}
            idCliente={usuario.idCliente}
            setEstadoNotificacion={setEstadoNotificacion}
            setEstadoNotificacion2={setEstadoNotificacion2}
            setEstadoNotificacion3={setEstadoNotificacion3}
            agregarCarritoIDB={agregarCarritoIDB}
            whatsAppProducto={whatsAppProducto}
            exiteUsuario={exiteUsuario}

          />
        </div>
        <div>
          <div className="control-atras-slide" onClick={() => siguienteSlide()}>
            <img src="/icons/Retroceder.svg" alt="" />
          </div>
          <div className="control-siguiente-slide" onClick={() => atrasSlide()}>
            <img src="/icons/Siguiente.svg" alt="" />
          </div>
        </div>
      </div>
    </>
  );
};
export default SlideProductos;
