import {
  collection,
  getDocs,
  getFirestore,
  collectionGroup,
  where,
  query,
} from "firebase/firestore";
const db = getFirestore();

const coleccionProductos = "Productos";
const coleccionClientes = "Usuarios";

export const productosTotal = async () => {
  const productosRef = collection(db, coleccionProductos);
  const productosDB = await getDocs(productosRef);
  return productosDB.size;
};

export const clientesTotal = async () => {
  const queryClientes = query(
    collection(db, coleccionClientes),
    where("rol", "==", "cliente")
  );
  const clientesDB = await getDocs(queryClientes);
  return clientesDB.size;
};

export const pedidosTotal = async () => {
  const pedidosRef = collectionGroup(db, "Pedidos");
  const pedidosDB = await getDocs(pedidosRef);
  return pedidosDB.size;
};

export const ventasTotal = async () => {
  const ventasRef = collectionGroup(db, "Pedidos");
  const ventasDB = await getDocs(ventasRef);
  var monto = 0;
  ventasDB.forEach((doc) => {
    monto += doc.data().monto;
  });
  return monto;
};
