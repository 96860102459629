import React, { useState, useRef } from "react";
import {
  editarArrayMenuPromocion,
  menuPromocionEditarCF,
} from "../../../controllers/MenuPromocion";
import "./../Modal.css";

const initFormGaleria = {
  titulo: "",
  descripcion: "",
};

const MenuModalPromocionEditar = ({
  setEstadoModal,
  seleccionado,
  principal,
  posicion,
}) => {
  const [formPromocion, setFormPromocion] = useState(seleccionado);
  const [foto, setFoto] = useState(seleccionado.icono);
  const [fotoVista, setFotoVista] = useState(seleccionado.icono);
  const imagenRef = useRef();

  const cambiarDatosGaleria = (e) => {
    const { name, value } = e.target;
    setFormPromocion({
      ...formPromocion,
      [name]: value,
    });
  };

  const cambiarDatosDelMenuPromocionCheked = (e) => {
    const mostrar = "mostrar";
    setFormPromocion({
      ...formPromocion,
      [mostrar]: e.currentTarget.checked,
    });
  };

  function cambiarImagen(e) {
    let fotoSeleccionado;
    if (e.target.files && e.target.files.length === 1) {
      fotoSeleccionado = e.target.files[0];
      setFoto(fotoSeleccionado);
      const fotoCargada = new FileReader();
      fotoCargada.onload = () => {
        setFotoVista(fotoCargada.result);
      };
      fotoCargada.readAsDataURL(fotoSeleccionado);
    }
    imagenRef.current.value = "";
  }

  function cambiarImagenSubir() {
    imagenRef.current.click();
  }

  const editarNuevoMenuPrincipal = (e) => {
    e.preventDefault();
    if (fotoVista.includes("data:image")) {
      menuPromocionEditarCF(principal, formPromocion, posicion, foto);
    } else {
      const copiaPrincipal = [...principal];
      copiaPrincipal[posicion] = formPromocion;
      editarArrayMenuPromocion(copiaPrincipal);
    }
    setFormPromocion(initFormGaleria);
    setEstadoModal(false);
  };

  return (
    <div className="contenedor-padre-modal">
      <div className="contenedor-modal">
        <div className="modal-cerrar">
          {" "}
          <button onClick={() => setEstadoModal(false)}>
            <img src="/icons/Cerrar.svg" alt="" />
          </button>
        </div>

        <form onSubmit={editarNuevoMenuPrincipal}>
          <p>Titulo:</p>
          <input
            type="text"
            required
            name="titulo"
            placeholder="Nombre Menu"
            value={formPromocion.titulo}
            onChange={cambiarDatosGaleria}
          />
          <p>Descripcion:</p>
          <textarea
            name="descripcion"
            required
            cols="30"
            rows="2"
            placeholder="Descripción de la Categoria"
            value={formPromocion.descripcion}
            onChange={cambiarDatosGaleria}
          ></textarea>

          <div className="switch-button">
            <input
              type="checkbox"
              name="mostrar"
              id="switch-label"
              checked={formPromocion.mostrar}
              className={
                formPromocion.mostrar
                  ? "switch-button__checkbox switch-button__checkbox-activo"
                  : "switch-button__checkbox"
              }
              onChange={cambiarDatosDelMenuPromocionCheked}
            />
            <label
              htmlFor="switch-label"
              className="switch-button__label"
            ></label>
          </div>

          <div className="contenedor-imagenes-categorias">
            <div className="grid-perfil-cliente-imagen">
              {fotoVista && (
                <>
                  <img
                    style={{ width: "300px" }}
                    src={fotoVista}
                    alt="vista previa"
                  />
                  <button
                    type="button"
                    style={{ backgroundColor: "black" }}
                    onClick={cambiarImagenSubir}
                  >
                    Cambiar foto
                  </button>
                </>
              )}
              <input
                ref={imagenRef}
                type="file"
                accept=".jpg, .png, .jpeg"
                onChange={cambiarImagen}
                style={{
                  display: "none",
                }}
              />
            </div>
          </div>

          <input type="submit" value="Editar Menu" />
        </form>

        <br />
      </div>
    </div>
  );
};
export default MenuModalPromocionEditar;
