import React from "react";
import { Route, Redirect } from "react-router-dom";
import PropTypes from "prop-types";

const RutaCliente = (props) => {
  const { layout: Layout, component: Component, location, ...rest } = props;

  const clienteLocal = localStorage.getItem("idCliente");
  const rolLocal = localStorage.getItem("rol");

  const hola = "Te amo Viviana.";
  return clienteLocal && rolLocal === "cliente" ? (
    <Route
      {...rest}
      render={(matchProps) => (
        <Layout hola={hola}>{<Component {...matchProps} />}</Layout>
      )}
    />
  ) : (
    <Redirect
      to={{
        pathname: "/ingresar",
        state: { from: location },
      }}
    />
  );
};

RutaCliente.propTypes = {
  component: PropTypes.any.isRequired,
  layout: PropTypes.any.isRequired,
  path: PropTypes.string,
};

export default RutaCliente;
/*
  return exiteUsuario && usuario.rol === "cliente" ? (
    <Route
      {...rest}
      render={(matchProps) => (
        <Layout hola={hola}>{<Component {...matchProps} />}</Layout>
      )}
    />
  ) : (
    <Redirect
      to={{
        pathname: "/ingresar",
        state: { from: location },
      }}
    />
  );
*/
