import React, { useState } from "react";
import { agregarArrayMenuSocial } from "../../../controllers/MenuSocial";
import "./../Modal.css";

const initFormMenuSocial = {
  nombre: "",
  url: "",
};

const MenuModalSocialCrear = ({ setEstadoMenuModal }) => {
  const [formMenuSocial, setFormMenuSocial] = useState(initFormMenuSocial);

  const cambiarDatosDelMenuSocial = (e) => {
    const { name, value } = e.target;
    setFormMenuSocial({
      ...formMenuSocial,
      [name]: value,
    });
  };

  const agregarNuevoMenuSocial = (e) => {
    e.preventDefault();
    agregarArrayMenuSocial(formMenuSocial);
    setFormMenuSocial(initFormMenuSocial);
    setEstadoMenuModal(false);
  };

  return (
    <div className="contenedor-padre-modal">
      <div className="contenedor-modal">
        <div className="modal-cerrar">
          {" "}
          <button onClick={() => setEstadoMenuModal(false)}>
            <img src="/icons/Cerrar.svg" alt="" />
          </button>
        </div>

        <form onSubmit={agregarNuevoMenuSocial}>
          <p>Nombre Menu:</p>
          <input
            type="text"
            required
            name="nombre"
            placeholder="Nombre Menu"
            value={formMenuSocial.nombre}
            onChange={cambiarDatosDelMenuSocial}
          />
          <p>URL Menu:</p>
          <input
            type="text"
            required
            name="url"
            placeholder="URL Menu"
            value={formMenuSocial.url}
            onChange={cambiarDatosDelMenuSocial}
          />

          <input type="submit" value="Guardar Menu" />
        </form>

        <br />
      </div>
    </div>
  );
};
export default MenuModalSocialCrear;
