import React, { useState } from "react";
import { editarArrayMenuInfo } from "../../../controllers/MenuInfo";
import "./../Modal.css";

const initFormMenuInfo = {
  nombre: "",
  info: "",
};

const MenuModalInfoEditar = ({
  setEstadoMenuModal,
  menuSeleccionado,
  menuPrincipal,
  posicionMenu,
}) => {
  const [formMenuInfo, setFormMenuInfo] = useState(menuSeleccionado);

  const cambiarDatosDelMenuInfo = (e) => {
    const { name, value } = e.target;
    setFormMenuInfo({
      ...formMenuInfo,
      [name]: value,
    });
  };

  const cambiarDatosDelMenuInfoCheked = (e) => {
    const mostrar = "mostrar";
    setFormMenuInfo({
      ...formMenuInfo,
      [mostrar]: e.currentTarget.checked,
    });
  };

  const editarNuevoMenuInfo = (e) => {
    e.preventDefault();
    const copiaMenuPrincipal = [...menuPrincipal];
    copiaMenuPrincipal[posicionMenu] = formMenuInfo;
    editarArrayMenuInfo(copiaMenuPrincipal);
    setFormMenuInfo(initFormMenuInfo);
    setEstadoMenuModal(false);
  };

  return (
    <div className="contenedor-padre-modal">
      <div className="contenedor-modal">
        <div className="modal-cerrar">
          {" "}
          <button onClick={() => setEstadoMenuModal(false)}>
            <img src="/icons/Cerrar.svg" alt="" />
          </button>
        </div>

        <form onSubmit={editarNuevoMenuInfo}>
          <p>Nombre Menu:</p>
          <input
            type="text"
            required
            name="nombre"
            placeholder="Nombre Menu"
            value={formMenuInfo.nombre}
            onChange={cambiarDatosDelMenuInfo}
          />
          <p>URL Menu:</p>
          <input
            type="text"
            required
            name="url"
            placeholder="URL Menu"
            value={formMenuInfo.url}
            onChange={cambiarDatosDelMenuInfo}
          />
          <p>Estado:</p>
          <div className="switch-button-modal">
            <input
              type="checkbox"
              name="mostrar"
              id="switch-label"
              checked={formMenuInfo.mostrar}
              className={
                formMenuInfo.mostrar
                  ? "switch-button-checkbox switch-button-checkbox-activo"
                  : "switch-button-checkbox"
              }
              onChange={cambiarDatosDelMenuInfoCheked}
            />
            <label
              htmlFor="switch-label"
              className="switch-button-label"
            ></label>
          </div>
          <input type="submit" value="Editar Menu" />
        </form>
      </div>
    </div>
  );
};
export default MenuModalInfoEditar;
