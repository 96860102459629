import React, { useState } from "react";
import ProductoSolo from "../ProductoSolo/ProductoSolo";
import Paginacion from "../Paginacion/Paginacion";
import "./ProductosPaginacion.css";

const ProductosPaginacion = ({ productos, whatsAppProducto }) => {
  const [paginaActual, setPaginaActual] = useState(1);
  const elementosPorPagina = 8;

  const limiteNumeroPagina = 5;
  const [limiteMaximoPagina, setLimiteMaximoPagina] = useState(5);
  const [limiteMinimoPagina, setLimiteMinimoPagina] = useState(0);

  const paginas = [];
  for (let i = 1; i <= Math.ceil(productos.length / elementosPorPagina); i++) {
    paginas.push(i);
  }

  const indiceUltimo = paginaActual * elementosPorPagina;
  const indicePrimero = indiceUltimo - elementosPorPagina;
  const elementoActual = productos.slice(indicePrimero, indiceUltimo);

  const clickNumero = (event) => {
    setPaginaActual(Number(event.target.id));
  };

  const numeroDePaginas = paginas.map((number) => {
    if (number < limiteMaximoPagina + 1 && number > limiteMinimoPagina) {
      return (
        <p
          key={number}
          id={number}
          onClick={clickNumero}
          className={paginaActual === number ? "boton-paginacion-activo" : null}
        >
          {number}
        </p>
      );
    } else {
      return null;
    }
  });

  const botonSiguiente = () => {
    setPaginaActual(paginaActual + 1);

    if (paginaActual + 1 > limiteMaximoPagina) {
      setLimiteMaximoPagina(limiteMaximoPagina + limiteNumeroPagina);
      setLimiteMinimoPagina(limiteMinimoPagina + limiteNumeroPagina);
    }
  };

  const botonRetroceder = () => {
    setPaginaActual(paginaActual - 1);

    if ((paginaActual - 1) % limiteNumeroPagina === 0) {
      setLimiteMaximoPagina(limiteMaximoPagina - limiteNumeroPagina);
      setLimiteMinimoPagina(limiteMinimoPagina - limiteNumeroPagina);
    }
  };

  return (
    <>
      <div className="contenedor-productos-centrar">
        {elementoActual.map((producto) => (
          <ProductoSolo key={producto.idProducto} producto={producto}  whatsAppProducto={whatsAppProducto} />
        ))}{" "}
      </div>
      <Paginacion
        botonRetroceder={botonRetroceder}
        paginaActual={paginaActual}
        paginas={paginas}
        numeroDePaginas={numeroDePaginas}
        botonSiguiente={botonSiguiente}
      />
    </>
  );
};

export default ProductosPaginacion;
