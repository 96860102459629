import React, { useEffect, useState } from "react";
import { traerUnaPromocion } from "../../controllers/Promocion";
import "./Promocion.css";

export default function Promocion() {
  const [promociones, setPromociones] = useState([]);
  const [promocionSeleccionado, setPromocionSeleccionado] = useState("");
  const [indexPromocion, setIndexPromocion] = useState(0);

  useEffect(() => {
    (async () => {
      const promocionDoc = await traerUnaPromocion();
      setPromociones(promocionDoc.Promocion);
      setPromocionSeleccionado(promocionDoc.Promocion[0]);
    })();
  }, []);

  useEffect(() => {
    const tiempo = setInterval(
      () => setIndexPromocion((i) => (i + 1) % promociones.length),
      4000
    );
    return () => clearInterval(tiempo);
  }, [promociones.length]);

  useEffect(() => {
    setPromocionSeleccionado(promociones[indexPromocion]);
  }, [indexPromocion, promociones]);

  return promociones?.length === 0 ? (
    <></>
  ) : (
    <div className="contenedor-promocion">
      <p>{promocionSeleccionado} </p>
    </div>
  );
}
