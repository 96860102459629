import React, { useState, useEffect, useRef } from "react";
import { db } from "../../../firebase/firebase";
import { doc, onSnapshot } from "firebase/firestore";
import {
  editarManifiestoConFoto,
  editarManifiestoTitulares,
} from "../../../controllers/Manifiesto";

const Manifiesto = () => {
  const [titulo, setTitulo] = useState("");
  const [subTitulo, setSubTitulo] = useState("");
  const [descripcion, setDescripcion] = useState("");
  const [slogan, setSlogan] = useState("");
  const [ingles, setIngles] = useState("");
  const [nombres, setNombres] = useState("");
  const [boton, setBoton] = useState("");
  const [url, setUrl] = useState("");
  const [foto, setFoto] = useState(undefined);
  const [fotoVista, setFotoVista] = useState(undefined);
  const [fotoFirebase, setFotoFirebase] = useState("");

  const imagenRef = useRef();

  useEffect(() => {
    const manifiestoRef = doc(db, "Manifiesto", "P4ketM7RVmUPvp5TLINd");
    onSnapshot(manifiestoRef, (doc) => {
      setFotoVista(doc.data().imagen);
      setFotoFirebase(doc.data().imagen);
      setTitulo(doc.data().titulo);
      setSubTitulo(doc.data().subTitulo);
      setDescripcion(doc.data().descripcion);
      setSlogan(doc.data().slogan);
      setIngles(doc.data().ingles);
      setNombres(doc.data().nombres);
      setBoton(doc.data().boton);
      setUrl(doc.data().url);
    });
  }, []);

  useEffect(() => {
    if (!foto) {
      return;
    }
    const fotoCargada = new FileReader();
    fotoCargada.onload = () => {
      setFotoVista(fotoCargada.result);
    };
    fotoCargada.readAsDataURL(foto);
  }, [foto]);

  function cambiarImagen(e) {
    let fotoSeleccionado;
    if (e.target.files && e.target.files.length === 1) {
      fotoSeleccionado = e.target.files[0];
      setFoto(fotoSeleccionado);
    }
    imagenRef.current.value = "";
  }

  function cambiarImagenSubir() {
    imagenRef.current.click();
  }

  function eliminarImagen(e) {
    setFoto(undefined);
    if (fotoFirebase) {
      setFotoVista(fotoFirebase);
    } else {
      setFotoVista(undefined);
    }
    imagenRef.current.value = "";
  }

  const guardarSlider = (e) => {
    e.preventDefault();
    editarManifiestoConFoto(foto);
    setFoto(undefined);
    setFotoVista(undefined);
  };

  const editarDatosSlider = (e) => {
    e.preventDefault();
    editarManifiestoTitulares(
      titulo,
      subTitulo,
      descripcion,
      slogan,
      ingles,
      nombres,
      boton,
      url
    );
  };
  return (
    <>
      <h2 style={{ fontSize: "16px" }}>Manifiesto</h2>
      <div className="contenedor-perfil-cliente">
        <form onSubmit={editarDatosSlider}>
          <p>Titulo:</p>
          <input
            type="text"
            required
            name="titulo"
            placeholder="Titulo"
            value={titulo}
            onChange={(event) => setTitulo(event.target.value)}
          />

          <p>Sub Titulo:</p>
          <input
            type="text"
            required
            name="subTitulo"
            placeholder="Subtitulo"
            value={subTitulo}
            onChange={(event) => setSubTitulo(event.target.value)}
          />

          <p>Descripción:</p>
          <textarea
            name="descripcion"
            required
            cols="30"
            rows="6"
            placeholder="Descripción de la Categoria"
            value={descripcion}
            onChange={(event) => setDescripcion(event.target.value)}
          ></textarea>
          <p>Autor:</p>
          <input
            type="text"
            required
            name="nombres"
            placeholder="Autor"
            value={nombres}
            onChange={(event) => setNombres(event.target.value)}
          />
          <p>URL botón: "https://www.instagram.com/thepalmastore/"</p>
          <input
            type="text"
            required
            name="url"
            placeholder="Subtitulo"
            value={url}
            onChange={(event) => setUrl(event.target.value)}
          />
          <input type="submit" value="Editar" />
        </form>
        <br />
        <div className="grid-perfil-cliente-imagen">
          {fotoVista && (
            <>
              <img
                style={{ width: "300px" }}
                src={fotoVista}
                alt="vista previa"
              />
              <br />
              <button
                type="button"
                style={{
                  backgroundColor: "black",
                  color: "white",
                  cursor: "pointer",
                  border: "none",
                  marginLeft: "2px",
                  padding: "5px",
                }}
                onClick={cambiarImagenSubir}
              >
                Cambiar foto
              </button>
              {foto !== undefined && (
                <>
                  <button
                    type="button"
                    style={{
                      backgroundColor: "#dadad8",
                      cursor: "pointer",
                      border: "none",
                      marginLeft: "2px",
                      padding: "5px",
                    }}
                    onClick={eliminarImagen}
                  >
                    Cancelar cambio
                  </button>
                  <button
                    type="button"
                    style={{
                      backgroundColor: "#f2c9cd",
                      cursor: "pointer",
                      border: "none",
                      marginLeft: "2px",
                      padding: "5px",
                    }}
                    onClick={guardarSlider}
                  >
                    Guardar foto
                  </button>
                </>
              )}
            </>
          )}
          <input
            ref={imagenRef}
            type="file"
            accept=".jpg, .png, .jpeg"
            onChange={cambiarImagen}
            style={{
              display: "none",
            }}
          />
        </div>
      </div>
    </>
  );
};

export default Manifiesto;
